import React from "react";
// import { newMock } from "./newMock";
import CardAccordion from "../CardAccordionMain/CardAccordion";
import styles from "./SudjelovanjeNaPredavanjima.module.css";

function SudjelovanjeNaPredavanjima({
  userId,
  data,
  evidentionUserID,
  setEvidentionData,
}) {
  return (
    <div>
      <h1 className={styles.raniRazvojHeader}>
        Sudjelovanje na predavanjima i radionicama
      </h1>
      <p className={styles.raniRazvojDescription}>
        Za preuzimanje potvrde s ITP-a potrebno je ostvariti minimalan broj
        dolazaka na sva predavanja i radionice.
      </p>
      <p className={styles.raniRazvojDescription2}>
        Minimalan broj dolazaka za određeni program je vidljiv na dnu tablice
        programa.
      </p>
      <div className={styles.sudjelovanjeAccordion}>
        {data ? (
          Object.keys(data)
            .sort((a, b) => data[a].place - data[b].place)
            .map((property) => {
              const item = data[property];
              let isInteraktivnaPredavanja = false;
              let array = [];
              let brojPredavanja = 0;
              if (item?.interactiveLessons && item?.modules) {
                array.push(item.interactiveLessons);
                isInteraktivnaPredavanja = true;
                array.push(...item?.modules);
                brojPredavanja = item?.interactiveLessons.length;
              }
              if (item?.modules && !item?.interactiveLessons) {
                const modules = item.modules;
                const remainderModules = modules.slice(0);
                array.push(...remainderModules);
              }
              let attendedCount = 0;
              let totalCount = 0;
              let predavanjaAttendance = 0;
              if (array) {
                array.forEach((module) => {
                  module.map((item) => {
                    if (item.attendance && item.name.includes("Radionica")) {
                      attendedCount++;
                      totalCount++;
                    } else if (item.name.includes("Radionica")) {
                      totalCount++;
                    }
                    if (item.attendance && !item.name.includes("Radionica")) {
                      predavanjaAttendance++;
                    }
                  });
                });
              }

              return (
                <CardAccordion
                  evidentionUserID={evidentionUserID}
                  setEvidentionData={setEvidentionData}
                  key={property}
                  userId={userId}
                  title={property}
                  modules={array}
                  isDisabled={!array || array.length === 0}
                  isRar={property.includes("RAR")}
                  isOR={property.includes("OR")}
                  isTP={property.includes("TP")}
                  isTT={property.includes("T5") || property.includes("T6")}
                  iseOr={property.includes("eOR")}
                  attendedCount={attendedCount}
                  totalAttendance={`${attendedCount}/${totalCount}`}
                  isInteraktivnaPredavanja={isInteraktivnaPredavanja}
                  infoText={`Kako bi mogli preuzeti  potvrdu o završenom programu, polaznici su dužni doći na ${
                    totalCount - 4
                  }/${totalCount} radionica`}
                  showInfoText
                  showEditBtn
                  errorFlag={
                    !(
                      attendedCount >
                      (property.includes("RAR")
                        ? 5
                        : property.includes("OR")
                        ? 16
                        : property.includes("TP1")
                        ? 5
                        : property.includes("TP2")
                        ? 6
                        : 24)
                    )
                  }
                />
              );
            })
        ) : (
          <h1>Nema podataka</h1>
        )}
      </div>
    </div>
  );
}

export default SudjelovanjeNaPredavanjima;
