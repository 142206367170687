import { useLocation, useNavigate } from "react-router-dom";
import IPDLogo from "../../assets/svg/IPDLogo.svg";
import { useUserState } from "../../context/UserContext";
import { OstaleRole, Polaznik, Referada, Voditelj } from "./Data";
import styles from "./SideMenu.module.css";

const OrIds = [3, 4, 5, 6, 7, 8, 9, 10];

const SideMenu = () => {
  const { role, userData } = useUserState();

  const userId = role;
  const navigate = useNavigate();
  const location = useLocation();
  const helpContainerStyles = {
    marginTop: 0,
  };
  let dataToRender = [];
  let secondDataToRender = [];
  if (userId === "Referada") {
    dataToRender = Referada;
  } else if (userId === "Polaznik") {
    dataToRender = Polaznik;

    if (!OrIds.some((id) => userData.programs.includes(id))) {
      dataToRender = dataToRender.filter(
        (data) => data.title !== "Osvrti na voditelje"
      );
    }
  } else if (userId === "Voditelj") {
    dataToRender = Polaznik;
    secondDataToRender = Voditelj;
  } else {
    dataToRender = OstaleRole;
  }

  return (
    <>
      <div className={styles.container}>
        <img
          alt="logo"
          src={IPDLogo}
          className={styles.logo}
          onClick={() => {
            if (!userData?.firstLogin) {
              navigate("/dashboard");
            }
          }}
        />

        <div className={styles.items}>
          {dataToRender.map((item, index) => (
            <div
              key={index}
              className={
                location.pathname === `${item.pathname}`
                  ? styles.itemActive
                  : styles.item
              }
              onClick={() => {
                if (!userData?.firstLogin) navigate(`${item.pathname}`);
              }}
            >
              <img alt="" src={item.icon} className={styles.itemIcon} />
              <p className={styles.itemText}>{item.title}</p>
            </div>
          ))}
        </div>
        {/* {(userId === "Voditelj" || userId === "Supervizor") && (
          <>
            <div className={styles.separator}>
              <span className={styles.spanSeparator}>VODITELJI</span>
              <div className={styles.divider} />
            </div>
            <div className={styles.items} style={{ marginTop: 0 }}>
              {secondDataToRender.map((item, index) => (
                <div
                  key={index}
                  className={
                    location.pathname === `${item.pathname}`
                      ? styles.itemActive
                      : styles.item
                  }
                  onClick={() => {
                    navigate(`${item.pathname}`);
                  }}
                >
                  <img alt="" src={item.icon} className={styles.itemIcon} />
                  <p className={styles.itemText}>{item.title}</p>
                </div>
              ))}
            </div>
          </>
        )} */}
        {/* <div
          className={styles.helpItemContainer}
          style={userId !== "Polaznik" ? helpContainerStyles : {}}
        >
          <div
            className={
              location.pathname === "/pomoc" ? styles.itemActive : styles.item
            }
            onClick={() => {
              navigate("/pomoc");
            }}
            //nema dizajna, treba raspraviti
          >
            <HelpIcon className={styles.itemIcon} />
            <p className={styles.itemText}>Pomoć</p>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default SideMenu;
