import React, { useRef } from "react";
import Icon from "../../../../assets/img/PDFIcon.png";
import ITP from "../../../../assets/potvrdeIDiplome/ITP.png";
import OR from "../../../../assets/potvrdeIDiplome/OR.png";
import RAR from "../../../../assets/potvrdeIDiplome/RAR2.png";
import { useUserState } from "../../../../context/UserContext";
import styles from "./DiplomePotvrde.module.css";
import {
  polaznikOR1DiplomePotvrde,
  polaznikRARDiplomePotvrde,
  polaznikTT5DiplomePotvrde,
} from "./MockData";

function DiplomePotvrde({ userId, polaznikLevel }) {
  let dataToRender = [];
  const { userData } = useUserState();
  if (polaznikLevel === "polaznikRAR") {
    dataToRender = polaznikRARDiplomePotvrde;
  } else if (polaznikLevel === "polaznikOR1") {
    dataToRender = polaznikOR1DiplomePotvrde;
  } else if (polaznikLevel === "polaznikTT5") {
    dataToRender = polaznikTT5DiplomePotvrde;
  }
  const programi = dataToRender.map((item) => item.programi).flat();
  const canvasRef = useRef(null);
  const userName = userData.firstName + " " + userData.lastName;

  const generateDegree = (program) => {
    if (userName) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const degreeImg = new Image();
      degreeImg.onload = () => {
        ctx.drawImage(degreeImg, 0, 0, canvas.width, canvas.height);
        ctx.font = "bold 30px Arial";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        if (program === "RAR")
          ctx.fillText(userName, canvas.width / 2, canvas.height / 2.7);
        else if (program === "OR")
          ctx.fillText(userName, canvas.width / 2, canvas.height / 2.45);
        // bit će završeno kad template za njih bude
        // else if (program === "TT") ctx.fillText(userName, canvas.width / 2, canvas.height / 2.45);
        // else if (program === "TP")ctx.fillText(userName, canvas.width / 2, canvas.height / 2.45);
        else if (program === "ITP")
          ctx.fillText(userName, canvas.width / 2, canvas.height / 2.2);
        else console.log("Invalid program");

        const downloadLink = document.createElement("a");
        downloadLink.href = canvas.toDataURL("image/png");
        downloadLink.download = "my_degree.png";
        downloadLink.click();
      };
      degreeImg.onerror = (error) => {
        console.error("Error loading image:", error);
      };
      degreeImg.src =
        program === "RAR"
          ? RAR
          : program === "OR"
          ? OR
          : program === "ITP"
          ? ITP
          : null; // program==="TT" ? TT : program==="TP" ? TP : ITP;
    }
  };

  return (
    <div className={styles.raniRazvojPrvaSekcija2}>
      <h1 className={styles.raniRazvojHeader}>Diplome i potvrde</h1>
      <p className={styles.raniRazvojDescription}>
        Kad svi uvjeti za stjecanje potvrde/diplome budu zadovoljeni, moći ćeš
        je preuzeti ovdje.
      </p>
      <div className={styles.potvrdeWrapper}>
        <canvas
          ref={canvasRef}
          width={850}
          height={1000}
          style={{ display: "none" }}
        />
        <span className={styles.title}>Potvrda o položenom programu ITP-a</span>
        {programi[0].isRARPolozen ? (
          <>
            <div className={styles.programRow}>
              <img alt="" src={Icon} className={styles.icon} />
              <span className={styles.label}>
                Potvrda o položenom RAR programu
              </span>
            </div>
            <button
              className={styles.potvrdaBtn}
              onClick={() => generateDegree("RAR")}
            >
              Preuzmi potvrdu
            </button>
          </>
        ) : (
          <span className={styles.raniRazvojDescription}>
            Tvoja RAR potvrda još uvijek nije u izradi.
          </span>
        )}
        {programi[0].isORPolozen ? (
          <>
            <div className={styles.programRow}>
              <img alt="" src={Icon} className={styles.icon} />
              <span className={styles.label}>
                Potvrda o položenom OR programu
              </span>
            </div>
            <button
              className={styles.potvrdaBtn}
              onClick={() => generateDegree("OR")}
            >
              Preuzmi potvrdu
            </button>
          </>
        ) : (
          <span className={styles.raniRazvojDescription}>
            Tvoja OR potvrda još uvijek nije u izradi.
          </span>
        )}
        {programi[0].isTPPolozen ? (
          <>
            <div className={styles.programRow}>
              <img alt="" src={Icon} className={styles.icon} />
              <span className={styles.label}>
                Potvrda o položenom TP programu
              </span>
            </div>
            <button
              className={styles.potvrdaBtn}
              // onClick={() => generateDegree("TP")}
            >
              Preuzmi potvrdu
            </button>
          </>
        ) : (
          <span className={styles.raniRazvojDescription}>
            Tvoja TP potvrda još uvijek nije u izradi.
          </span>
        )}
        {programi[0].isTTPolozen ? (
          <>
            <div className={styles.programRow}>
              <img alt="" src={Icon} className={styles.icon} />
              <span className={styles.label}>
                Potvrda o položenom TT programu
              </span>
            </div>
            <button
              className={styles.potvrdaBtn}
              // onClick={() => generateDegree("TT")}
            >
              Preuzmi potvrdu
            </button>
          </>
        ) : (
          <span className={styles.raniRazvojDescription}>
            Tvoja TT potvrda još uvijek nije u izradi.
          </span>
        )}
      </div>
      {polaznikLevel !== "polaznikRAR" && (
        <div className={styles.potvrdeWrapper}>
          <span className={styles.title}>Diploma ITP-a</span>
          {dataToRender[0].isPrakticarDiplomaReady ? (
            <>
              <div className={styles.programRow}>
                <img alt="" src={Icon} className={styles.icon} />
                <span className={styles.label}>Praktičar ITP-a</span>
              </div>
              <button
                className={styles.potvrdaBtn}
                onClick={() => generateDegree("ITP")}
              >
                Preuzmi diplomu
              </button>
            </>
          ) : (
            <span className={styles.raniRazvojDescription}>
              Polaznikova diploma praktičara još uvijek nije u izradi.
            </span>
          )}
          {dataToRender[0].isTerapeutDiplomaReady ? (
            <>
              <div className={styles.programRow}>
                <img alt="" src={Icon} className={styles.icon} />
                <span className={styles.label}>Terapeut ITP-a</span>
              </div>
              <button className={styles.potvrdaBtn}>Preuzmi diplomu</button>
            </>
          ) : (
            <span className={styles.raniRazvojDescription}>
              Polaznikova diploma terapeuta još uvijek nije u izradi.
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default DiplomePotvrde;
