import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Polaznik.css";
import {
  handleNavigateToRucniUnos,
  polaznikORTTOptions,
  polaznikRAROptions,
} from "./utils";

const Polaznik = ({
  user,
  showButton = true,
  polaznikLevel,
  userEvidentionID,
}) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const fullName = localStorage.getItem("evidentionFullName");
  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const dropdownRefs = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        dropdownRefs.current &&
        !dropdownRefs.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);
  let dataToRender = [];
  let secondDataToRender = [];
  if (polaznikLevel === "polaznikRAR") {
    dataToRender = polaznikRAROptions;
  } else if (
    polaznikLevel === "polaznikOR1" ||
    polaznikLevel === "polaznikTT5"
  ) {
    dataToRender = polaznikRAROptions;
    secondDataToRender = polaznikORTTOptions;
  }
  return (
    <div className="polaznik-container-header">
      <div className="polaznik-username">Polaznik: {fullName} </div>
      <div className="self-add-container">
        {showButton && (
          <button className="self-add-hrs" onClick={() => handleDropdown()}>
            Ručni unos sati +
          </button>
        )}
        {showDropdown && (
          <div className="rucni-unos-dropdown" ref={dropdownRefs}>
            <>
              {dataToRender.map((item, index) => {
                return (
                  <div
                    className="rucni-unos-list general-styles"
                    onClick={() =>
                      handleNavigateToRucniUnos(
                        navigate,
                        item.value,
                        userEvidentionID
                      )
                    }
                  >
                    <span to={""} className="header-link" index={index}>
                      {item.label}
                    </span>
                  </div>
                );
              })}
              {(polaznikLevel === "polaznikOR1" ||
                polaznikLevel === "polaznikTT5") && (
                <>
                  {secondDataToRender.map((item, index) => {
                    return (
                      <div
                        className="rucni-unos-list general-styles"
                        onClick={() =>
                          handleNavigateToRucniUnos(
                            navigate,
                            item.value,
                            userEvidentionID
                          )
                        }
                      >
                        <span to={""} className="header-link" index={index}>
                          {item.label}
                        </span>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default Polaznik;
