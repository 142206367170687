import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import Success from "../../../assets/img/Success.png";
import LoadingIcon from "../../../assets/svg/LoadingIcon.svg";
import UserMenu from "../../../components/userMenu/UserMenu.js";
import { useAuthentication } from "../../hooks/useAuthentication";
import otherStyles from "../AddEmployeeToGroup/AddEmployeeToGroup.module.css";
import styles from "../IPDTeam.module.css";
import AddIcon from "../assets/AddIcon.svg";
import ProfilePhoto from "../assets/ProfilePhoto.jpg";
import UserCardSearch from "../components/SearchUserCards/UserCardSearch";
import DjelatniciCard from "../components/djelatniciCard";
import { ModalWrapper, useModal } from "./utils/modalActions";

const apiUrl = process.env.REACT_APP_API_URL;

const AddEmployeeToGroup = () => {
  const { authToken } = useAuthentication();
  const [employeesData, setEmployeesData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState("one");
  const [searchResults, setSearchResults] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const { isModalOpen, showModal, hideModal, modalButtons } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const addVoditeljiNewGroup = location.state?.addVoditeljiNewGroup;
  const handleCardSelect = (user) => {
    const isUserSelected = selectedUsers.some(
      (selectedUser) => selectedUser.uuid === user.uuid
    );

    let updatedSelectedUsers;

    if (isUserSelected) {
      updatedSelectedUsers = selectedUsers.filter(
        (selectedUser) => selectedUser.uuid !== user.uuid
      );
      console.log(`Deselected user:`, user);
    } else {
      updatedSelectedUsers = [...selectedUsers, user];
      console.log(`Selected user:`, user);
    }

    setSelectedUsers(updatedSelectedUsers);
    setIsNextButtonDisabled(updatedSelectedUsers.length === 0);
    console.log(`isNextButtonDisabled: ${isNextButtonDisabled}`);
  };

  const handleAddTeacherToGroup = () => {
    const teachers = selectedUsers.filter((user) =>
      user.roles.includes("Ucitelj")
    );

    if (teachers.length === 0) {
      console.log("No teachers to add.");
      return;
    }

    setIsLoading(true);
    teachers.forEach((teacher) => {
      const userId = teacher.originalId;
      const groupId = 1;

      axios
        .put(`${apiUrl}/addTeacherToGroup?uid=${userId}&gid=${groupId}`)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error("Error adding teacher to group:", error);
        });
    });
    setIsLoading(false);
    showModal();
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  const getHeaderTitle = (currentTabIndex) => {
    if (currentTabIndex === "one") {
      return "voditelja";
    } else if (currentTabIndex === "two") {
      return "učitelja";
    } else if (currentTabIndex === "three") {
      return "polaznika VT-a";
    }
    return "Default Title";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/users/employees`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const resData = response.data;
        setEmployeesData(resData.VoditeljiArray);
        setTeacherData(resData.UciteljiArray);
        setUserData(resData.PolazniciVTArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (authToken) fetchData();
  }, [authToken]);

  const mogucnosti2 = [{ value: "", label: "Više mogućnosti" }];

  const selectStylesMogucnosti = {
    control: (styles) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      backgroundColor: "#F4F4F4",
      color: "#4D4D4D",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      height: "40px",
      width: "300px",
      borderRadius: "4px",
      border: "1px solid #D1D9E2 !important",
      cursor: "pointer",
      boxShadow: "none",
      "&:focus": {
        border: "0 !important",
      },
    }),
  };
  const EllipsisDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faEllipsisV} style={{ color: "#5F6D7E" }} />
      </components.DropdownIndicator>
    );
  };

  return (
    <Box m="20px">
      <div className={otherStyles.flexContainer}>
        <h1 className={otherStyles.title}>IPD Tim</h1>
        <div className={otherStyles.userMenu}>
          <UserMenu />
        </div>
      </div>
      <UserCardSearch
        data={combinedData}
        setSearchResults={setSearchResults}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <div className={styles.ContainerTitleGroups}>
        <h1 className={styles.titleForAddToGroup}>{`Dodaj ${getHeaderTitle(
          currentTabIndex
        )} u grupu`}</h1>
      </div>

      <div className={styles.tabContainer}>
        <Tabs
          value={currentTabIndex}
          variant="scrollable"
          onChange={handleTabChange}
          textColor="inherit"
          className="custom-tabs my-special-tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#CB9C2D",
            },
          }}
          aria-label="secondary tabs example"
          scrollButtons="auto"
        >
          <Tab
            sx={{
              color: currentTabIndex === "one" ? "gold" : "#9FA7B2",
            }}
            value="one"
            label="Diplomirani voditelji"
            className={styles.tabText}
          />
          <Tab
            sx={{
              color: currentTabIndex === "two" ? "gold" : "#9FA7B2",
            }}
            value="two"
            label="Učitelji"
          />
          <Tab
            sx={{
              color: currentTabIndex === "three" ? "gold" : "#9FA7B2",
            }}
            value="three"
            label="Polaznici VT-a"
          />
        </Tabs>
        <Box>
          <div className={`selectContainer ${otherStyles.selectContainer}`}>
            <Select
              defaultValue={mogucnosti2[0]}
              styles={selectStylesMogucnosti}
              isSearchable={false}
              isDisabled={true}
              components={{
                DropdownIndicator: EllipsisDropdownIndicator,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </Box>
      </div>
      <div className={otherStyles["djelatnici-grid"]}>
        {searchResults
          ? searchResults.map((data, index) => (
              <DjelatniciCard
                key={index}
                {...data}
                profilePhoto={ProfilePhoto}
                onSelect={() => handleCardSelect(data)}
                isSelected={selectedUsers.some(
                  (user) => user.uuid === data.uuid
                )}
              />
            ))
          : currentTabIndex === "one"
          ? employeesData.map((data, index) => (
              <DjelatniciCard
                key={index}
                {...data}
                profilePhoto={ProfilePhoto}
                onSelect={() => handleCardSelect(data)}
                isSelected={selectedUsers.some(
                  (user) => user.uuid === data.uuid
                )}
              />
            ))
          : currentTabIndex === "two"
          ? teacherData.map((data, index) => (
              <DjelatniciCard
                {...data}
                profilePhoto={ProfilePhoto}
                onSelect={() => handleCardSelect(data)}
                isSelected={selectedUsers.some(
                  (user) => user.uuid === data.uuid
                )}
              />
            ))
          : currentTabIndex === "three"
          ? userData.map((data, index) => (
              <DjelatniciCard
                key={index}
                {...data}
                profilePhoto={ProfilePhoto}
                onSelect={() => handleCardSelect(data)}
                isSelected={selectedUsers.some(
                  (user) => user.uuid === data.uuid
                )}
              />
            ))
          : null}
        <div className={otherStyles.buttonContainer}>
          <button className={otherStyles.cancelButton}>Odustani</button>
          <button
            className={`${otherStyles.nextButton} ${
              isNextButtonDisabled ? otherStyles.disabledBtn : ""
            }`}
            onClick={() =>
              addVoditeljiNewGroup
                ? navigate("/grupe/nova-grupa", {
                    state: { noviVoditelji: selectedUsers },
                  })
                : handleAddTeacherToGroup()
            }
            disabled={isNextButtonDisabled}
          >
            Dodaj voditelja
            <img src={AddIcon} alt="" />
          </button>
        </div>
      </div>
      {isLoading && (
        <ModalWrapper
          isOpen={isModalOpen}
          image={LoadingIcon}
          description="Učitavanje..."
        />
      )}
      {isModalOpen && (
        <ModalWrapper
          isOpen={isModalOpen}
          image={Success}
          description="Učitelj uspiješno dodan."
          buttons={modalButtons}
        />
      )}
    </Box>
  );
};

export default AddEmployeeToGroup;
