import React, { useState } from "react";
import SearchIcon from "../../../../assets/svg/SearchIcon.svg";
import styles from "./UserCardSearch.module.css";

const UserCardSearch = ({
  data,
  defaultData,
  setSearchResults,
  selectedUsers,
  setSelectedUsers,
}) => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);

    const searchName = input.trim().toLowerCase();
    let filteredData = [];
    if (searchName === "") {
      setSearchResults(defaultData);
    } else {
      filteredData = data.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        const firstName = user.firstName?.toLowerCase();
        const lastName = user.lastName?.toLowerCase();

        return (
          fullName.includes(searchName) ||
          firstName?.includes(searchName) ||
          lastName?.includes(searchName)
        );
      });

      console.log("Filtered Data:", filteredData);
      setSearchResults(filteredData);
    }
  };

  const handleCardSelect = (user) => {
    const isAlreadySelected = selectedUsers.some(
      (selectedUser) => selectedUser.uuid === user.uuid
    );

    if (isAlreadySelected) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.uuid !== user.uuid)
      );
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }

    console.log("Selected User Data: ", user);
  };

  return (
    <label className={styles.searchUsersInputContainer}>
      <img alt="search" src={SearchIcon} className={styles.searchUsersIcon} />
      <input
        type="search"
        placeholder="Pretraži"
        className={styles.searchUsersInput}
        value={searchInput}
        onChange={handleSearchInputChange}
      />
    </label>
  );
};

export default UserCardSearch;
