import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Edit from "../../../../assets/img/Edit.png";
import Trash from "../../../../assets/img/TrashIcon.png";
import Plus from "../../../../assets/img/WhitePlus.png";
import { useUserState } from "../../../../context/UserContext";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { deleteNastupnoPredavanje } from "../../api/deleteNastupnoPredavanje";
import DodajNastupnoPredavanjeModal from "./Modal/DodajNastupnoPredavanjeModal";
import "./PredavanjeTable.css";
function RenderTableData({
  data = [],
  setPresentations,
  setNastupnaPredavanja,
}) {
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const disabledPredavanjaButton = location?.state?.disabledPredavanjaButton;
  const [showModal, setShowModal] = useState(false);
  const [nazivPredavanja, setNazivPredavanja] = useState("");
  const [datumPredavanja, setDatumPredavanja] = useState("");
  const [theoreticalId, setTheoreticalId] = useState("");
  const [gostTopic, setGostTopic] = useState("");
  const [gostAttendance, setGostAttendance] = useState("");
  const handleClick = (
    tema,
    datum,
    theoreticalId,
    gostTopic,
    gostAttendance
  ) => {
    setShowModal(true);
    setNazivPredavanja(tema);
    setDatumPredavanja(datum);
    setTheoreticalId(theoreticalId);
    setGostTopic(gostTopic);
    setGostAttendance(gostAttendance);
  };
  return (
    <>
      {data.map((item, index) => (
        <tr key={index}>
          <td>{index + 1}.</td>
          <td>{item.tema}</td>
          <td className="table-nastupna-predavanja-custom-border">
            {item.datum}
          </td>
          <td>
            {item.prviGost === "" ? (
              <p>/</p>
            ) : (
              <div className="table-nastupna-predavanja-column">
                <img
                  alt=""
                  className="table-nastupna-predavanja-avatar"
                  src={item.prviGostAvatar}
                />
                <p className="table-nastupna-predavanja-gost-name">
                  {item.prviGost}
                </p>
                <p className="table-nastupna-predavanja-status">
                  {item.statusPrviGost}
                </p>
              </div>
            )}
          </td>
          <td className="table-nastupna-predavanja-custom-border">
            {item.temaPrvogGosta === "" ? (
              <button
                className={
                  disabledPredavanjaButton
                    ? "table-nastupna-predavanja-btn-disabled"
                    : "table-nastupna-predavanja-btn"
                }
                disabled={disabledPredavanjaButton}
                onClick={() =>
                  handleClick(
                    item.tema,
                    item.datum,
                    item.theoreticalId,
                    item.temaPrvogGosta,
                    item.prviGostAttendance
                  )
                }
              >
                Zapiši se <img alt="" src={Plus} />
              </button>
            ) : (
              <div className="table-nastupna-predavanja-column">
                <span>{item.temaPrvogGosta}</span>
                {userData.id === item.prviGostId &&
                  item.prviGostDone === false && (
                    <div className="edit-delete-btn-row">
                      <button
                        className="edit-nastupno-predavanje-btn"
                        onClick={() =>
                          handleClick(
                            item.tema,
                            item.datum,
                            item.theoreticalId,
                            item.temaPrvogGosta,
                            item.prviGostAttendance
                          )
                        }
                      >
                        <img
                          alt=""
                          src={Edit}
                          className="edit-nastupno-predavanje-icon"
                        />
                      </button>
                      <button
                        className="delete-nastupno-predavanje-btn"
                        onClick={() =>
                          deleteNastupnoPredavanje(
                            userData.id,
                            item.theoreticalId,
                            authToken,
                            setPresentations,
                            setNastupnaPredavanja
                          )
                        }
                      >
                        <img
                          alt=""
                          src={Trash}
                          className="delete-nastupno-predavanje-trash"
                        />
                      </button>
                    </div>
                  )}
              </div>
            )}
          </td>
          <td>
            {item.drugiGost === "" ? (
              <p>/</p>
            ) : (
              <div className="table-nastupna-predavanja-column">
                <img
                  alt=""
                  className="table-nastupna-predavanja-avatar"
                  src={item.drugiGostAvatar}
                />
                <p className="table-nastupna-predavanja-gost-name">
                  {item.drugiGost}
                </p>
                <p className="table-nastupna-predavanja-status">
                  {item.statusDrugiGost}
                </p>
              </div>
            )}
          </td>
          <td>
            {item.temaDrugogGosta === "" ? (
              <button
                className={
                  disabledPredavanjaButton
                    ? "table-nastupna-predavanja-btn-disabled"
                    : "table-nastupna-predavanja-btn"
                }
                disabled={disabledPredavanjaButton}
                onClick={() =>
                  handleClick(
                    item.tema,
                    item.datum,
                    item.theoreticalId,
                    item.temaDrugogGosta,
                    item.drugiGostAttendance
                  )
                }
              >
                Zapiši se <img alt="" src={Plus} />
              </button>
            ) : (
              <div className="table-nastupna-predavanja-column">
                <span>{item.temaDrugogGosta}</span>
                {userData.id === item.drugiGostId &&
                  item.drugiGostDone === false && (
                    <div className="edit-delete-btn-row">
                      <button
                        onClick={() =>
                          handleClick(
                            item.tema,
                            item.datum,
                            item.theoreticalId,
                            item.temaDrugogGosta,
                            item.drugiGostAttendance
                          )
                        }
                      >
                        <img
                          alt=""
                          src={Edit}
                          className="edit-nastupno-predavanje-icon"
                        />
                      </button>
                      <button
                        className="delete-nastupno-predavanje-btn"
                        onClick={() =>
                          deleteNastupnoPredavanje(
                            userData.id,
                            item.theoreticalId,
                            authToken,
                            setPresentations,
                            setNastupnaPredavanja
                          )
                        }
                      >
                        <img
                          alt=""
                          src={Trash}
                          className="delete-nastupno-predavanje-trash"
                        />
                      </button>
                    </div>
                  )}
              </div>
            )}
          </td>
        </tr>
      ))}
      {showModal && (
        <DodajNastupnoPredavanjeModal
          onClose={() => setShowModal(false)}
          nazivPredavanja={nazivPredavanja}
          datumPredavanja={datumPredavanja}
          theoreticalId={theoreticalId}
          gostTopic={gostTopic}
          gostAttendance={gostAttendance}
          setPresentations={setPresentations}
          setNastupnaPredavanja={setNastupnaPredavanja}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
}

export default RenderTableData;
