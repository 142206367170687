import axios from "axios";
import { formatEvents } from "../calendaripd-utils/calendaripd.utils";
const apiUrl = process.env.REACT_APP_API_URL;

export const getEvents = async (
  dateFrom,
  dateTo,
  selectedRooms,
  accessToken,
  setEvents
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/myIPDCalendar`,
      {
        dateFrom: dateFrom,
        dateTo: dateTo,
        rooms: selectedRooms,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      setEvents(formatEvents(response.data));
    }
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};
