export const izaberiDogadjaj = [
  { value: "Terapija", label: "Terapija" },
  { value: "Individualna supervizija", label: "Individualna supervizija" },
  { value: "Grupna supervizija", label: "Grupna supervizija" },
  { value: "Ostalo", label: "Ostalo" },
];

export const izaberiMjesto = [
  { value: "ZG1", label: "ZG1" },
  { value: "ZG2", label: "ZG2" },
  { value: "ZG3", label: "ZG3" },
  { value: "ZG4", label: "ZG4" },
  { value: "ZG5", label: "ZG5" },
  { value: "ZG6", label: "ZG6" },
  { value: "ZG7", label: "ZG7" },
  { value: "ZG8", label: "ZG8" },
  { value: "ZG9", label: "ZG9" },
  { value: "ZG10", label: "ZG10" },
  { value: "BG1", label: "BG1" },
  { value: "BG2", label: "BG2" },
  { value: "LJ1", label: "LJ1" },
  { value: "LJ2", label: "LJ2" },
  { value: "LJ3", label: "LJ3" },
  { value: "LJ4", label: "LJ4" },
  { value: "ST1", label: "ST1" },
  { value: "Bez sobe", label: "Bez sobe" },
];

export const izaberiVrstuSupervizije = [
  {
    value: "Soba ZG1",
    label: "Soba ZG1",
  },
  {
    value: "Soba ZG2",
    label: "Soba ZG2",
  },
];

export const optionsCity = [
  { value: "Svi gradovi", label: "Svi gradovi" },
  { value: "Zagreb", label: "Zagreb" },
  { value: "Beograd", label: "Beograd" },
  { value: "Ljubljana", label: "Ljubljana" },
  { value: "Split", label: "Split" },
];

export const timeSlotsStart = [
  { label: "08:00", value: "07:00" },
  { label: "08:15", value: "07:15" },
  { label: "08:30", value: "07:30" },
  { label: "08:45", value: "07:45" },
  { label: "09:00", value: "08:00" },
  { label: "09:15", value: "08:15" },
  { label: "09:30", value: "08:30" },
  { label: "09:45", value: "08:45" },
  { label: "10:00", value: "09:00" },
  { label: "10:15", value: "09:15" },
  { label: "10:30", value: "09:30" },
  { label: "10:45", value: "09:45" },
  { label: "11:00", value: "10:00" },
  { label: "11:15", value: "10:15" },
  { label: "11:30", value: "10:30" },
  { label: "11:45", value: "10:45" },
  { label: "12:00", value: "11:00" },
  { label: "12:15", value: "11:15" },
  { label: "12:30", value: "11:30" },
  { label: "12:45", value: "11:45" },
  { label: "13:00", value: "12:00" },
  { label: "13:15", value: "12:15" },
  { label: "13:30", value: "12:30" },
  { label: "13:45", value: "12:45" },
  { label: "14:00", value: "13:00" },
  { label: "14:15", value: "13:15" },
  { label: "14:30", value: "13:30" },
  { label: "14:45", value: "13:45" },
  { label: "15:00", value: "14:00" },
  { label: "15:15", value: "14:15" },
  { label: "15:30", value: "14:30" },
  { label: "15:45", value: "14:45" },
  { label: "16:00", value: "15:00" },
  { label: "16:15", value: "15:15" },
  { label: "16:30", value: "15:30" },
  { label: "16:45", value: "15:45" },
  { label: "17:00", value: "16:00" },
  { label: "17:15", value: "16:15" },
  { label: "17:30", value: "16:30" },
  { label: "17:45", value: "16:45" },
  { label: "18:00", value: "17:00" },
  { label: "18:15", value: "17:15" },
  { label: "18:30", value: "17:30" },
  { label: "18:45", value: "17:45" },
  { label: "19:00", value: "18:00" },
  { label: "19:15", value: "18:15" },
  { label: "19:30", value: "18:30" },
  { label: "19:45", value: "18:45" },
  { label: "20:00", value: "19:00" },
  { label: "20:15", value: "19:15" },
  { label: "20:30", value: "19:30" },
  { label: "20:45", value: "19:45" },
  { label: "21:00", value: "20:00" },
];
export const timeSlotsEnd = [
  { label: "09:00", value: "08:00" },
  { label: "09:15", value: "08:15" },
  { label: "09:30", value: "08:30" },
  { label: "09:45", value: "08:45" },
  { label: "10:00", value: "09:00" },
  { label: "10:15", value: "09:15" },
  { label: "10:30", value: "09:30" },
  { label: "10:45", value: "09:45" },
  { label: "11:00", value: "10:00" },
  { label: "11:15", value: "10:15" },
  { label: "11:30", value: "10:30" },
  { label: "11:45", value: "10:45" },
  { label: "12:00", value: "11:00" },
  { label: "12:15", value: "11:15" },
  { label: "12:30", value: "11:30" },
  { label: "12:45", value: "11:45" },
  { label: "13:00", value: "12:00" },
  { label: "13:15", value: "12:15" },
  { label: "13:30", value: "12:30" },
  { label: "13:45", value: "12:45" },
  { label: "14:00", value: "13:00" },
  { label: "14:15", value: "13:15" },
  { label: "14:30", value: "13:30" },
  { label: "14:45", value: "13:45" },
  { label: "15:00", value: "14:00" },
  { label: "15:15", value: "14:15" },
  { label: "15:30", value: "14:30" },
  { label: "15:45", value: "14:45" },
  { label: "16:00", value: "15:00" },
  { label: "16:15", value: "15:15" },
  { label: "16:30", value: "15:30" },
  { label: "16:45", value: "15:45" },
  { label: "17:00", value: "16:00" },
  { label: "17:15", value: "16:15" },
  { label: "17:30", value: "16:30" },
  { label: "17:45", value: "16:45" },
  { label: "18:00", value: "17:00" },
  { label: "18:15", value: "17:15" },
  { label: "18:30", value: "17:30" },
  { label: "18:45", value: "17:45" },
  { label: "19:00", value: "18:00" },
  { label: "19:15", value: "18:15" },
  { label: "19:30", value: "18:30" },
  { label: "19:45", value: "18:45" },
  { label: "20:00", value: "19:00" },
  { label: "20:15", value: "19:15" },
  { label: "20:30", value: "19:30" },
  { label: "20:45", value: "19:45" },
  { label: "21:00", value: "20:00" },
  { label: "21:15", value: "20:15" },
  { label: "21:30", value: "20:30" },
  { label: "21:45", value: "20:45" },
  { label: "22:00", value: "21:00" },
];

export const rooms = [
  "ZG1",
  "ZG2",
  "ZG3",
  "ZG4",
  "ZG5",
  "ZG6",
  "ZG7",
  "ZG8",
  "ZG9",
  "ZG10",
  "BG1",
  "BG2",
  "LJ1",
  "LJ2",
  "LJ3",
  "LJ4",
  "ST1",
  "Bez sobe",
];
export const roomColorMapping = {
  ZG1: "#800000",
  ZG2: "#bfef45",
  ZG3: "#42d4f4",
  ZG4: "#911eb4",
  ZG5: "#a9a9a9",
  ZG6: "#000000",
  ZG7: "#3cb44b",
  ZG8: "#000075",
  ZG9: "#9A6324",
  ZG10: "#4363d8",
  BG1: "#f032e6",
  BG2: "#808000",
  LJ1: "#e6194B",
  LJ2: "#ffe119",
  LJ3: "#ffe119",
  LJ4: "#ffe119",
  ST1: "#bfef45",
};

export const optionsTwo = [
  // { value: "Predavanja", label: "Predavanja" },
  { value: "Rad s traumom 1", label: "Rad s traumom 1" },
  { value: "Rad s traumom 2", label: "Rad s traumom 2" },
];

export const optionsFour = [
  // { value: "Fakultativno", label: "Fakultativno" },
  { value: "T.R.E.K", label: "T.R.E.K" },
  { value: "T.R.E", label: "T.R.E" },
];
