import React from "react";
import RenderTableData from "./RenderTableData";
import RenderTableHeader from "./RenderTableHeader";
import "./TableTerapije.css";

function TableTerapije({
  columnNames = [],
  data = [],
  userId,
  eventType,
  setModalData,
  setCloseModal,
  deleteFlag,
  setDeleteFlag,
  evidentionUserID,
}) {
  return (
    <table className="table-terapije">
      <thead>
        <RenderTableHeader columnNames={columnNames} />
      </thead>
      <tbody>
        <RenderTableData
          data={data}
          userId={userId}
          eventType={eventType}
          setModalData={setModalData}
          setCloseModal={setCloseModal}
          setDeleteFlag={setDeleteFlag}
          deleteFlag={deleteFlag}
          evidentionUserID={evidentionUserID}
        />
      </tbody>
    </table>
  );
}

export default TableTerapije;
