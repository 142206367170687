import React from "react";
import "./EvaluacijeTable.css";
import RenderTableData from "./RenderTableData";
import RenderTableHeader from "./RenderTableHeader";

function EvaluacijeTable({ columnNames = [], data = [] }) {
  return (
    <table className="table-evaluacije">
      <thead>
        <RenderTableHeader columnNames={columnNames} />
      </thead>
      <tbody>
        <RenderTableData data={data} />
      </tbody>
    </table>
  );
}

export default EvaluacijeTable;
