import { useEffect, useMemo, useState } from "react";
import Exit from "../../../assets/img/ExitIcon.png";
import TrashIcon from "../../../assets/svg/TrashIcon";
import { useUserState } from "../../../context/UserContext";
import { useAuthentication } from "../../hooks/useAuthentication";
import { createEvent } from "../api/createEvent";
import { deleteEvent } from "../api/deleteEvent";
import { getRoomAvalability } from "../api/getRoomAvailability";
import { updateEvent } from "../api/updateEvent";
import {
  timeSlotsEnd,
  timeSlotsStart,
} from "../calendaripd-utils/calendarIpd.mocks";
import {
  handleEndTimeChange,
  handleInputChange,
  handleKlijentChange,
  handleKlijentClick,
  handleStartTimeChange,
} from "../utils";
import styles from "./CreateModal.module.css";
import { eventOptions } from "./modalUtils";

const CreateModal = ({
  onClick,
  date,
  rooms,
  type,
  users,
  startDate,
  endDate,
  selectedRooms,
  setEvents,
  reRender,
  setReRender,
  setIsCreateOpen,
  editEventValues,
  secondSelectedRoomId,
}) => {
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const [selectedTimeStart, setSelectedTimeStart] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [timeStart, setTimeStart] = useState(timeSlotsStart);
  const [timeEnd, setTimeEnd] = useState(timeSlotsEnd);
  const [filteredTimeEnd, setFilteredTimeEnd] = useState(timeEnd);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [busyAppointments, setBusyAppointments] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [formValues, setFormValues] = useState({
    eventType: eventOptions.length > 0 ? eventOptions[0].value : "",
    eventId: "",
    supervisionType: "individual",
    clientId: null,
    outsideClient: "",
    name: "",
    date: date,
    timeFrom: "",
    timeTo: "",
    description: "",
    roomId: secondSelectedRoomId
      ? secondSelectedRoomId
      : rooms.length > 0
      ? rooms[0].value
      : "",
  });

  function timeStringToDate(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }

  function isTimeInRange(time, rangeStart, rangeEnd) {
    return time >= rangeStart && time < rangeEnd;
  }

  function isWithinOneHourOfBusySlot(slotTime, busyStartTime) {
    const oneHourBeforeBusyStart = new Date(busyStartTime);
    oneHourBeforeBusyStart.setHours(busyStartTime.getHours() - 1);

    return slotTime >= oneHourBeforeBusyStart && slotTime < busyStartTime;
  }

  function getEarliestBusyStart(busyAppointments) {
    const busyStartTimes = busyAppointments.map((appointment) => {
      const [start] = appointment.split(" - ");
      return timeStringToDate(start);
    });

    return new Date(Math.min(...busyStartTimes));
  }

  function filterTimeSlots(
    timeSlots,
    busyAppointments,
    selectedStartTime = null
  ) {
    const busyRanges = busyAppointments.map((appointment) => {
      const [start, end] = appointment.split(" - ");
      return {
        start: timeStringToDate(start),
        end: timeStringToDate(end),
      };
    });

    const earliestBusyStart = getEarliestBusyStart(busyAppointments);

    return timeSlots.filter((slot) => {
      const slotTime = timeStringToDate(slot.value);

      if (selectedStartTime) {
        return slotTime > selectedStartTime && slotTime < earliestBusyStart;
      } else {
        return !busyRanges.some(
          (range) =>
            isTimeInRange(slotTime, range.start, range.end) ||
            isWithinOneHourOfBusySlot(slotTime, range.start)
        );
      }
    });
  }

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      timeFrom: timeStart.length > 0 ? timeStart[0].value - 1 : "",
      timeTo: timeEnd.length > 0 ? timeEnd[0].value - 1 : "",
    }));
  }, [timeStart, timeEnd]);

  const filteredUsers = useMemo(() => {
    const normalizedSearchValue = searchValue.toLowerCase();

    if (!normalizedSearchValue) return [];

    const startsWithSearch = [];
    const includesSearch = [];

    users.forEach((user) => {
      const fullName = `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`;

      if (
        user.firstName.toLowerCase().startsWith(normalizedSearchValue) ||
        user.lastName.toLowerCase().startsWith(normalizedSearchValue)
      ) {
        startsWithSearch.push(user);
      } else if (fullName.includes(normalizedSearchValue)) {
        includesSearch.push(user);
      }
    });

    return [...startsWithSearch, ...includesSearch];
  }, [users, searchValue]);

  useEffect(() => {
    if (type === "edit") {
      const { timeFrom, timeTo, roomId, clientId } = editEventValues;
      setSelectedTimeStart(timeFrom);
      setSelectedTimeEnd(timeTo);
      const selectedClient = users.find((user) => user.id === clientId);
      if (selectedClient) {
        setSearchValue(
          `${selectedClient.firstName} ${selectedClient.lastName}`
        );
      }
      setFormValues({
        eventType: editEventValues.eventType,
        eventId: editEventValues.eventId,
        supervisionType: editEventValues.supervisionType,
        clientId: editEventValues.clientId || null,
        outsideClient: editEventValues.outsideClient,
        name: editEventValues.name,
        date: editEventValues.date,
        timeFrom: editEventValues.timeFrom,
        timeTo: editEventValues.timeTo,
        description: editEventValues.description,
        roomId: roomId,
      });
    }
  }, [type, editEventValues]);

  useEffect(() => {
    const fetchTime = async () => {
      const response = await getRoomAvalability(
        authToken,
        type === "edit" ? editEventValues.date : date?.split("T")[0],
        formValues.roomId
      );
      setBusyAppointments(response);
    };

    if (authToken) fetchTime();
  }, [formValues.roomId, date, editEventValues, type, authToken]);

  let availableTimeSlots;

  if (type === "create") {
    availableTimeSlots = filterTimeSlots(timeSlotsStart, busyAppointments);
  } else {
    availableTimeSlots = timeSlotsStart;
  }

  useEffect(() => {
    if (selectedTimeStart && type !== "edit") {
      const selectedStartDate = timeStringToDate(selectedTimeStart);

      const closestBusyStartTime = busyAppointments
        .map((appointment) => {
          const [start] = appointment.split(" - ");
          return timeStringToDate(start);
        })
        .filter((busyStartDate) => busyStartDate > selectedStartDate)
        .sort((a, b) => a - b)[0];

      const filtered = timeEnd.filter((item) => {
        const endTime = timeStringToDate(item.value);
        return (
          endTime > selectedStartDate &&
          (!closestBusyStartTime || endTime < closestBusyStartTime)
        );
      });

      setFilteredTimeEnd(filtered);
    } else {
      setFilteredTimeEnd(timeEnd);
    }
  }, [selectedTimeStart, timeEnd, busyAppointments]);

  return (
    <div className={styles.wrapper}>
      <img src={Exit} alt="" className={styles.exit} onClick={onClick} />
      <div className={styles.modalContainer}>
        <h1 className={styles.h1}>
          {type === "create"
            ? "Kreiranje događaja"
            : formValues.eventType === "therapy"
            ? (formValues.eventType === "therapy" && "Terapija") +
              `: ${userData.firstName} ${userData.lastName}`
            : ""}
        </h1>
        <div className={styles.formWrapper}>
          <div className={styles.form}>
            <div className={styles.item}>
              <label className={styles.label} htmlFor="vrsta">
                Vrsta događaja
              </label>
              <select
                className={styles.select}
                value={formValues.eventType}
                disabled={type === "edit" && role !== "Referada"}
                name="eventType"
                onChange={(event) =>
                  handleInputChange(
                    "eventType",
                    event.target.value,
                    setFormValues
                  )
                }
              >
                {eventOptions.map((event, index) => {
                  if (role === "Referada") {
                    return (
                      <option key={index} value={event.value}>
                        {event.label}
                      </option>
                    );
                  } else if (
                    role === "Voditelj" ||
                    role === "Polaznik VT-a" ||
                    role === "Terapeut" ||
                    role === "Učitelj"
                  ) {
                    if (index === 0) {
                      return (
                        <option key={index} value={event.value}>
                          {event.label}
                        </option>
                      );
                    } else {
                      return null;
                    }
                  } else if (role === "Supervizor") {
                    if (index === 1) {
                      return (
                        <option key={index} value={event.value}>
                          {event.label}
                        </option>
                      );
                    }
                  } else {
                    return null;
                  }
                })}
              </select>
            </div>
            {(formValues.eventType === "therapy" ||
              formValues.eventType === "individual_supervision") && (
              <>
                <div className={styles.item}>
                  <label className={styles.label} htmlFor="klijent">
                    Polaznik/student
                  </label>
                  <div className={styles.search}>
                    <div className={styles.searchInput}>
                      <input
                        type="search"
                        className={styles.input}
                        placeholder="Upiši"
                        value={searchValue}
                        onChange={(event) =>
                          handleKlijentChange(
                            event,
                            setSearchValue,
                            setIsSearchVisible
                          )
                        }
                        disabled={formValues.outsideClient !== ""}
                      />
                    </div>
                    <div
                      className={
                        searchValue === "" || !isSearchVisible
                          ? styles.none
                          : styles.searchDropdown
                      }
                    >
                      {filteredUsers?.slice(0, 5).map((user, index) => (
                        <div
                          key={index}
                          className={styles.dropdownItems}
                          onClick={() =>
                            handleKlijentClick(
                              user.id,
                              user.firstName,
                              user.lastName,
                              setSearchValue,
                              setFormValues,
                              setIsSearchVisible
                            )
                          }
                        >
                          {user.firstName} {user.lastName}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.item}>
                  <label className={styles.label} htmlFor="klijentvan">
                    Klijent van škole
                  </label>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Upiši"
                    value={formValues.outsideClient}
                    disabled={searchValue !== ""}
                    name="outsideClient"
                    onChange={(event) =>
                      handleInputChange(
                        "outsideClient",
                        event.target.value,
                        setFormValues
                      )
                    }
                  />
                </div>
              </>
            )}
            {formValues.eventType === "other" && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="Ostalo">
                  Ostalo
                </label>
                <input
                  type="text"
                  className={styles.input}
                  placeholder="Upiši"
                  value={formValues.name}
                  name="name"
                  onChange={(event) =>
                    handleInputChange("name", event.target.value, setFormValues)
                  }
                />
              </div>
            )}
            <div className={styles.item}>
              <label className={styles.label} htmlFor="datum">
                Datum održavanja
              </label>
              <input
                type="date"
                className={styles.input}
                value={formValues.date}
                name="date"
                onChange={(event) =>
                  handleInputChange("date", event.target.value, setFormValues)
                }
                disabled={type === "edit" && role !== "Referada"}
              />
            </div>
            <div className={styles.item}>
              <label className={styles.label} htmlFor="mjesto">
                Grad i soba
              </label>
              <select
                className={styles.select}
                name="roomId"
                value={secondSelectedRoomId || formValues.roomId}
                onChange={(event) =>
                  handleInputChange("roomId", event.target.value, setFormValues)
                }
                disabled={type === "edit" && role !== "Referada"}
              >
                {rooms.map((room, index) => (
                  <option value={room.value} key={index}>
                    {room.label}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.item}>
              <label className={styles.label} htmlFor="vrijeme">
                Vrijeme održavanja
              </label>
              <div className={styles.timeSelects}>
                <select
                  className={styles.selectSmall}
                  value={selectedTimeStart ? selectedTimeStart : "0"}
                  name="timeFrom"
                  onChange={(event) =>
                    handleStartTimeChange(
                      event,
                      setSelectedTimeStart,
                      setFormValues
                    )
                  }
                  disabled={type === "edit" && role !== "Referada"}
                >
                  <option value="0" selected disabled>
                    Odaberi
                  </option>
                  {availableTimeSlots &&
                    availableTimeSlots.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </select>
                <span className={styles.divider}>-</span>
                <select
                  className={styles.selectSmall}
                  value={selectedTimeEnd ? selectedTimeEnd : "0"}
                  onChange={(event) =>
                    handleEndTimeChange(
                      event,
                      setSelectedTimeEnd,
                      setFormValues
                    )
                  }
                  disabled={type === "edit" && role !== "Referada"}
                >
                  <option value="0" selected disabled>
                    Odaberi
                  </option>
                  {filteredTimeEnd &&
                    filteredTimeEnd.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {(formValues.eventType === "therapy" ||
              formValues.eventType === "individual_supervision") && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="napomena">
                  Napomena
                </label>
                <textarea
                  className={styles.textarea}
                  placeholder="Dodaj napomenu"
                  value={formValues.description}
                  name="description"
                  onChange={(event) =>
                    handleInputChange(
                      "description",
                      event.target.value,
                      setFormValues
                    )
                  }
                />
              </div>
            )}
            {formValues.eventType === "other" && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="opis">
                  Opis
                </label>
                <textarea
                  className={styles.opis}
                  value={formValues.description}
                  name="description"
                  onChange={(event) =>
                    handleInputChange(
                      "description",
                      event.target.value,
                      setFormValues
                    )
                  }
                  placeholder="Dodaj opis"
                />
              </div>
            )}
          </div>
          {role !== "Polaznik" && role !== "Zakupitelj" && (
            <div className={styles.btnsContainer}>
              {type === "edit" && role === "Referada" && (
                <div
                  className={styles.delete}
                  onClick={() =>
                    deleteEvent(
                      formValues,
                      authToken,
                      startDate,
                      endDate,
                      selectedRooms,
                      setEvents,
                      setIsCreateOpen,
                      reRender,
                      setReRender
                    )
                  }
                >
                  <TrashIcon alt="" />
                </div>
              )}
              <div className={styles.createBtns}>
                <button className={styles.btnCancel} onClick={onClick}>
                  Odustani
                </button>
                <button
                  className={styles.btnCreate}
                  onClick={() => {
                    type === "edit"
                      ? updateEvent(
                          formValues.eventType,
                          formValues,
                          authToken,
                          startDate,
                          endDate,
                          selectedRooms,
                          setEvents,
                          setIsCreateOpen,
                          reRender,
                          setReRender
                        )
                      : createEvent(
                          formValues.eventType,
                          formValues,
                          authToken,
                          startDate,
                          endDate,
                          selectedRooms,
                          setEvents,
                          setIsCreateOpen,
                          reRender,
                          setReRender
                        );
                  }}
                >
                  {type === "edit" ? "Spremi promjene" : "Kreiraj događaj"}
                </button>
              </div>
            </div>
          )}
          <div className={styles.createBtns}>
            <button className={styles.btnCancel} onClick={onClick}>
              Odustani
            </button>
            <button
              className={styles.btnCreate}
              onClick={() => {
                type === "edit"
                  ? updateEvent(
                      formValues.eventType,
                      formValues,
                      authToken,
                      startDate,
                      endDate,
                      selectedRooms,
                      setEvents,
                      setIsCreateOpen,
                      reRender,
                      setReRender
                    )
                  : createEvent(
                      formValues.eventType,
                      formValues,
                      authToken,
                      startDate,
                      endDate,
                      selectedRooms,
                      setEvents,
                      setIsCreateOpen,
                      reRender,
                      setReRender
                    );
              }}
            >
              {type === "edit" ? "Spremi promjene" : "Kreiraj događaj"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
