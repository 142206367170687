export const izaberiDogadjaj = [
  { value: "Terapija", label: "Terapija" },
  { value: "Individualna supervizija", label: "Individualna supervizija" },
  { value: "Grupna supervizija", label: "Grupna supervizija" },
  { value: "Ostalo", label: "Ostalo" },
];

export const izaberiMjesto = [
  { value: "ZG1", label: "ZG1" },
  { value: "ZG2", label: "ZG2" },
  { value: "ZG3", label: "ZG3" },
  { value: "ZG4", label: "ZG4" },
  { value: "ZG5", label: "ZG5" },
  { value: "ZG6", label: "ZG6" },
  { value: "ZG7", label: "ZG7" },
  { value: "ZG8", label: "ZG8" },
  { value: "ZG9", label: "ZG9" },
  { value: "ZG10", label: "ZG10" },
  { value: "BG1", label: "BG1" },
  { value: "BG2", label: "BG2" },
  { value: "LJ1", label: "LJ1" },
  { value: "LJ2", label: "LJ2" },
  { value: "LJ3", label: "LJ3" },
  { value: "LJ4", label: "LJ4" },
  { value: "ST1", label: "ST1" },
  { value: "Bez sobe", label: "Bez sobe" },
];

export const izaberiVrstuSupervizije = [
  {
    value: "Soba ZG1",
    label: "Soba ZG1",
  },
  {
    value: "Soba ZG2",
    label: "Soba ZG2",
  },
];

export const optionsCity = [
  { value: "Svi gradovi", label: "Svi gradovi" },
  { value: "Zagreb", label: "Zagreb" },
  { value: "Beograd", label: "Beograd" },
  { value: "Ljubljana", label: "Ljubljana" },
  { value: "Split", label: "Split" },
];

export const timeSlots = [
  {
    value: "09:00 - 12:15",
    label: "09:00 - 12:15",
    start: "09:00",
    end: "12:15",
    availability: false,
  },
  {
    value: "12:15 - 13:30",
    label: "12:15 - 13:30",
    start: "12:15",
    end: "13:30",
    availability: false,
  },
  {
    value: "13:30 - 16:45",
    label: "13:30 - 16:45",
    start: "13:30",
    end: "16:45",
    availability: false,
  },
  {
    value: "16:45 - 21:15",
    label: "16:45 - 21:15",
    start: "16:45",
    end: "21:15",
    availability: false,
  },
];

export const therapyTimeSlots = [
  {
    value: "07:45 - 08:45",
    label: "07:45 - 08:45",
    start: "07:45",
    end: "08:45",
    avaliability: true,
  },
  {
    value: "09:00 - 10:00",
    label: "09:00 - 10:00",
    start: "09:00",
    end: "10:00",
    avaliability: true,
  },
  {
    value: "10:15 - 11:15",
    label: "10:15 - 11:15",
    start: "10:15",
    end: "11:15",
    avaliability: true,
  },
  {
    value: "11:30 - 12:30",
    label: "11:30 - 12:30",
    start: "11:30",
    end: "12:30",
    avaliability: true,
  },
  {
    value: "12:45 - 13:45",
    label: "12:45 - 13:45",
    start: "12:45",
    end: "13:45",
    avaliability: true,
  },
  {
    value: "14:00 - 15:00",
    label: "14:00 - 15:00",
    start: "14:00",
    end: "15:00",
    avaliability: true,
  },
  {
    value: "15:15 - 16:15",
    label: "15:15 - 16:15",
    start: "15:15",
    end: "16:15",
    avaliability: true,
  },
  {
    value: "16:30 - 17:30",
    label: "16:30 - 17:30",
    start: "16:30",
    end: "17:30",
    avaliability: true,
  },
  {
    value: "18:00 - 19:00",
    label: "18:00 - 19:00",
    start: "18:00",
    end: "19:00",
    avaliability: true,
  },
  {
    value: "19:15 - 20:15",
    label: "19:15 - 20:15",
    start: "19:15",
    end: "20:15",
    avaliability: true,
  },
  {
    value: "20:30 - 21:30",
    label: "20:30 - 21:30",
    start: "20:30",
    end: "21:30",
    avaliability: true,
  },
];
export const rooms = [
  "ZG1",
  "ZG2",
  "ZG3",
  "ZG4",
  "ZG5",
  "ZG6",
  "ZG7",
  "ZG8",
  "ZG9",
  "ZG10",
  "BG1",
  "BG2",
  "LJ1",
  "LJ2",
  "LJ3",
  "LJ4",
  "ST1",
  "Bez sobe",
];
export const roomColorMapping = {
  ZG1: "#800000",
  ZG2: "#bfef45",
  ZG3: "#42d4f4",
  ZG4: "#911eb4",
  ZG5: "#a9a9a9",
  ZG6: "#000000",
  ZG7: "#3cb44b",
  ZG8: "#000075",
  ZG9: "#9A6324",
  ZG10: "#4363d8",
  BG1: "#f032e6",
  BG2: "#808000",
  LJ1: "#e6194B",
  LJ2: "#ffe119",
  LJ3: "#ffe119",
  LJ4: "#ffe119",
  ST1: "#bfef45",
};

export const optionsTwo = [
  // { value: "Predavanja", label: "Predavanja" },
  { value: "Rad s traumom 1", label: "Rad s traumom 1" },
  { value: "Rad s traumom 2", label: "Rad s traumom 2" },
];

export const optionsFour = [
  // { value: "Fakultativno", label: "Fakultativno" },
  { value: "T.R.E.K", label: "T.R.E.K" },
  { value: "T.R.E", label: "T.R.E" },
];
