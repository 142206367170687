import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Exit from "../../../../../../assets/img/ExitIcon.png";
import Success from "../../../../../../assets/img/Success.png";
import Modal from "../../../../../../components/Modal/Modal";
import styles from "./KomentarModal.module.css";

function KomentarModal({ onClose }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    datumSupervizije: "",
    komentar: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <div className={styles.modalOverlay}>
      <img
        alt=""
        src={Exit}
        className={styles.exitBtn}
        onClick={() => onClose()}
      />
      <div className={styles.modalWrapper}>
        <div className={styles.infoModal}>
          <p className={styles.title}>Komentar na superviziju</p>
          <p className={styles.subtitle} style={{ fontSize: 20 }}>
            Polaznik: Mate Mandela
          </p>
          <div className={styles.inputWrapper}>
            <div className={styles.inputColumn}>
              <span className={styles.label}>Datum održavanja supervizije</span>
              <input
                name="datumSupervizije"
                value={formData.datumSupervizije}
                type="date"
                className={styles.modalInput}
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.inputColumn}>
              <span className={styles.label}>Komentar</span>
              <textarea
                className={styles.textarea}
                placeholder="Upiši"
                name="komentar"
                value={formData.komentar}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className={styles.btnRow}>
          <button onClick={() => onClose()} className={styles.cancelBtn}>
            Odustani
          </button>
          <button
            className={styles.successBtn}
            onClick={() => {
              setModalContent({
                image: Success,
                description: "Tvoj komentar je uspješno dodan",
                buttons: [
                  {
                    label: "Uredu",
                    action: () => onClose(),
                    className: "modal-btn-success",
                  },
                ],
              });
              setShowModal(true);
            }}
          >
            Objavi komentar
          </button>
        </div>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          description={modalContent.description}
          buttons={modalContent.buttons}
          fullWidth
        />
      )}
    </div>
  );
}

export default KomentarModal;
