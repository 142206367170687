import { deleteModalEntryWithData } from "../../api/deleteModalEntryWithData";
import { deleteModalEvidentionEntry } from "../../api/deleteModalEvidentionEntry";
import { getUserEntryByType } from "../../api/getTerapijeModalData";
export const fetchTerapijeModalData = async (
  eventType,
  userId,
  accessToken,
  setModalData,
  setCloseModal
) => {
  const response = await getUserEntryByType(eventType, userId, accessToken);
  setModalData(response);
  if (response.length === 0) {
    setCloseModal(true);
  }
};

export const handleDeleteEntry = async (
  eventId,
  userId,
  accessToken,
  eventType,
  setModalData,
  setCloseModal,
  setDeleteFlag,
  deleteFlag,
  item
) => {
  if (eventType === "therapy_manual" && item?.otherInfo.therapistId) {
    const data = {
      therapistId: item?.otherInfo.therapistId,
      date: item?.otherInfo.date,
      brojSati: item?.otherInfo.brojSati,
      eventType: "manual_work_with_client",
    };

    await deleteModalEntryWithData(data, accessToken);
  } else if (
    eventType === "manual_work_with_client" &&
    item?.otherInfo.clientId
  ) {
    const data = {
      clientId: item?.otherInfo.clientId,
      date: item?.otherInfo.date,
      brojSati: item?.otherInfo.brojSati,
      eventType: "therapy_manual",
    };

    await deleteModalEntryWithData(data, accessToken);
  } else {
    console.error("Invalid eventType");
    return;
  }
  const response = await deleteModalEvidentionEntry(eventId, accessToken);
  if (response.status === 200) {
    fetchTerapijeModalData(
      eventType,
      userId,
      accessToken,
      setModalData,
      setCloseModal
    );
  }
  setDeleteFlag(!deleteFlag);
};
