import React from "react";
import Trash from "../../../../../assets/img/TrashIcon.png";
import Disabled from "../../../../../assets/img/TrashLight.png";
import { useUserState } from "../../../../../context/UserContext";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import { formatDate } from "../../../../myProfile/utils";
import { handleDeleteEntry } from "../utils";

function RenderTableData({
  data = [],
  userId,
  eventType,
  setModalData,
  setCloseModal,
}) {
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  return (
    <>
      {data.map((item, index) => (
        <tr key={index}>
          <td>
            {item?.otherInfo?.date
              ? formatDate(item?.otherInfo?.date)
              : formatDate(item?.otherInfo?.year) ||
                formatDate(item?.otherInfo?.date) === ""
              ? formatDate(item?.otherInfo?.year)
              : "Nema podataka"}
          </td>
          {item?.otherInfo?.tipGrupneSupervizije && (
            <td>
              {item?.otherInfo?.tipGrupneSupervizije
                ? item?.otherInfo?.tipGrupneSupervizije === "trijada"
                  ? "Trijada"
                  : item?.otherInfo?.tipGrupneSupervizije === "eper"
                  ? "EPeR"
                  : item?.otherInfo?.tipGrupneSupervizije === "ags"
                  ? "AGS"
                  : "Nema podataka"
                : null}
            </td>
          )}
          <td>
            {item?.otherInfo?.name
              ? item?.otherInfo?.name
              : item?.otherInfo?.fullName
              ? item?.otherInfo?.fullName === "Vanjski supervizor" &&
                item?.otherInfo?.vanjskiSupervizor
                ? item?.otherInfo?.vanjskiSupervizor
                : item?.otherInfo?.fullName === "Vanjski terapeut" &&
                  item?.otherInfo?.vanjskiTerapeut
                ? item?.otherInfo?.vanjskiTerapeut
                : item?.otherInfo?.fullName
              : item?.otherInfo?.uloga
              ? item?.otherInfo?.uloga
              : "Nema podataka"}
          </td>
          <td style={{ paddingLeft: 86 }}>
            {item?.otherInfo?.gprSati
              ? item?.otherInfo?.gprSati
              : item?.otherInfo?.brojSati
              ? item?.otherInfo?.brojSati
              : item?.otherInfo?.satiGrupneSupervizije ||
                item?.otherInfo?.satiIndividualneSupervizije
              ? item?.otherInfo?.satiIndividualneSupervizije +
                item?.otherInfo?.satiGrupneSupervizije
              : 1}
          </td>
          <td style={{ paddingLeft: 70 }}>
            <button
              className={
                userId === "Referada" || userId === "Voditelj"
                  ? "table-terapije-btn-disabled"
                  : "table-terapije-btn"
              }
              disabled={userId === "Referada"}
              onClick={() =>
                handleDeleteEntry(
                  item.eventId,
                  userData.id,
                  authToken,
                  eventType,
                  setModalData,
                  setCloseModal
                )
              }
            >
              <img
                alt=""
                src={
                  userId === "Referada" || userId === "Voditelj"
                    ? Disabled
                    : Trash
                }
                className="table-terapije-trash-icon"
              />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
}

export default RenderTableData;
