import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Exit from "../../../assets/img/Exit.png";

export const CardWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #E2E2E2",
  borderRadius: 8,
  width: 203,
  height: 248,
  position: "relative",
  padding: 16,
  "&:hover": {
    cursor: "pointer",
  },
}));

export const DeleteButton = styled(`button`)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(${Exit})`,
  backgroundSize: "50%",
  backgroundPositionX: "center",
  backgroundPositionY: "center",
  backgroundRepeat: "no-repeat",
  position: "absolute",
  right: "-16px",
  top: "-16px",
  backgroundColor: "#E2E2E2",
  borderRadius: 39,
  width: 32,
  height: 32,
  border: "none",
  transition: "backgroundColor 300ms ease-in-out",
  "&:hover": {
    backgroundColor: "#d1d9e2",
    cursor: "pointer",
  },
}));

export const InfoContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));

export const ImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "backgroundImage",
})(({ backgroundImage }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 112,
  width: "100%",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "auto",
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "center",
  backgroundPositionY: "center",
  borderRadius: 6,
}));

export const Name = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: 20,
  letterSpacing: " -0.001em",
  color: "#4D4D4D",
  textAlign: "left",
  paddingLeft: 12,
  width: "100%",
}));
export const Row = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 6,
  paddingLeft: 10,
}));

export const SmallTypography = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: " -0.001em",
  color: "#4D4D4D",
  textAlign: "left",
  maxWidth: "18ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
  overflowWrap: "break-word",
}));
