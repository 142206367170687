import axios from "axios";
import { getAllProgramsReferada } from "../../groups/api/getAllProgramsReferada";
import { fetchCities } from "../../myProfile/apiUtils";
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchData = async (
  pojediniPolaznikID,
  accessToken,
  setUserData,
  setUserInGroups,
  setPolozeniProgram,
  setIsZakupitelj,
  setMjestoUpisaOptions,
  setAllPrograms,
  setZakupiteljType
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/getPolaznik?id=${pojediniPolaznikID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const resData = response.data;
    setUserData(resData.userDetails);
    setUserInGroups(resData.aktivnaGrupa);
    setPolozeniProgram(resData.finishedPrograms);
    setIsZakupitelj(resData.userDetails.isZakupitelj);
    setZakupiteljType(resData.userDetails.zakupiteljType);
    const updatedMjestoUpisa = await fetchCities(accessToken);
    setMjestoUpisaOptions(updatedMjestoUpisa);
    const formattedOptions = await getAllProgramsReferada(accessToken);
    setAllPrograms(formattedOptions);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
