import Quill from "quill";
import ImageCompress from "quill-image-compress";
import "quill/dist/quill.snow.css";
import React, { useEffect, useRef, useState } from "react";

function QuillEditor({
  answer,
  description,
  setUserAnswer,
  userId,
  handleUpdate,
  setIsDone,
  isMentorstva,
}) {
  Quill.register("modules/imageCompress", ImageCompress);
  const quillContainerRef = useRef(null);
  const quillInstanceRef = useRef(null);

  const minCharsCount = 1500; //minimalni broj slova u introspekciji - user

  const [prevAnswer, setPrevAnswer] = useState("");
  const [testis, setTestis] = useState("");
  const [charsCount, setCharsCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (testis !== prevAnswer) {
        setPrevAnswer(testis);
        handleUpdate();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [testis, prevAnswer, handleUpdate]);

  useEffect(() => {
    if (quillContainerRef.current && !quillInstanceRef.current) {
      quillInstanceRef.current = new Quill(quillContainerRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ font: ["serif", "monospace"] }],
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "blockquote"],
            [{ color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["image"],
          ],
          imageCompress: {
            quality: 0.7,
            maxWidth: 1000,
            maxHeight: 1000,
            imageType: "image/jpeg",
            debug: false,
            suppressErrorLogging: false,
            insertIntoEditor: undefined,
          },
        },
        readOnly: false, //isMentorstva
      });

      const handleKeydown = (event) => {
        if (event.key === "Enter") {
          updateCharacterCount();
        }
      };

      quillInstanceRef.current.on("text-change", () => {
        const html = quillInstanceRef.current.root.innerHTML;
        setUserAnswer(html);
        setTestis(html);
        updateCharacterCount();
      });

      quillInstanceRef.current.root.addEventListener("keydown", handleKeydown);

      return () => {
        quillInstanceRef.current.root.removeEventListener(
          "keydown",
          handleKeydown
        );
      };
    }

    if (quillInstanceRef.current) {
      quillInstanceRef.current.root.innerHTML = answer;
    }
  }, [quillContainerRef, answer, userId]);

  const updateCharacterCount = () => {
    const delta = quillInstanceRef.current.getContents();
    const text = delta.ops
      .map((op) => (typeof op.insert === "string" ? op.insert : ""))
      .join("")
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?]/g, "")
      .replace(/\s/g, "").length;

    if (text < minCharsCount) {
      setCharsCount(text);
      setIsDone(false);
    } else {
      setCharsCount(minCharsCount);
      setIsDone(true);
    }
  };

  return (
    <>
      <div className="introspekcije-description">{description}</div>
      <div
        ref={quillContainerRef}
        id="quill-container"
        style={{
          height: "400px",
          width: "100%",
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
      ></div>
      {userId === "Polaznik" && (
        <div
          className="introspekcije-description"
          style={{ width: "100%", textAlign: "right", fontSize: 15 }}
        >
          {charsCount}/{minCharsCount}
        </div>
      )}
    </>
  );
}

export default QuillEditor;
