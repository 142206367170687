import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../../../assets/svg/SearchIcon.svg";
import BackComponent from "../../../../components/BackComponent/BackComponent";
import Modal from "../../../../components/Modal/Modal";
import { useUserState } from "../../../../context/UserContext";
import { getAllUsers } from "../../../calendarIpd/api/getAllUsers";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { AktualniRadKlijentiValidationSchema } from "./Schema";
import styles from "./Styles.module.css";
import { handleInputChange, handleUnesiSateClick, postData } from "./utils";

function AktualniRadKlijenti({
  currentAcademicYearStart,
  currentAcademicYearEnd,
  userEvidentionID,
}) {
  const navigate = useNavigate();
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [polaznik, setPolaznik] = useState("");
  const [allPolaznici, setAllPolaznici] = useState([]);
  const fullName = localStorage.getItem("evidentionFullName");
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  useEffect(() => {
    const fetchPolaznici = async () => {
      const polaznici = await getAllUsers(authToken);
      setAllPolaznici(
        polaznici.map((user) => ({
          name: `${user.firstName} ${user.lastName}`,
          id: user.id,
        }))
      );
    };

    if (authToken) fetchPolaznici();
  }, [authToken]);
  const [formData, setFormData] = useState({
    fullName: "",
    date: new Date().toISOString().split("T")[0],
    year: "",
    brojSati: 1,
    clientId: "",
  });
  const filteredValues = allPolaznici.filter((value) =>
    value.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSearchChange = (event) => {
    const searchTextValue = event.target.value;
    setSearchText(searchTextValue);
    setShowDropdown(searchTextValue.length > 0);
  };

  const handlePolaznikClick = (value) => {
    setSearchText(value.name);
    setPolaznik(value);
    setFormData({
      ...formData,
      fullName: value.name,
      clientId: value.id,
    });
    setShowDropdown(false);
  };

  const otherInfo = JSON.stringify(
    postData("manual_work_with_client", formData)
  );
  const [errors, setErrors] = useState({});
  return (
    <div className={styles.wrapper}>
      <BackComponent />
      <span className={styles.title}>
        Ručni unos sati za aktualni rad s klijentom
      </span>
      <div className={styles.inputWrapper}>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Ime i prezime klijenta</span>
          {/* <input
            className={styles.rucniUnosInput}
            type="text"
            name="fullName"
            placeholder="Upiši"
            value={formData.fullName}
            onChange={(e) =>
              handleInputChange(
                "fullName",
                e.target.value,
                setFormData,
                formData
              )
            }
          /> */}
          <div className={styles.searchContainer}>
            <label htmlFor="" className={styles.labelS}>
              <input
                type="search"
                className={styles.inputS}
                placeholder="Upiši"
                value={searchText}
                onChange={(event) => handleSearchChange(event)}
              />
              <img src={SearchIcon} alt="" />
            </label>
            {filteredValues.length > 0 && showDropdown && (
              <div className={styles.dropdown}>
                {filteredValues.slice(0, 5).map((value) => (
                  <div
                    key={value.id}
                    className={styles.dropdownItem}
                    onClick={() => handlePolaznikClick(value)}
                  >
                    <p className={styles.p}>{value.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          {errors.fullName && (
            <div className={styles.error}>{errors.fullName}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Datum održavanja</span>
          <input
            className={styles.rucniUnosInput}
            type="date"
            name="date"
            value={formData.date}
            onChange={(e) =>
              handleInputChange("date", e.target.value, setFormData, formData)
            }
          />
          {errors.date && <div className={styles.error}>{errors.date}</div>}
        </div>
      </div>
      <div className={styles.btnRow}>
        <button
          onClick={() => {
            localStorage.setItem("evidentionFullName", fullName);
            localStorage.setItem("evidentionUserID", userEvidentionID);
            navigate("/evidencija");
          }}
          className={styles.cancelBtn}
        >
          Odustani
        </button>
        <button
          className={styles.successBtn}
          onClick={() =>
            handleUnesiSateClick(
              setErrors,
              formData,
              AktualniRadKlijentiValidationSchema,
              userEvidentionID,
              "manual_work_with_client",
              otherInfo,
              authToken,
              setModalContent,
              navigate,
              setShowModal,
              userData
            )
          }
        >
          Unesi sate
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default AktualniRadKlijenti;
