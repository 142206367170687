import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Box } from "@mui/material";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Alert from "../../assets/img/AlertIcon.png";
import BackArrow from "../../assets/img/BackArrow.png";
import IPDLogo from "../../assets/svg/IPDLogo.svg";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import UserMenu from "../../components/userMenu/UserMenu";
import { useUserState } from "../../context/UserContext";
import { getAllCities } from "../groups/api/getAllCities";
import { useAuthentication } from "../hooks/useAuthentication";
import styles from "../obracunSoba/ObracunSoba.module.css";
import { getAllRooms } from "./api/getAllRooms";
import { getAllUsers } from "./api/getAllUsers";
import { getEvents } from "./api/getEvents";
import { updateDefaultCity } from "./api/updateDefaultCity";
import "./App.css";
import CreateModal from "./components/CreateModal";
import InfoModal from "./components/InfoModal";
import { handleDatesSet, selectStyles } from "./utils";

const CalendarIpd = () => {
  const navigate = useNavigate();
  const currentDate = new Date();
  const { role, userData, setUserData } = useUserState();
  const { authToken } = useAuthentication();
  const [events, setEvents] = useState();
  const [isDayGridMonth, setIsDayGridMonth] = useState(false);
  const [info, setInfo] = useState({});
  const [rooms, setRooms] = useState([]);
  const [cities, setCities] = useState([]);
  const [secondRooms, setSecondRooms] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [type, setType] = useState("");
  const [defaultSelectedCity, setDefaultSelectedCity] = useState(
    userData?.defaultCity || null
  );
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedCityForSecond, setSelectedCityForSecond] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateForSecond, setSelectedDateForSecond] = useState(null);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );
  const [selectedTime, setSelectedTime] = useState({
    startTime: null,
    endTime: null,
  });
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [showDefaultCityRadios, setShowDefaultCityRadios] = useState(
    userData?.defaultCity === null
  );
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [secondSelectedRoomId, setSecondSelectedRoomId] = useState(null);
  const [citiesForSecond, setCitiesForSecond] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [editEventValues, setEditEventValues] = useState({
    eventId: "",
    eventType: "",
    supervisionType: "",
    clientId: "",
    outsideClient: "",
    name: "",
    date: "",
    timeFrom: "",
    timeTo: "",
    description: "",
    roomId: "",
  });

  const filterByCity = (selectedCity) => {
    const filteredRooms = allRooms?.filter(
      (room) => room.cityId == selectedCity?.value
    );
    setRooms(filteredRooms);
  };

  const handleDefaultCityChange = (value, label) => {
    setDefaultSelectedCity({ value, label });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getAllUsers(authToken);
      setAllUsers(response);
    };
    if (authToken) fetchUsers();
  }, [authToken]);

  useEffect(() => {
    const fetchCities = async () => {
      const response = await getAllCities(authToken);
      const filteredResponse = response.filter(
        (city) => city.label !== "Podgorica" && city.label !== "Rijeka"
      );
      setCities(filteredResponse);
      setCitiesForSecond(filteredResponse);
    };
    if (authToken) fetchCities();
  }, [authToken]);

  useEffect(() => {
    const fetchRooms = async () => {
      const response = await getAllRooms(authToken);
      setAllRooms(
        response.rooms.map((room) => ({
          label: room.roomName,
          value: room.id,
          cityId: room.cityId,
        }))
      );
    };
    if (authToken) fetchRooms().then(() => filterByCity(selectedCity));
  }, [authToken]);

  const handleRoomChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((item) => item.value);
    setSelectedRooms(selectedValues);
  };
  const handleCityChange = (selectedOptions) => {
    setSelectedCity(selectedOptions);
  };

  const handleCityChangeForSecond = (selectedOptions) => {
    setSelectedCityForSecond(selectedOptions);
  };

  const handleDateChange = (event) => {
    setSelectedDateForSecond(event.target.value);
  };

  const handleSubmitDefaultCity = async () => {
    await updateDefaultCity(defaultSelectedCity.value, authToken);
    setShowDefaultCityRadios(false);
    setUserData((prevState) => ({
      ...prevState,
      defaultCity: defaultSelectedCity.value,
    }));
  };

  const licence = document.querySelector(".fc-license-message");
  if (licence) licence.style.display = "none";

  useEffect(() => {
    const updatedEndDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 2,
      2
    );
    if (authToken)
      getEvents(
        startDate,
        updatedEndDate,
        selectedCity?.value,
        selectedRooms,
        authToken,
        setEvents
      );
  }, [reRender, startDate, selectedRooms, selectedCity, authToken]);

  useEffect(() => {
    const updatedEndDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 2,
      2
    );
    const roomIds = allRooms.map((room) => room.value);
    if (authToken)
      getEvents(
        startDate,
        updatedEndDate,
        selectedCity?.value,
        roomIds,
        authToken,
        setAllEvents
      );
  }, [reRender, startDate, authToken, selectedCity]);

  const handleDateClick = (info) => {
    const date = info.dateStr.split("T")[0];
    const secondRoomId = info?.resource?.id;
    setType("create");
    setSelectedDate(date);
    setSecondSelectedRoomId(secondRoomId);
    setIsCreateOpen(true);
  };

  useEffect(() => {
    const filteredRooms = allRooms?.filter(
      (room) => room.cityId == selectedCityForSecond?.value
    );
    setSecondRooms(filteredRooms);
  }, [selectedCityForSecond, rooms]);

  const handleEventClick = (info) => {
    setType("edit");
    const dateStart = new Date(info.event._instance.range.start);
    const dateEnd = new Date(info.event._instance.range.end);

    dateStart.setHours(dateStart.getHours() - 1);
    dateEnd.setHours(dateEnd.getHours() - 1);

    const hoursStart = dateStart.getHours();
    const minutesStart = dateStart.getMinutes();
    const secondsStart = dateStart.getSeconds();

    const hoursEnd = dateEnd.getHours();
    const minutesEnd = dateEnd.getMinutes();
    const secondsEnd = dateEnd.getSeconds();

    const formattedTimeStart = `${hoursStart
      .toString()
      .padStart(2, "0")}:${minutesStart
      .toString()
      .padStart(2, "0")}:${secondsStart.toString().padStart(2, "0")}`;

    const formattedTimeEnd = `${hoursEnd
      .toString()
      .padStart(2, "0")}:${minutesEnd.toString().padStart(2, "0")}:${secondsEnd
      .toString()
      .padStart(2, "0")}`;

    const secondInfo = {
      start: formattedTimeStart,
      end: formattedTimeEnd,
      roomId: info.event._def?.resourceIds
        ? info.event._def.resourceIds[0]
        : null,
      title: info.event._def.title,
    };
    const clickedEvent = info.event._def.extendedProps;
    const isEditable = clickedEvent.isEditable;
    const eventStart = info.event.start;
    if (role !== "Polaznik") {
      setType("edit");

      if (!isEditable) {
        if (secondInfo.roomId === null) {
          setInfo({
            room: clickedEvent.roomId,
            timeFrom: clickedEvent.timeFrom,
            timeTo: clickedEvent.timeTo,
            eventType: clickedEvent.eventType,
            therapistName: clickedEvent.therapistName || null,
          });
        } else {
          setInfo({
            room: parseInt(secondInfo.roomId, 10),
            timeFrom: secondInfo.start.slice(0, 5),
            timeTo: secondInfo.end.slice(0, 5),
            eventType: clickedEvent.eventType,
            title: secondInfo.title,
            therapistName: clickedEvent.therapistName || null,
          });
        }
        setIsInfoOpen(true);
      } else {
        if (
          clickedEvent.eventType === "therapy" ||
          clickedEvent.eventType === "individual_supervision" ||
          clickedEvent.eventType === "other"
        ) {
          setEditEventValues({
            eventId: info.event.id ? info.event.id : "",
            eventType: clickedEvent.eventType ? clickedEvent.eventType : "",
            supervisionType: "individual",
            clientId: clickedEvent.clientId ? clickedEvent.clientId : "",
            outsideClient: clickedEvent.clientOutsideIPD
              ? clickedEvent.clientOutsideIPD
              : "",
            name: clickedEvent.name ? clickedEvent.name : "",
            date: eventStart ? eventStart.toISOString().split("T")[0] : "",
            timeFrom: clickedEvent.timeFrom
              ? clickedEvent.timeFrom.slice(0, -3)
              : "",
            timeTo: clickedEvent.timeTo ? clickedEvent.timeTo.slice(0, -3) : "",
            description: clickedEvent.description
              ? clickedEvent.description
              : "",
            roomId: clickedEvent.roomId ? clickedEvent.roomId : "",
          });
          setIsCreateOpen(true);
        } else {
          setInfo({
            room: clickedEvent.roomId ? clickedEvent.roomId : "",
            timeFrom: secondInfo.start?.slice(0, 5),
            timeTo: secondInfo.end?.slice(0, 5),
            eventType: clickedEvent.eventType,
            title: clickedEvent.name || capitalize(clickedEvent.eventType),
            therapistName: clickedEvent.therapistName || null,
          });
          setIsInfoOpen(true);
        }
      }
      setIsInfoOpen(true);
    } else {
      const data = {
        therapistName: clickedEvent.therapistName || null,
        room: parseInt(clickedEvent.roomId, 10),
        timeFrom: secondInfo.start.slice(0, 5),
        timeTo: secondInfo.end.slice(0, 5),
        eventType: clickedEvent.eventType,
        title: clickedEvent.title,
        clientId: clickedEvent.clientId ? clickedEvent.clientId : "",
        outsideClient: clickedEvent.clientName ? clickedEvent.clientName : "",
        name: clickedEvent.name ? clickedEvent.name : "",
        date: eventStart ? eventStart.toISOString().split("T")[0] : "",
        timeFrom: clickedEvent.timeFrom
          ? clickedEvent.timeFrom.slice(0, -3)
          : "",
        timeTo: clickedEvent.timeTo ? clickedEvent.timeTo.slice(0, -3) : "",
        description: clickedEvent.description ? clickedEvent.description : "",
        roomId: clickedEvent.roomId ? clickedEvent.roomId : "",
      };
      setIsCreateOpen(true);
    }
  };

  useEffect(() => {
    if (userData?.defaultCity !== null) {
      handleCityChange(
        cities?.find((city) => city.value === userData?.defaultCity)
      );
      handleCityChangeForSecond(
        cities?.find((city) => city.value === userData?.defaultCity)
      );
    } else {
      handleCityChange({ label: "Split", value: 1 });
      handleCityChangeForSecond({ label: "Split", value: 1 });
    }
  }, [authToken, cities]);

  const filterEventsByRooms = (selectedRooms) => {
    if (!selectedRooms) {
      setFilteredEvents(events);
    } else if (selectedRooms.length === 0) {
      const filtered = events?.filter((event) => {
        return selectedCity?.value === event.roomId;
      });
      setFilteredEvents(filtered);
    } else {
      const filtered = events?.filter((event) => {
        return selectedRooms.some((room) => room.value === event.roomId);
      });

      setFilteredEvents(filtered);
    }
  };

  useEffect(() => {
    filterEventsByRooms(selectedRooms);
  }, [selectedRooms, events]);

  useEffect(() => {
    filterByCity(selectedCity);
  }, [selectedCity, allRooms]);

  const resourceRooms = secondRooms.map((room) => ({
    id: room.value,
    title: room.label,
  }));

  const transformedEvents = allEvents.map((event) => {
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);

    startDate.setHours(startDate.getHours() - 1);
    endDate.setHours(endDate.getHours() - 1);

    return {
      id: event.id,
      title: event?.name
        ? event?.name
        : capitalize(event?.eventType) || "Unnamed Event",
      start: startDate?.toISOString(),
      end: endDate?.toISOString(),
      resourceId: event?.roomId?.toString(),
    };
  });

  let transformedAllEvents = [];

  if (events && events.length > 0) {
    transformedAllEvents = events.map((event) => {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);

      startDate.setHours(startDate.getHours() - 1);
      endDate.setHours(endDate.getHours() - 1);

      return {
        ...event,
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      };
    });
  }

  console.log(resourceRooms);

  return (
    <div>
      {isCreateOpen && (
        <CreateModal
          type={type}
          users={allUsers}
          date={selectedDate}
          timeProp={selectedTime}
          rooms={rooms}
          onClick={() => setIsCreateOpen(false)}
          startDate={startDate}
          endDate={endDate}
          selectedRooms={selectedRooms}
          setEvents={setEvents}
          reRender={reRender}
          setReRender={setReRender}
          setIsCreateOpen={setIsCreateOpen}
          editEventValues={editEventValues}
          secondSelectedRoomId={secondSelectedRoomId}
        />
      )}
      {isInfoOpen && (
        <InfoModal
          data={info}
          rooms={allRooms}
          onClick={() => setIsInfoOpen(false)}
        />
      )}

      <div className={`${styles.nav} ${styles.kalendarNav}`}>
        <div
          className={styles.back}
          onClick={
            role === "Polaznik"
              ? () => navigate("/dashboard")
              : () => navigate("/kalendar")
          }
        >
          <div className={styles.backToHome}>
            <img src={BackArrow} alt="" className={styles.backArrow} />
            <h2 className={styles.h2Calendar}>Natrag</h2>
          </div>
          <h1 className={styles.h1}>Kalendar IPD-a</h1>
        </div>
        <div className={styles.logo}>
          <img src={IPDLogo} alt="" />
        </div>
        <div className={styles.user}>
          <div className={styles.userRow}>
            <button className={styles.notificationBtn}>
              <img alt="" src={Alert} />
            </button>
            <UserMenu />
          </div>
        </div>
      </div>
      <MobileHeader />
      <Box>
        <Box display="flex" justifyContent="space-between" marginTop={20}>
          <div className="calendar-sidebar-container">
            {showDefaultCityRadios && (
              <div className="default-city-container">
                <p
                  style={{
                    fontSize: 18,
                    color: "#5f6d7e",
                    fontWeight: 600,
                    fontFamily: "Inter",
                    marginBlockStart: 0,
                  }}
                >
                  Odaberite grad koji najviše gledate:
                </p>
                {cities.map((city, index) => (
                  <div key={index} className="option-container">
                    <input
                      type="radio"
                      name="default-city"
                      className="default-city-option"
                      value={city.value}
                      onChange={() =>
                        handleDefaultCityChange(city.value, city.label)
                      }
                      checked={defaultSelectedCity?.value === city.value}
                    />{" "}
                    <label
                      htmlFor={city.value}
                      style={{
                        fontSize: 15,
                        color: "#5f6d7e",
                        fontWeight: 400,
                        fontFamily: "Inter",
                      }}
                    >
                      {city.label}
                    </label>
                  </div>
                ))}
                <button
                  className="default-btn"
                  onClick={() => handleSubmitDefaultCity()}
                >
                  Spremi
                </button>
              </div>
            )}
            <br />
            <Select
              options={cities}
              styles={selectStyles}
              onChange={handleCityChange}
              value={selectedCity}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
            <br />
            <Select
              options={rooms}
              styles={selectStyles}
              onChange={handleRoomChange}
              placeholder="Prikaži za sobe"
              isSearchable={false}
              isMulti
              components={{ IndicatorSeparator: () => null }}
            />
            <br />
            {/* <Select
                options={[]}
                styles={selectStyles}
                onChange={() => {}}
                placeholder="Predavanja"
                isSearchable={false}
                isDisabled={true}
                components={{ IndicatorSeparator: () => null }}
              />
              <br />
              <Select
                options={[]}
                styles={selectStyles}
                onChange={() => {}}
                placeholder="Radionice"
                isSearchable={false}
                isDisabled={true}
                components={{ IndicatorSeparator: () => null }}
              />
              <br />
              <Select
                options={[]}
                styles={selectStyles}
                onChange={() => {}}
                placeholder="Fakultativno"
                isSearchable={false}
                isDisabled={true}
                components={{ IndicatorSeparator: () => null }}
              /> */}
          </div>

          <Box flex="1 1 100%" ml="15px">
            <div className="fc">
              <FullCalendar
                height="75vh"
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                headerToolbar={{
                  left: "prev,today,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                }}
                dateClick={role !== "Polaznik" ? handleDateClick : null}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                datesSet={(info) => {
                  const isMonthView = info.view.type === "dayGridMonth";
                  setIsDayGridMonth(isMonthView);
                  handleDatesSet(
                    info,
                    selectedRooms,
                    authToken,
                    setEvents,
                    setStartDate,
                    setEndDate,
                    startDate,
                    endDate
                  );
                }}
                selectMirror={true}
                dayMaxEvents={true}
                select={
                  role !== "Polaznik" && ((info) => handleDateClick(info))
                }
                eventClick={handleEventClick}
                events={transformedAllEvents}
                eventContent={(data) => (
                  <div
                    style={{
                      overflow: "hidden",
                      minHeight: "18px",
                      padding: 2,
                      boxSizing: "border-box",
                    }}
                  >
                    {data.event._def.extendedProps.name
                      ? data.event._def.extendedProps.name
                      : capitalize(data.event._def.extendedProps.eventType)}
                  </div>
                )}
                slotMinTime="08:00:00"
                slotMaxTime="22:00:00"
                allDaySlot={false}
                eventMouseEnter={() => {}}
                eventMouseLeave={() => {}}
                slotLabelContent={(slotInfo) => {
                  const hours = slotInfo.date.getHours() % 12 || 12;
                  const meridian =
                    slotInfo.date.getHours() >= 12 ? " PM" : " AM";
                  return (
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#71717A",
                        fontFamily: "Inter",
                      }}
                    >
                      {hours === 0 ? "12" : hours}
                      {meridian}
                    </span>
                  );
                }}
                dayHeaderContent={(info) => {
                  const dayName = info.date
                    .toLocaleString("en-US", { weekday: "short" })
                    .toUpperCase();
                  const date = info.date.getDate();
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                      }}
                    >
                      <div
                        style={{
                          color: "#71717A",
                          fontSize: "11px",
                          fontWeight: "700",
                          fontFamily: "Inter",
                        }}
                      >
                        {dayName}
                      </div>
                      {!isDayGridMonth ? (
                        <div
                          style={{
                            fontSize: "22px",
                            fontWeight: "500",
                            color: "#000000",
                            fontFamily: "Inter",
                          }}
                        >
                          {date}
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "10px",
                          }}
                        ></div>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </Box>
        </Box>
      </Box>
      {true && (
        <div>
          <h1
            style={{
              marginTop: 50,
              width: "100%",
              textAlign: "center",
              fontSize: 28,
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#4D4D4D",
            }}
          >
            Pregled po sobama
          </h1>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 40,
            }}
          >
            <div
              style={{
                width: "250px",
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <label
                htmlFor="dateSelect"
                style={{
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#5F6D7E",
                }}
              >
                Odaberi grad
              </label>
              <Select
                options={citiesForSecond}
                styles={selectStyles}
                onChange={handleCityChangeForSecond}
                value={selectedCityForSecond}
                isSearchable={false}
                isDisabled={false}
                components={{ IndicatorSeparator: () => null }}
              />
            </div>
            {/* <div
              style={{
                width: "250px",
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <label
                htmlFor="dateSelect"
                style={{
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#5F6D7E",
                }}
              >
                Odaberi datum
              </label>
              <input
                style={{
                  padding: 8,
                  border: "1px solid #b9bec4",
                  borderRadius: 4,
                  textAlign: "left",
                  color: "#5F6D7E",
                  boxShadow: "4px 4px 64px 0px #5F6D7E1A",
                  fontFamily: "Inter",
                  fontSize: 16,
                  fontWeight: 500,
                  lineHeight: 1.6,
                  letterSpacing: " -0.001em",
                  width: "100%",
                  cursor: "pointer",
                  boxSizing: "border-box",
                  height: 38,
                }}
                type="date"
                id="date"
                name="date"
                value={selectedDateForSecond}
                onChange={handleDateChange}
                disabled={true}
              />
            </div> */}
          </div>
          <div className="fc" style={{ margin: 50 }}>
            <FullCalendar
              height="75vh"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                resourceTimelinePlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                right: "prev,today,next",
                left: "title",
              }}
              dateClick={() => {}}
              initialView="resourceTimelineDay"
              editable={true}
              selectable={true}
              resourceAreaHeaderContent="Sobe"
              resources={resourceRooms}
              resourceOrder={resourceRooms[0]?.id}
              datesSet={(info) =>
                handleDatesSet(
                  info,
                  selectedRooms,
                  authToken,
                  setEvents,
                  setStartDate,
                  setEndDate,
                  startDate,
                  endDate
                )
              }
              selectMirror={true}
              dayMaxEvents={true}
              select={role !== "Polaznik" && ((info) => handleDateClick(info))}
              eventClick={handleEventClick}
              events={transformedEvents}
              slotMinTime="07:00:00"
              slotMaxTime="22:00:00"
              allDaySlot={false}
              eventMouseEnter={() => {}}
              eventMouseLeave={() => {}}
              slotLabelContent={(slotInfo) => {
                const hours = slotInfo.date.getHours() % 12 || 12;
                const meridian = slotInfo.date.getHours() >= 12 ? " PM" : " AM";
                return (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#71717A",
                      fontFamily: "Inter",
                    }}
                  >
                    {hours === 0 ? "12" : hours}
                    {meridian}
                  </span>
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarIpd;
