import axios from "axios";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import AppleIcon from "../../assets/svg/AppleIcon.svg";
import GoogleIcon from "../../assets/svg/GoogleIcon.svg";
import IPDLogo from "../../assets/svg/IPDLogo.png";
import { useUserState } from "../../context/UserContext";
import { appleProvider, auth } from "../../firebase";
import styles from "./Login.module.css";

const Login = () => {
  const navigate = useNavigate();
  const { setAccessToken, setUserData, setRole } = useUserState();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    await signInWithPopup(auth, provider)
      .then((result) => {
        const date = new Date();
        localStorage.setItem(
          "sessionTime",
          `${date.setHours(date.getHours() + 3)}`
        );

        const token = result.user.accessToken;
        localStorage.setItem("authToken", token);
        setAccessToken(token);
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/user`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (data) => {
            await axios
              .get(
                `${process.env.REACT_APP_API_URL}/api/role/${data.data.roleId}`,
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then((response) => setRole(response.data.name));
            setUserData(data.data);
            if (data.data.isArchived) {
              alert(
                "Školska godina je završila i potrebno je najkasnije do 20.10. upisati novu školsku godinu uplatom upisnine po obavijesti koja ti je poslana od strane Škole na tvoj email kako bi ti se produžio pristup radionicama, programima i softveru. Za upis pauze, čime zadržavaš status polaznika Škole i pristup postojećim programima i softveru, molimo da se javiš na racunovodstvo@IPD-center.eu."
              );
            } else if (data.data.firstLogin) {
              navigate("/moj-profil", { state: { isFirstLogin: true } });
            } else if (!data.data.settingsSaved) {
              navigate("/postavke", { state: { firstSettingsSave: true } });
            } else {
              navigate("/dashboard");
            }
          })
          .catch(() => alert("Nisi registrirani korisnik"));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, credential);
      });
  };
  const handleAppleSignIn = () => {
    signInWithPopup(auth, appleProvider)
      .then(async (result) => {
        const token = await result.user.getIdToken();
        localStorage.setItem("authToken", token);
        setAccessToken(token);
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/user`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (data) => {
            await axios
              .get(
                `${process.env.REACT_APP_API_URL}/api/role/${data.data.roleId}`,
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then((response) => setRole(response.data.name));
            setUserData(data.data);
            if (data.data.firstLogin) {
              navigate("/moj-profil", { state: { isFirstLogin: true } });
            } else {
              navigate("/dashboard");
            }
          })
          .catch(() => alert("Nisi registrirani korisnik"));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = OAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, credential);
      });
  };
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <img src={IPDLogo} alt="logo" className={styles.logo} />
        <h2 className={styles.heading}>Prijavi se</h2>
        <div className={styles.buttonContainer}>
          <button className={styles.googleButton} onClick={handleGoogleSignIn}>
            <img
              src={GoogleIcon}
              alt="google icon"
              className={styles.googleIcon}
            />
            <p className={styles.googleButtonText}>
              Prijavi se s Google računom
            </p>
          </button>
          <button className={styles.appleButton} onClick={handleAppleSignIn}>
            <img
              src={AppleIcon}
              alt="google icon"
              className={styles.appleIcon}
            />
            <p className={styles.appleButtonText}>Prijavi se s Apple računom</p>
          </button>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.bottomHeadingRow}>
            <div className={styles.headingLine} />
            <p className={styles.bottomHeading}>Nemaš račun?</p>
            <div className={styles.headingLine} />
          </div>
          <div className={styles.bottomContent}>
            <p className={styles.bottomText}>
              Registracija korisnika je moguća isključivo kroz upis naših
              programa. <br />
              <br />
              Za sve informacije obratite nam se na{" "}
              <a href="mailto:contact@ipd-center.eu" className={styles.link}>
                contact@ipd-center.eu
              </a>
            </p>
            <p className={styles.bottomText}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
