import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import AddIcon from "../../assets/svg/AddIcon.svg";
import ArchiveIcon from "../../assets/svg/ArchiveIcon.svg";
import ExportIcon from "../../assets/svg/ExportIcon.svg";
import TrashIcon from "../../assets/svg/TrashIcon.svg";
import { useUserState } from "../../context/UserContext";
import Arhiviraj from "../arhiviraj/index.jsx";
import Delete from "../delete/index.jsx";
import Export from "../export/index.jsx";
import { useAuthentication } from "../hooks/useAuthentication";
import Polaznici from "../polaznici/index";
import TableAttendees from "../TableAttendees/index.jsx";
import styles from "./Attendees.module.css";
import "./index.css";
import SearchStudents from "./searchStudents/SearchStudents";
import {
  activeArchive,
  fetchCities,
  fetchData,
  fetchGroups,
  fetchSearchData,
  fetchWI,
  handleActivityChange,
  handleGrupaChange,
  handleIntensiveChange,
  handleProgramChange,
  handleSelectChange,
  handleWorkshopChange,
  MultiselectPlaceOption,
  MultiselectPlaceStyles,
  onlinePolaznici,
  programsWithGroups,
  selectStyles,
  selectStylesMogucnosti,
  sviPolaznici,
} from "./utils.js";

const Attendees = () => {
  const navigate = useNavigate();
  const { role } = useUserState();
  const [students, setStudents] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [groups, setGroups] = useState([]);
  const [intensives, setIntensives] = useState([]);
  const { authToken } = useAuthentication();
  const [mjestoUpisa, setMjestoUpisa] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [polazniciCount, setPolazniciCount] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProgram, setSelectedProgram] = useState({
    value: null,
    label: "Svi polaznici",
  });
  const [selectedActivity, setSelectedActivity] = useState(activeArchive[0]);
  const [selectedWorkshop, setSelectedWorkshop] = useState({
    value: null,
    label: "Sve radionice",
  });
  const [selectedIntensive, setSelectedIntensive] = useState({
    value: null,
    label: "Svi intenzivi",
  });
  const [selectedGroup, setSelectedGroup] = useState({
    value: null,
    label: "Sve grupe",
  });

  useEffect(() => {
    if (role !== "Referada") {
      navigate("/dashboard");
    }
  }, [role]);

  console.log(selectedActivity);

  useEffect(() => {
    if (selectedProgram.value === "22" || selectedProgram.value === "33") {
      setSelectedGroup({
        value: null,
        label: "Sve grupe",
      });
      if (authToken) fetchWI(authToken, setWorkshops, setIntensives);
    }
  }, [authToken, selectedProgram]);

  const debouncedFetchSearchData = useMemo(() => {
    return _.debounce(
      (
        currentPage,
        authToken,
        setStudents,
        setPolazniciCount,
        searchResults
      ) => {
        fetchSearchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          searchResults,
          false
        );
      },
      400
    );
  }, [authToken]);

  useEffect(() => {
    const city = selectedOption.map((option) => option.label);
    if (searchResults.length > 0) {
      if (authToken)
        debouncedFetchSearchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          searchResults
        );
    } else {
      if (authToken)
        fetchData(
          currentPage,
          authToken,
          setStudents,
          setPolazniciCount,
          false,
          city,
          selectedProgram.value,
          selectedGroup.value,
          selectedWorkshop.value,
          selectedIntensive.value,
          selectedActivity.value
        );
    }
  }, [
    currentPage,
    authToken,
    selectedOption,
    selectedProgram,
    selectedGroup,
    polazniciCount,
    selectedWorkshop,
    selectedIntensive,
    searchResults,
    selectedActivity,
  ]);

  useEffect(() => {
    if (authToken) fetchCities(authToken, setMjestoUpisa);
  }, [authToken]);

  useEffect(() => {
    if (searchResults.length > 0) setCurrentPage(1);
  }, [searchResults]);

  useEffect(() => {
    if (
      selectedProgram.value !== null &&
      selectedProgram.value !== "22" &&
      selectedProgram.value !== "33"
    ) {
      if (authToken) fetchGroups(selectedProgram, authToken, setGroups);
    }
  }, [selectedProgram, selectedOption, authToken, currentPage]);

  const mogucnosti2 = [{ value: "", label: "Više mogućnosti" }];

  const mogucnosti = [
    {
      value: "export",
      label: (
        <div
          onClick={() => navigate("/export-polaznika")}
          className={styles.dropdownMoreOptionsRow}
        >
          <img src={ExportIcon} alt="" />
          <span>Export podataka</span>
          <Routes>
            <Route path="/export" element={<Export />} />
          </Routes>
        </div>
      ),
    },
    {
      value: "dodaj",
      label: (
        <div
          onClick={() => navigate("/dodaj-polaznika")}
          className={styles.dropdownMoreOptionsRow}
        >
          <img src={AddIcon} alt="" />
          <span>Dodaj polaznika</span>
          <Routes>
            <Route path="/polaznici2" element={<Polaznici />} />
          </Routes>
        </div>
      ),
    },
    {
      value: "arhiviraj",
      label: (
        <div
          onClick={() => navigate("/arhiviraj-polaznika")}
          className={styles.dropdownMoreOptionsRow}
        >
          <img src={ArchiveIcon} alt="" />
          <span>Arhiviraj polaznika</span>
          <Routes>
            <Route path="/arhiviraj" element={<Arhiviraj />} />
          </Routes>
        </div>
      ),
    },
    {
      value: "obrisi",
      label: (
        <div
          onClick={() => navigate("/delete-polaznik")}
          className={styles.dropdownMoreOptionsRow}
        >
          <img src={TrashIcon} alt="" />
          <span style={{ color: "#CB2D2D" }}>Obriši polaznika</span>
          <Routes>
            <Route path="/delete" element={<Delete />} />
          </Routes>
        </div>
      ),
    },
  ];
  const EllipsisDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faEllipsisV} style={{ color: "#5F6D7E" }} />
      </components.DropdownIndicator>
    );
  };
  const handleRowClick = (userId) => {
    navigate(`/pojedini-polaznik/${userId}`);
  };

  return (
    <div>
      <SearchStudents setSearchResults={setSearchResults} />
      <div className="table-filters-container">
        <div className="mjesto-upisa-filter">
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "22px",
              fontFamily: "Inter",
              color: "#4D4E4E",
              width: "50%",
              textAlign: "center",
            }}
          >
            Mjesto upisa
          </Typography>
          <Select
            isClearable={false}
            options={mjestoUpisa}
            value={selectedOption}
            isMulti
            onChange={(selectedOptions) =>
              handleSelectChange(
                selectedOptions,
                setSelectedOption,
                setCurrentPage
              )
            }
            styles={MultiselectPlaceStyles}
            isSearchable={false}
            components={{
              Option: MultiselectPlaceOption,
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <Select
          defaultValue={
            selectedOption.some(
              (option) => option.label === "Online" && option.value === 7
            )
              ? onlinePolaznici[0]
              : sviPolaznici[0]
          }
          options={
            selectedOption.some(
              (option) => option.label === "Online" && option.value === 7
            )
              ? onlinePolaznici
              : sviPolaznici
          }
          onChange={(selectedOption) =>
            handleProgramChange(
              selectedOption,
              setSelectedProgram,
              setCurrentPage
            )
          }
          styles={selectStyles}
          isSearchable={false}
          components={{ IndicatorSeparator: () => null }}
        />

        <Select
          defaultValue={activeArchive[0]}
          options={activeArchive}
          onChange={(selectedOption) =>
            handleActivityChange(
              selectedOption,
              setSelectedActivity,
              setCurrentPage
            )
          }
          styles={selectStyles}
          isSearchable={false}
          components={{ IndicatorSeparator: () => null }}
        />

        {programsWithGroups.includes(selectedProgram.value) && (
          <Select
            defaultValue={groups}
            options={groups}
            onChange={(selectedOption) =>
              handleGrupaChange(
                selectedOption,
                setSelectedGroup,
                setCurrentPage
              )
            }
            styles={selectStyles}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
          />
        )}
        {selectedProgram.value === "22" && (
          <Select
            defaultValue={workshops}
            options={workshops}
            onChange={(selectedOption) =>
              handleWorkshopChange(
                selectedOption,
                setSelectedWorkshop,
                setCurrentPage
              )
            }
            styles={selectStyles}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
          />
        )}
        {selectedProgram.value === "33" && (
          <Select
            defaultValue={intensives}
            options={intensives}
            onChange={(selectedOption) =>
              handleIntensiveChange(
                selectedOption,
                setSelectedIntensive,
                setCurrentPage
              )
            }
            styles={selectStyles}
            isSearchable={false}
            components={{ IndicatorSeparator: () => null }}
          />
        )}
        <Select
          defaultValue={mogucnosti2[0]}
          options={mogucnosti}
          styles={selectStylesMogucnosti}
          isSearchable={false}
          components={{
            DropdownIndicator: EllipsisDropdownIndicator,
            IndicatorSeparator: () => null,
          }}
        />
      </div>

      <Box marginTop="16px">
        <TableAttendees
          data={students}
          getRowId={(row) => row.user_uuid}
          onRowClick={handleRowClick}
          showAvatars={true}
          polazniciCount={polazniciCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setStudents={setStudents}
          setPolazniciCount={setPolazniciCount}
          cityId={mjestoUpisa}
          programId={selectedProgram}
          group={selectedGroup}
        />
      </Box>
    </div>
  );
};

export default Attendees;
