import axios from "axios";
import { getEvents } from "./getEvents";
const apiUrl = process.env.REACT_APP_API_URL;

export const deleteEvent = async (
  formValues,
  accessToken,
  startDate,
  endDate,
  selectedRooms,
  setEvents,
  setIsCreateOpen,
  reRender,
  setReRender
) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/deleteIPDCalendarEvent?eventId=${formValues.eventId}&eventType=${formValues.eventType}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      getEvents(
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
        selectedRooms,
        accessToken,
        setEvents
      );
      setIsCreateOpen(false);
      setReRender(!reRender);
    }
  } catch (error) {
    console.error("Error updating event", error);
  }
};
