import { deleteModalEvidentionEntry } from "../../api/deleteModalEvidentionEntry";
import { getUserEntryByType } from "../../api/getTerapijeModalData";
export const fetchTerapijeModalData = async (
  eventType,
  userId,
  accessToken,
  setModalData,
  setCloseModal
) => {
  const response = await getUserEntryByType(eventType, userId, accessToken);
  setModalData(response);
  if (response.length === 0) {
    setCloseModal(true);
  }
};

export const handleDeleteEntry = async (
  eventId,
  userId,
  accessToken,
  eventType,
  setModalData,
  setCloseModal
) => {
  const response = await deleteModalEvidentionEntry(eventId, accessToken);
  if (response.status === 200) {
    fetchTerapijeModalData(
      eventType,
      userId,
      accessToken,
      setModalData,
      setCloseModal
    );
  }
};
