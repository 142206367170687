import { Box } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import ProfilePhoto from "../../assets/img/blank_profile_photo.jpeg";
import Plus from "../../assets/img/WhitePlus.png";
import Modal from "../../components/Modal/Modal.jsx";
import ReusableTabs from "../../components/TabMenu/Tabs";
import { useCreateEventState } from "../../context/CreateEventContext";
import { useGroupState } from "../../context/CreateGroupContext.js";
import { useUserState } from "../../context/UserContext";
import { getUserById } from "../groups/api/getUserById";
import { useAuthentication } from "../hooks/useAuthentication";
import { fetchData, handleDodajVoditelje } from "./api.js";

import DjelatniciCard from "./components/djelatniciCard.jsx";
import UserCardSearch from "./components/SearchUserCards/UserCardSearch";
import styles from "./IPDTeam.module.css";
import ObrisiDjelatnika from "./ObrisiDjelatnika/ObrisiDjelatnika";
import {
  EllipsisDropdownIndicator,
  handleCardSelect,
  handleOdustaniClick,
  mogucnosti,
  mogucnosti2,
  selectStylesDisabled,
  selectStylesEnabled,
} from "./utils.js";

const IPDTeam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAddVoditelj = location?.state?.isAddVoditelj || false;
  const isAddVoditeljToEvent = location?.state?.isAddVoditeljToEvent || false;
  const groupId = isAddVoditelj && location?.state?.groupId;
  const voditeljiNow = isAddVoditeljToEvent && location?.state?.voditeljiNow;
  const fetchedGroupTeachers =
    (isAddVoditelj || isAddVoditeljToEvent) &&
    location?.state?.fetchedGroupTeachers;
  const addVoditeljiNewGroup = location.state?.addVoditeljiNewGroup;
  const createNewGroupSelectedVoditelji =
    location.state?.createNewGroupSelectedVoditelji;
  const { groupData, updateGroupData } = useGroupState();
  const { role } = useUserState();
  const { authToken } = useAuthentication();
  const [isDeleteDjelatnik, setIsDeleteDjelatnik] = useState(false);
  const [voditeljiData, setVoditeljiData] = useState([]);
  const [selectedVoditelji, setSelectedVoditelji] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [userData, setUserData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState("one");
  const [searchResults, setSearchResults] = useState(null);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const [selectedVoditeljiEvent, setSelectedVoditeljiEvent] = useState([]);
  const userId = role;
  const [deleteDjelatnik, setDeleteDjelatnik] = useState(true);
  const { createEventData, updateCreateEventData } = useCreateEventState();
  const [showDeleteDjelatnikButtons, setShowDeleteDjelatnikButtons] =
    useState(false);
  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  useEffect(() => {
    if (role !== "Voditelj" && role !== "Supervizor" && role !== "Referada") {
      navigate("/dashboard");
    }
  }, [role, authToken]);

  useEffect(() => {
    if (
      createNewGroupSelectedVoditelji &&
      createNewGroupSelectedVoditelji.length > 0
    ) {
      setSelectedVoditelji(createNewGroupSelectedVoditelji);
    }
  }, [createNewGroupSelectedVoditelji]);

  useEffect(() => {
    if (authToken)
      fetchData(
        authToken,
        setVoditeljiData,
        setTeacherData,
        setUserData,
        fetchedGroupTeachers,
        setSelectedVoditelji,
        setEmployeeData
      );
  }, [authToken, groupId, selectedDropdownOption]);

  useEffect(() => {
    const newData = {
      ...groupData,
      noviVoditelji: selectedVoditelji,
    };
    if (groupData.noviVoditelji !== selectedVoditelji) {
      updateGroupData(newData);
    }
  }, [selectedVoditelji, groupData, updateGroupData]);

  useEffect(() => {
    const fetchVoditelji = async () => {
      try {
        const voditeljiPromises = selectedVoditelji.map(async (id) => {
          return await getUserById(authToken, id);
        });
        const voditelji = await Promise.all(voditeljiPromises);
        setSelectedVoditeljiEvent(voditelji);
      } catch (error) {
        console.error("Error fetching voditelji:", error);
      }
    };

    if (authToken) fetchVoditelji();
  }, [selectedVoditelji]);

  const handleDodajVoditeljaToEvent = () => {
    const combinedVoditeljiData = [
      ...(createEventData.voditeljiData || []),
      ...(selectedVoditeljiEvent || []),
    ];
    const uniqueVoditeljiIds = Array.from(
      new Set(combinedVoditeljiData.map((voditelj) => voditelj.id))
    );
    const updatedVoditeljiData = uniqueVoditeljiIds.map((id) =>
      combinedVoditeljiData.find((voditelj) => voditelj.id === id)
    );
    updateCreateEventData({
      ...createEventData,
      voditeljiData: updatedVoditeljiData,
    });
    navigate(-1);
  };
  return (
    <div className={styles.wrapper}>
      <UserCardSearch data={employeeData} setSearchResults={setSearchResults} />
      {(isAddVoditelj || addVoditeljiNewGroup) && (
        <span className={styles.subtitle}>Dodaj voditelja u grupu</span>
      )}
      {isAddVoditeljToEvent && (
        <span className={styles.subtitle}>Dodaj voditelja</span>
      )}
      <div className={styles.tabContainer}>
        <ReusableTabs
          currentTabIndex={currentTabIndex}
          handleTabChange={handleTabChange}
          tabsData={[
            { value: "one", label: "Učitelji / supervizori" },
            { value: "two", label: "Polaznici VT-a" },
            { value: "three", label: "Diplomirani voditelji" },
          ]}
        />
        {userId !== "Voditelj" && (
          <Box>
            <Select
              defaultValue={mogucnosti2[0]}
              options={mogucnosti(navigate, setSelectedDropdownOption)}
              value={mogucnosti2[0]}
              styles={
                selectedDropdownOption === "obrisi"
                  ? selectStylesDisabled
                  : selectStylesEnabled
              }
              onChange={(selectedOption) => {
                if (selectedOption.value === "obrisi") {
                  setDeleteDjelatnik(true);
                  setSelectedDropdownOption("obrisi");
                }
              }}
              isSearchable={false}
              components={{
                DropdownIndicator: EllipsisDropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              isDisabled={
                selectedDropdownOption === "obrisi" ||
                isAddVoditelj ||
                showDeleteDjelatnikButtons ||
                addVoditeljiNewGroup ||
                isAddVoditeljToEvent
              }
            />
          </Box>
        )}
      </div>
      <div className={styles["djelatnici-grid"]}>
        {selectedDropdownOption === "obrisi" ? (
          <ObrisiDjelatnika
            deleteDjelatnik={deleteDjelatnik}
            fetchData={() => {
              if (authToken)
                fetchData(
                  authToken,
                  setVoditeljiData,
                  setTeacherData,
                  setUserData,
                  fetchedGroupTeachers,
                  setSelectedVoditelji
                );
            }}
            setVoditeljiData={setVoditeljiData}
            setTeacherData={setTeacherData}
            setUserData={setUserData}
            fetchedGroupTeachers={fetchedGroupTeachers}
            setSelectedVoditelji={setSelectedVoditelji}
            selectedDropdownOption={selectedDropdownOption}
            searchResults={searchResults}
            employeesData={voditeljiData}
            teacherData={teacherData}
            userData={userData}
            currentTabIndex={currentTabIndex}
            handleOdustaniClick={() =>
              handleOdustaniClick(
                setShowDeleteDjelatnikButtons,
                setIsDeleteDjelatnik,
                setSelectedDropdownOption
              )
            }
            setSelectedDropdownOption={setSelectedDropdownOption}
          />
        ) : searchResults ? (
          searchResults.map((data, index) => (
            <Link
              to={`/pojedini-djelatnik/${data?.id}`}
              key={index}
              className={styles.link}
            >
              <DjelatniciCard
                {...data}
                profilePhoto={data?.imageUrl ? data?.imageUrl : ProfilePhoto}
                showExpandIcon={true}
              />
            </Link>
          ))
        ) : currentTabIndex === "three" ? (
          voditeljiData.map((data, index) => {
            return (
              <React.Fragment key={index}>
                {isAddVoditelj ||
                addVoditeljiNewGroup ||
                isAddVoditeljToEvent ? (
                  <DjelatniciCard
                    key={index}
                    firstName={data?.firstName}
                    lastName={data?.lastName}
                    email={data?.emailAddress}
                    profilePhoto={
                      data?.imageUrl ? data?.imageUrl : ProfilePhoto
                    }
                    showExpandIcon={true}
                    onClick={() =>
                      handleCardSelect(
                        data,
                        setSelectedVoditelji,
                        selectedVoditelji
                      )
                    }
                    isSelected={selectedVoditelji.includes(data?.id)}
                  />
                ) : (
                  <Link
                    to={`/pojedini-djelatnik/${data?.id}`}
                    key={index}
                    className={styles.link}
                  >
                    <DjelatniciCard
                      key={index}
                      firstName={data?.firstName}
                      lastName={data?.lastName}
                      email={data?.emailAddress}
                      profilePhoto={
                        data?.imageUrl ? data?.imageUrl : ProfilePhoto
                      }
                      showExpandIcon={true}
                    />
                  </Link>
                )}
              </React.Fragment>
            );
          })
        ) : currentTabIndex === "one" ? (
          teacherData.map((data, index) => {
            return (
              <React.Fragment key={index}>
                {isAddVoditelj ||
                addVoditeljiNewGroup ||
                isAddVoditeljToEvent ? (
                  <DjelatniciCard
                    key={index}
                    firstName={data?.firstName}
                    lastName={data?.lastName}
                    email={data?.emailAddress}
                    profilePhoto={
                      data?.imageUrl ? data?.imageUrl : ProfilePhoto
                    }
                    showExpandIcon={true}
                    onClick={() =>
                      handleCardSelect(
                        data,
                        setSelectedVoditelji,
                        selectedVoditelji
                      )
                    }
                    isSelected={selectedVoditelji.includes(data?.id)}
                  />
                ) : (
                  <Link
                    to={`/pojedini-djelatnik/${data?.id}`}
                    key={index}
                    className={styles.link}
                  >
                    <DjelatniciCard
                      key={index}
                      firstName={data?.firstName}
                      lastName={data?.lastName}
                      email={data?.emailAddress}
                      profilePhoto={
                        data?.imageUrl ? data?.imageUrl : ProfilePhoto
                      }
                      showExpandIcon={true}
                    />
                  </Link>
                )}
              </React.Fragment>
            );
          })
        ) : currentTabIndex === "two" ? (
          userData.map((data, index) => {
            return (
              <React.Fragment key={index}>
                {isAddVoditelj ||
                addVoditeljiNewGroup ||
                isAddVoditeljToEvent ? (
                  <DjelatniciCard
                    key={index}
                    firstName={data?.firstName}
                    lastName={data?.lastName}
                    email={data?.emailAddress}
                    profilePhoto={
                      data?.imageUrl ? data?.imageUrl : ProfilePhoto
                    }
                    showExpandIcon={true}
                    onClick={() =>
                      handleCardSelect(
                        data,
                        setSelectedVoditelji,
                        selectedVoditelji
                      )
                    }
                    isSelected={selectedVoditelji.includes(data?.id)}
                  />
                ) : (
                  <Link
                    to={`/pojedini-djelatnik/${data?.id}`}
                    key={index}
                    className={styles.link}
                  >
                    <DjelatniciCard
                      key={index}
                      firstName={data?.firstName}
                      lastName={data?.lastName}
                      email={data?.emailAddress}
                      profilePhoto={
                        data?.imageUrl ? data?.imageUrl : ProfilePhoto
                      }
                      showExpandIcon={true}
                    />
                  </Link>
                )}
              </React.Fragment>
            );
          })
        ) : null}
      </div>
      {(isAddVoditelj || addVoditeljiNewGroup) && (
        <div className={styles.btnRow}>
          <button
            className={styles.declineBtn}
            onClick={() =>
              addVoditeljiNewGroup
                ? navigate(`/grupe/nova-grupa`)
                : navigate(`/grupe/${groupId}`)
            }
          >
            Odustani
          </button>
          <button
            className={styles.saveBtn}
            onClick={() => {
              if (addVoditeljiNewGroup) {
                navigate("/grupe/nova-grupa");
              } else {
                const uniqueSelectedVoditelji = Array.from(
                  new Set(selectedVoditelji)
                );
                setSelectedVoditelji(uniqueSelectedVoditelji);
                handleDodajVoditelje(
                  groupId,
                  uniqueSelectedVoditelji,
                  setShowModal,
                  setModalContent,
                  authToken,
                  navigate
                );
              }
            }}
            disabled={selectedVoditelji.length === 0}
          >
            Dodaj voditelja <img alt="" src={Plus} />
          </button>
        </div>
      )}
      {isAddVoditeljToEvent && (
        <div className={styles.btnRow}>
          <button className={styles.declineBtn} onClick={() => navigate(-1)}>
            Odustani
          </button>
          <button
            className={styles.saveBtn}
            onClick={() => {
              handleDodajVoditeljaToEvent();
            }}
            disabled={selectedVoditelji.length === 0}
          >
            Dodaj voditelja <img alt="" src={Plus} />
          </button>
        </div>
      )}
      {showModal && (
        <Modal
          image={modalContent.image}
          description={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
};

export default IPDTeam;
