import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import ReusableTabs from "../../components/TabMenu/Tabs";
import { useUserState } from "../../context/UserContext";
import OsvrtiOcjeneNaMojRad from "./components/OsvrtiOcjeneNaMojRad/OsvrtiOcjeneNaMojRad";
import PisanjeOsvrtaNaVoditelje from "./components/PisanjeOsvrtaNaVoditelje";
import PregledOstalihVoditelja from "./components/PregledOstalihVoditelja/PregledOstalihVoditelja";
import { ostaliVoditeljiData } from "./components/PregledOstalihVoditelja/Table/MockData";
import { tabsData } from "./utils";

function OsvrtiNaVoditelja() {
  const { selectedTab } = useParams();
  const { role } = useUserState();
  const userId = role;
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedTab || "one");
  const handleTabChange = (event, newValue) => {
    if (newValue != selectedTab) {
      setCurrentTabIndex(newValue);
    }
  };
  useEffect(() => {
    if (currentTabIndex != selectedTab) {
      navigate(`/osvrti/${currentTabIndex}`);
    }
  }, [currentTabIndex, navigate]);
  const [isPojediniVoditelj, setPojediniVoditelj] = useState(
    location?.state?.isPojediniVoditelj || false
  );
  const [voditeljID, setVoditeljID] = useState(location?.state?.voditeljID);

  useEffect(() => {
    setPojediniVoditelj(location?.state?.isPojediniVoditelj || false);
    setVoditeljID(location?.state?.voditeljID);
  }, [location.state]);
  const searchParams = new URLSearchParams(window.location.search);
  const urlIsPojediniVoditelj = searchParams.get("isPojediniVoditelj");
  return (
    <Layout
      isOsvrti
      showSearchBox={userId === "Referada" && !urlIsPojediniVoditelj}
    >
      <div className="subpage-wrapper">
        {userId === "Polaznik" && <PisanjeOsvrtaNaVoditelje />}
        {userId === "Voditelj" && (
          <>
            <ReusableTabs
              currentTabIndex={currentTabIndex}
              handleTabChange={handleTabChange}
              tabsData={tabsData}
            />
            {currentTabIndex === "one" && <PisanjeOsvrtaNaVoditelje />}
            {currentTabIndex === "two" && <OsvrtiOcjeneNaMojRad />}
            {currentTabIndex === "three" && (
              <>
                {urlIsPojediniVoditelj ? (
                  <OsvrtiOcjeneNaMojRad />
                ) : (
                  <PregledOstalihVoditelja data={ostaliVoditeljiData} />
                )}
              </>
            )}
          </>
        )}
        {userId === "Referada" && (
          <>
            {urlIsPojediniVoditelj ? (
              <OsvrtiOcjeneNaMojRad />
            ) : (
              <PregledOstalihVoditelja data={ostaliVoditeljiData} />
            )}
          </>
        )}
      </div>
    </Layout>
  );
}

export default OsvrtiNaVoditelja;
