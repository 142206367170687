import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal/Modal";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { FakultativneRadioniceValidationSchema } from "./Schema";
import styles from "./Styles.module.css";
import {
  GPRSati,
  handleInputChange,
  handleUnesiSateClick,
  postData,
} from "./utils";

function FakultativneRadionice({ userEvidentionID }) {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();

  const fullName = localStorage.getItem("evidentionFullName");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    fullName: "",
    nazivRadionice: "",
    date: new Date().toISOString().split("T")[0],
    gprSati: "",
  });
  const otherInfo = JSON.stringify(postData("facultative_workshop", formData));
  const [errors, setErrors] = useState({});
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        Ručni unos sati za fakultativne radionice
      </span>
      <div className={styles.inputWrapper}>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Ime i prezime voditelja</span>
          <input
            className={styles.rucniUnosInput}
            type="text"
            name="fullName"
            placeholder="Upiši"
            value={formData.fullName}
            onChange={(e) =>
              handleInputChange(
                "fullName",
                e.target.value,
                setFormData,
                formData
              )
            }
          />
          {errors.fullName && (
            <div className={styles.error}>{errors.fullName}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Naziv fakultativne radionice</span>
          <input
            className={styles.rucniUnosInput}
            type="text"
            name="nazivRadionice"
            placeholder="Upiši"
            value={formData.nazivRadionice}
            onChange={(e) =>
              handleInputChange(
                "nazivRadionice",
                e.target.value,
                setFormData,
                formData
              )
            }
          />
          {errors.nazivRadionice && (
            <div className={styles.error}>{errors.nazivRadionice}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Datum fakultativne radionice</span>
          <input
            className={styles.rucniUnosInput}
            type="date"
            name="date"
            value={formData.date}
            onChange={(e) =>
              handleInputChange("date", e.target.value, setFormData, formData)
            }
          />
          {errors.date && <div className={styles.error}>{errors.date}</div>}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Broj sati GPR-a</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="gprSati"
            value={formData.gprSati}
            onChange={(e) =>
              handleInputChange(
                "gprSati",
                e.target.value,
                setFormData,
                formData
              )
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {GPRSati.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.gprSati && (
            <div className={styles.error}>{errors.gprSati}</div>
          )}
        </div>
      </div>
      <div className={styles.btnRow}>
        <button
          onClick={() => {
            localStorage.setItem("evidentionFullName", fullName);
            localStorage.setItem("evidentionUserID", userEvidentionID);
            navigate("/evidencija");
          }}
          className={styles.cancelBtn}
        >
          Odustani
        </button>
        <button
          className={styles.successBtn}
          onClick={() =>
            handleUnesiSateClick(
              setErrors,
              formData,
              FakultativneRadioniceValidationSchema,
              userEvidentionID,
              "facultative_workshop",
              otherInfo,
              authToken,
              setModalContent,
              navigate,
              setShowModal
            )
          }
        >
          Unesi sate
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default FakultativneRadionice;
