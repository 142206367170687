import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal/Modal";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { IntenziviRetroaktivnoValidationSchema } from "./Schema";
import styles from "./Styles.module.css";
import {
  LjetniIntenzivi,
  VikendIntenzivi,
  handleInputChange,
  handleUnesiSateClick,
  intenzivi,
  postData,
  satiGPR,
} from "./utils";

function IntenziviRetroaktivno({ userEvidentionID }) {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const fullName = localStorage.getItem("evidentionFullName");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    vrstaIntenziva: "",
    name: "",
    year: new Date().getFullYear(),
    gprSati: "",
  });
  const [errors, setErrors] = useState({});
  const otherInfo = JSON.stringify(postData("intensive", formData));

  useEffect(() => {
    if (formData.vrstaIntenziva === "ljetni") {
      setFormData({ ...formData, gprSati: 25 });
    } else if (formData.vrstaIntenziva === "vikend") {
      setFormData({ ...formData, gprSati: 13 });
    }
  }, [formData.vrstaIntenziva]);
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        Ručni unos sati za intenzive retroaktivno
      </span>
      <div className={styles.inputWrapper}>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Vrsta intenziva</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="vrstaIntenziva"
            value={formData.vrstaIntenziva}
            onChange={(e) =>
              handleInputChange(
                "vrstaIntenziva",
                e.target.value,
                setFormData,
                formData
              )
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {intenzivi.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.vrstaIntenziva && (
            <div className={styles.error}>{errors.vrstaIntenziva}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Naziv intenziva</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name={"name"}
            value={formData.name}
            onChange={(e) =>
              handleInputChange("name", e.target.value, setFormData, formData)
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {(formData.vrstaIntenziva === "ljetni"
              ? LjetniIntenzivi
              : VikendIntenzivi
            ).map((item, index) => (
              <option key={index} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.name && <div className={styles.error}>{errors.name}</div>}
        </div>

        <div className={styles.inputColumn}>
          <span className={styles.label}>Godina održavanja</span>
          <input
            className={styles.rucniUnosInput}
            type="number"
            name="year"
            min="1900"
            max="2100"
            value={formData.year}
            onChange={(e) =>
              handleInputChange("year", e.target.value, setFormData, formData)
            }
          />
          {errors.year && <div className={styles.error}>{errors.year}</div>}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Broj sati GPR-a</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="gprSati"
            value={formData.gprSati}
            onChange={(e) =>
              handleInputChange(
                "gprSati",
                e.target.value,
                setFormData,
                formData
              )
            }
            disabled
          >
            {satiGPR.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.gprSati && (
            <div className={styles.error}>{errors.gprSati}</div>
          )}
        </div>
      </div>
      <div className={styles.btnRow}>
        <button
          onClick={() => {
            localStorage.setItem("evidentionFullName", fullName);
            localStorage.setItem("evidentionUserID", userEvidentionID);
            navigate("/evidencija");
          }}
          className={styles.cancelBtn}
        >
          Odustani
        </button>
        <button
          className={styles.successBtn}
          onClick={() =>
            handleUnesiSateClick(
              setErrors,
              formData,
              IntenziviRetroaktivnoValidationSchema,
              userEvidentionID,
              "intensive",
              otherInfo,
              authToken,
              setModalContent,
              navigate,
              setShowModal
            )
          }
        >
          Unesi sate
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default IntenziviRetroaktivno;
