import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getSearchPolaznici = async (
  page,
  limit,
  accessToken,
  search,
  allRoles
  // isNoGroupsClicked
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/usersByRole/${
        allRoles ? "99" : "2"
      }?page=${page}&limit=${limit}&search=${search}`, //&noGroups=${isNoGroupsClicked}
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
