import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import { useUserState } from "../../context/UserContext";
import { getGroupsILead } from "../groups/api/getGroupsILead";
import { useAuthentication } from "../hooks/useAuthentication";
import { getGroupEvidention } from "./api/getGroupEvidention";
import EvidencijaDolazakaCard from "./components/EvidencijaDolazakaCard";
import styles from "./EvidencijaDolazaka.module.css";

function EvidencijaDolazaka() {
  const { role } = useUserState();
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [groupEvidention, setGroupEvidention] = useState([]);
  const [groups, setGroups] = useState(false);
  const [timePassed, setTimePassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimePassed(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (role !== "Voditelj" && role !== "Supervizor") {
      navigate("/dashboard");
    }
  }, [role, authToken]);

  useEffect(() => {
    const fetchGroups = async () => {
      const response = await getGroupsILead(authToken);
      const groupIds = response.map((group) => group.id);
      if (groupIds.length > 0) {
        const evidention = await getGroupEvidention(groupIds, authToken);
        setGroupEvidention(evidention);
        setGroups(true);
      }
    };
    if (authToken) fetchGroups();
  }, [authToken]);

  return (
    <div className={styles.wrapper}>
      {timePassed && (
        <>
          {groups ? (
            Object.keys(groupEvidention).map((groupName, index) => (
              <EvidencijaDolazakaCard
                key={index}
                group={groupName}
                data={groupEvidention[groupName]}
              />
            ))
          ) : (
            <div className={styles.info}>
              <img src={InfoIcon} alt="" />
              <p className={styles.infoP}>Trenutno nemate grupa koje vodite.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default EvidencijaDolazaka;
