import React, { useEffect, useState } from "react";
import OsobniPsihoterapijskiRadAccordions from "./Accordions/OsobniPsihoterapijskiRadAccordions";
import styles from "./OsobniPsihoterapeutskiRad.module.css";

function OsobniPsihoterapeutskiRad({
  userId,
  polaznikLevel,
  data,
  setDeleteFlag,
  deleteFlag,
}) {
  const [osobniPsihoterapeutskiRadArray, setOsobniPsihoterapeutskiRadArray] =
    useState([]);
  const [obracunInfo, setObracunInfo] = useState([]);
  useEffect(() => {
    if (data) {
      const array = Object.values(data);
      setOsobniPsihoterapeutskiRadArray([
        { individualTherapy: array[0] },
        { facultativeProgram: array[1] },
      ]);
    }
  }, [data]);
  useEffect(() => {
    if (osobniPsihoterapeutskiRadArray.length > 0) {
      const individualTherapy =
        osobniPsihoterapeutskiRadArray[0]?.individualTherapy || {};
      const fakultativniProgram =
        osobniPsihoterapeutskiRadArray[1]?.facultativeProgram || {};
      const individualTherapyTopicsArray = [
        {
          name: "Individualne programske terapije (IPD Kalendar)",
          hours: individualTherapy.automatic || 0,
          isNameClickable: true,
          eventType: "therapy_automatic",
        },
        {
          name: "Moje upisane individualne programske terapije",
          hours: individualTherapy.manual || 0,
          isNameClickable: true,
          eventType: "therapy_manual",
        },
      ];
      const facultativeProgramTopicsArray = [
        {
          name: "Fakultativne radionice",
          hours: fakultativniProgram.facultativeWorkshop || 0,
          isNameClickable: true,
          eventType: "facultative_workshop",
        },
        {
          name: "Intenzivi",
          hours: fakultativniProgram.intensive || 0,
          isNameClickable: true,
          eventType: "intensive",
        },
      ];
      setObracunInfo([
        {
          title: "Individualne programske terapije - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: individualTherapyTopicsArray,
              maxTopObracun:
                osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy
                  .minAttendance,
            },
          ],
        },
        {
          title: "Fakultativni program - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: facultativeProgramTopicsArray,
              maxBottomObracun:
                osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram
                  ?.minAttendance,
            },
          ],
        },
      ]);
    }
  }, [osobniPsihoterapeutskiRadArray]);
  const minOsobniPsihoRadSati =
    osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram?.minAttendance +
    osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy.minAttendance;

  const currentIndividualneSati =
    osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy.automatic +
    osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy.manual;

  const remainingIndividualneSati =
    osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy.minAttendance -
    (osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy.automatic +
      osobniPsihoterapeutskiRadArray?.[0]?.individualTherapy.manual);

  const currentFakultativniSati =
    osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram
      ?.facultativeWorkshop +
    osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram?.intensive;

  const remainingFakultativniSati =
    osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram?.minAttendance -
    (osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram
      ?.facultativeWorkshop +
      osobniPsihoterapeutskiRadArray?.[1]?.facultativeProgram?.intensive);
  return (
    <div className={styles.raniRazvojPrvaSekcija2}>
      <h1 className={styles.raniRazvojHeader}>Osobni psihoterapeutski rad</h1>
      {polaznikLevel !== "polaznikRAR" && (
        <p className={styles.raniRazvojDescription}>
          Za postizanje statusa{" "}
          {polaznikLevel === "polaznikOR1" ? "praktičara" : "terapeuta"} ITP-a
          potrebno je ostvariti minimalno {minOsobniPsihoRadSati} sati osobnog
          psihoterapijskog rada.
        </p>
      )}
      <p className={styles.raniRazvojDescription2}>
        Tvoje trenutno stanje sa satima: <br /> Individualne terapije -{" "}
        {currentIndividualneSati}h{" "}
        {polaznikLevel !== "polaznikRAR" &&
          `(Nedostaje ${
            remainingIndividualneSati <= 0 ? 0 : remainingIndividualneSati
          }h)`}
        <br />
        Fakultativni program - {currentFakultativniSati}h{" "}
        {polaznikLevel !== "polaznikRAR" &&
          `(Nedostaje ${
            remainingFakultativniSati <= 0 ? 0 : remainingFakultativniSati
          }h)`}
      </p>
      <div>
        <OsobniPsihoterapijskiRadAccordions
          data={obracunInfo}
          userId={userId}
          setDeleteFlag={setDeleteFlag}
          deleteFlag={deleteFlag}
        />
      </div>
    </div>
  );
}

export default OsobniPsihoterapeutskiRad;
