// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OsobniPsihoterapeutskiRad_raniRazvojPrvaSekcija2__Gpy2B {\n  margin-top: 64px;\n}\n.OsobniPsihoterapeutskiRad_raniRazvojHeader__Npj5a {\n  color: #4d4d4d;\n  font-family: Inter;\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: -0.028px;\n}\n.OsobniPsihoterapeutskiRad_raniRazvojDescription__E93hx,\n.OsobniPsihoterapeutskiRad_raniRazvojDescription2__fVgJF {\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: -0.014px;\n}\n.OsobniPsihoterapeutskiRad_osobniRadAccordion__53vzg {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/scenes/evidencija/components/OsobniPsihoterapeutskiRad/OsobniPsihoterapeutskiRad.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;AAC1B;AACA;;EAEE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB","sourcesContent":[".raniRazvojPrvaSekcija2 {\n  margin-top: 64px;\n}\n.raniRazvojHeader {\n  color: #4d4d4d;\n  font-family: Inter;\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: -0.028px;\n}\n.raniRazvojDescription,\n.raniRazvojDescription2 {\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: -0.014px;\n}\n.osobniRadAccordion {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"raniRazvojPrvaSekcija2": "OsobniPsihoterapeutskiRad_raniRazvojPrvaSekcija2__Gpy2B",
	"raniRazvojHeader": "OsobniPsihoterapeutskiRad_raniRazvojHeader__Npj5a",
	"raniRazvojDescription": "OsobniPsihoterapeutskiRad_raniRazvojDescription__E93hx",
	"raniRazvojDescription2": "OsobniPsihoterapeutskiRad_raniRazvojDescription2__fVgJF",
	"osobniRadAccordion": "OsobniPsihoterapeutskiRad_osobniRadAccordion__53vzg"
};
export default ___CSS_LOADER_EXPORT___;
