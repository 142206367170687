import React from "react";
import CardAccordion from "../../CardAccordionMain/CardAccordion";
import styles from "../Supervizije.module.css";

function EvaluacijeAccordions({ userId, data }) {
  const columnNames = [
    "Datum",
    "Tip supervizije",
    "Supervizor",
    "Komentar supervizora",
  ];
  return (
    <div className={styles.supervizijeAccordion}>
      {data.map((item, index) => {
        return (
          <>
            <CardAccordion
              key={index}
              title={item.title}
              userId={userId}
              showItems={false}
              columnNames={columnNames}
              showModulName={false}
              tableData={item.modules?.[0].topics}
            />
          </>
        );
      })}
    </div>
  );
}

export default EvaluacijeAccordions;
