import React, { useEffect, useState } from "react";
import OsobniRadKlijentiGrupeAccordion from "./Accordions/OsobniRadKlijentiGrupeAccordion";
import styles from "./OsobniRadKlijentiGrupe.module.css";

function OsobniRadKlijentiGrupe({
  userId,
  polaznikLevel,
  data,
  setDeleteFlag,
  deleteFlag,
}) {
  const [osobniRadKlijentiGrupeArray, setOsobniRadKlijentiGrupeArray] =
    useState([]);
  const [obracunInfo, setObracunInfo] = useState([]);
  useEffect(() => {
    if (data) {
      const array = Object.values(data);
      setOsobniRadKlijentiGrupeArray([
        { individualWork: array[0] },
        { groupWork: array[1] },
      ]);
    }
  }, [data]);
  useEffect(() => {
    if (osobniRadKlijentiGrupeArray.length > 0) {
      const individualWork =
        osobniRadKlijentiGrupeArray[0]?.individualWork || {};
      const groupWork = osobniRadKlijentiGrupeArray[1]?.groupWork || {};
      const individualWorkTopicsArray = [
        {
          name: "Moj rad s klijentima (IPD Kalendar)",
          hours: individualWork.automatic || 0,
          isNameClickable: true,
          eventType: "automatic_work_with_client",
        },
        {
          name: "Moj naknadno upisani rad s klijentima",
          hours: individualWork.manual || 0,
          isNameClickable: true,
          eventType: "manual_work_with_client",
        },
      ];
      const groupWorkTopicsArray = [
        {
          name: "Rad s grupom (IPD Kalendar)",
          hours: groupWork.automatic || 0,
          isNameClickable: true,
          eventType: "therapist_therapy_automatic",
        },
        {
          name: "Moj upisani rad s grupom",
          hours: groupWork.manual || 0,
          isNameClickable: true,
          eventType: "therapist_therapy_manual",
        },
      ];
      setObracunInfo([
        {
          title: "Moj rad s klijentima - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: individualWorkTopicsArray,
              maxTopObracun:
                osobniRadKlijentiGrupeArray?.[0]?.individualWork.minAttendance,
            },
          ],
        },
        {
          title: "Osobni rad s grupom - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: groupWorkTopicsArray,
              maxBottomObracun: 0,
            },
          ],
        },
      ]);
    }
  }, [osobniRadKlijentiGrupeArray]);
  const minOsobniRadKlijentiGrupeSati =
    osobniRadKlijentiGrupeArray[0]?.individualWork.minAttendance;

  const currentIndividualniSati =
    osobniRadKlijentiGrupeArray[0]?.individualWork.manual +
    osobniRadKlijentiGrupeArray[0]?.individualWork.automatic;
  const currentGrupneSati =
    osobniRadKlijentiGrupeArray[1]?.groupWork.manual +
    osobniRadKlijentiGrupeArray[1]?.groupWork.automatic;
  return (
    <div className={styles.raniRazvojPrvaSekcija2}>
      <div>
        <h1 className={styles.raniRazvojHeader}>
          Osobni rad s klijentima i grupama
        </h1>
        <p className={styles.raniRazvojDescription}>
          Za postizanje statusa{" "}
          {polaznikLevel === "polaznikOR1" ? "praktičara" : "terapeuta"} ITP-a
          potrebno je ostvariti minimalno {minOsobniRadKlijentiGrupeSati} sati
          osobnog rada s klijentima.
        </p>
        <p className={styles.raniRazvojDescription2}>
          Tvoje trenutno stanje sa satima: <br /> Moj rad s klijentima -{" "}
          {currentIndividualniSati}h (Nedostaje{" "}
          {minOsobniRadKlijentiGrupeSati - currentIndividualniSati <= 0
            ? 0
            : minOsobniRadKlijentiGrupeSati - currentIndividualniSati}
          h)
          <br />
          Osobni rad s grupom - {currentGrupneSati}h
        </p>
      </div>
      <div>
        <OsobniRadKlijentiGrupeAccordion
          data={obracunInfo}
          userId={userId}
          setDeleteFlag={setDeleteFlag}
          deleteFlag={deleteFlag}
        />
      </div>
    </div>
  );
}

export default OsobniRadKlijentiGrupe;
