import BlankPhoto from "../../../../assets/img/blank_profile_photo.jpeg";
import LetterIcon from "../../../../assets/svg/LetterIcon";
import PaperClipIcon from "../../../../assets/svg/PaperClipIcon";
import { useUserState } from "../../../../context/UserContext";
import { truncateText } from "../../utils/Utils";
import styles from "./TeacherCard.module.css";

const TeacherCard = ({
  index,
  firstName,
  lastName,
  biography,
  email,
  photo,
  roles,
  educates,
  isMentor,
}) => {
  const { userData } = useUserState();
  return (
    <div className={styles.container} key={index}>
      <div
        className={isMentor ? styles.mentorContainer : styles.innerContainer}
      >
        <div className={styles.photoWrapper}>
          {isMentor && <div className={styles.pill}>Mentor</div>}
          <img
            alt="profile_photo"
            src={photo ? photo : BlankPhoto}
            className={styles.photo}
          />
        </div>
        <h3 className={styles.title}>
          {firstName ? firstName : "Ime"}
          <br />
          {lastName ? lastName : "Prezime"}
        </h3>
        <div className={styles.infoContainer}>
          <div className={styles.infoItemRow}>
            <PaperClipIcon className={styles.infoItemIcon} />
            <p className={styles.infoItemText}>
              {biography ? biography : "Nema biografije!"}
            </p>
          </div>
          <a href={email && `mailto:${email}`} className={styles.infoItemRow}>
            <LetterIcon className={styles.infoItemIcon} />
            <p className={styles.infoItemText}>
              {email ? truncateText(email, 16) : "Nema email-a!"}
            </p>
          </a>
        </div>
      </div>
      {roles && userData?.roleId !== 2 && (
        <>
          <div className={styles.horizontalLine} />
          <p className={styles.bottomText}>
            {roles ? roles : "Nepoznata uloga!"} {educates ? educates : ""}
          </p>
        </>
      )}
    </div>
  );
};
export default TeacherCard;
