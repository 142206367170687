export const polaznikRAROptions = [
  {
    label: "Individualne programske terapije",
    value: "individualneTerapije",
  },
  {
    label: "Fakultativne radionice",
    value: "fakultativne",
  },
  {
    label: "Intenzivi retroaktivno",
    value: "intenzivi",
  },
];
export const polaznikORTTOptions = [
  {
    label: "Grupne supervizije",
    value: "grupneSupervizije",
  },
  {
    label: "Individualne supervizije",
    value: "individualneSupervizije",
  },
  {
    label: "Moj rad s klijentima - aktualno",
    value: "klijentiAktualno",
  },
  {
    label: "Moj rad s klijentima - retroaktivno",
    value: "klijentiRetroaktivno",
  },
  {
    label: "Osobni rad s grupom",
    value: "osobniRad",
  },
];
export const handleNavigateToRucniUnos = async (
  navigate,
  program,
  userEvidentionID
) => {
  navigate("/evidencija-rucni-unos", {
    state: { program: program, userEvidentionID: userEvidentionID },
  });
};
