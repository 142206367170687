import { archiveGroup } from "../api/archiveGroup";
import { deleteGroup } from "../api/deleteGroup";

const monthsCroatian = [
  "siječnja",
  "veljače",
  "ožujka",
  "travnja",
  "svibnja",
  "lipnja",
  "srpnja",
  "kolovoza",
  "rujna",
  "listopada",
  "studenoga",
  "prosinca",
];

export function formatDate(apiDate) {
  const originalDate = new Date(apiDate);
  const day = originalDate.getDate();
  const monthIndex = originalDate.getMonth();
  const year = originalDate.getFullYear();

  const formattedDate = `${day < 10 ? "0" : ""}${day}. ${
    monthsCroatian[monthIndex]
  } ${year}.`;

  return formattedDate;
}
export const truncateText = (text, charactersLimit) => {
  const truncatedText = [text.slice(0, charactersLimit), "..."];
  return truncatedText;
};

export const handleInputChange = (name, value, setFormValues) => {
  setFormValues((prevValues) => ({
    ...prevValues,
    [name]: value,
  }));
};
export const handleNext = (setActiveIndex, teachersData, itemsPerPage) => {
  setActiveIndex((prevIndex) =>
    prevIndex >= teachersData.length - itemsPerPage ? 0 : prevIndex + 1
  );
};
export const handleButtonDropdown = (setButtonDropdown, buttonDropdown) => {
  setButtonDropdown(!buttonDropdown);
};
export const handleEditGroup = (setEditMode, setButtonDropdown) => {
  setEditMode(true);
  setButtonDropdown(false);
};
export const handleRemoveTeacherItem = (
  userId,
  setSelectedItemIndex,
  setTeacherRemoveModal,
  teacherRemoveModal
) => {
  setSelectedItemIndex(userId);
  setTeacherRemoveModal(!teacherRemoveModal);
};
export const handleRemoveAttendeeItem = (
  index,
  setSelectedItemIndex,
  setAttendeesRemoveModal,
  attendeesRemoveModal
) => {
  setSelectedItemIndex(index);
  setAttendeesRemoveModal(!attendeesRemoveModal);
};

export const handleGroupDelete = (groupId, accessToken, navigate) => {
  deleteGroup(groupId, accessToken).then(() => {
    navigate("/grupe");
  });
};
export const handleGroupArchive = (groupId, accessToken, navigate) => {
  archiveGroup(groupId, accessToken).then(() => {
    navigate("/grupe");
  });
};
export const handleAddVoditeljClick = (navigate, groupId, teachersData) => {
  navigate("/djelatnici", {
    state: {
      isAddVoditelj: true,
      groupId: groupId,
      fetchedGroupTeachers: teachersData,
    },
  });
};
export const handleUredi = (
  navigate,
  groupData,
  attendeesData,
  teachersData
) => {
  console.log("Učitelji:", teachersData);

  navigate("/voditelj/uredi-uloge", {
    state: {
      groupData,
      attendeesData,
      teachersData,
    },
  });
};
export const handleIntrospekcijeClick = (navigate, groupId, name, date) => {
  navigate(`/introspekcije/${groupId}`, {
    state: {
      groupName: name,
      groupId: groupId,
      date: date,
    },
  });
};
