import { useEffect, useState } from "react";
import Exit from "../../../assets/img/ExitIcon.png";
import TrashIcon from "../../../assets/svg/TrashIcon";
import { useUserState } from "../../../context/UserContext";
import { useAuthentication } from "../../hooks/useAuthentication";
import { createEvent } from "../api/createEvent";
import { deleteEvent } from "../api/deleteEvent";
import { getRoomAvalability } from "../api/getRoomAvailability";
import { getTherapyRoomAvailability } from "../api/getTherapyRoomAvailability";
import { updateEvent } from "../api/updateEvent";
import {
  therapyTimeSlots,
  timeSlots,
} from "../calendaripd-utils/calendarIpd.mocks";
import {
  handleInputChange,
  handleKlijentChange,
  handleKlijentClick,
  handleStartTimeChange,
} from "../utils";
import styles from "./CreateModal.module.css";
import { eventOptions } from "./modalUtils";

const CreateModal = ({
  onClick,
  date,
  rooms,
  type,
  users,
  startDate,
  endDate,
  selectedRooms,
  setEvents,
  reRender,
  setReRender,
  setIsCreateOpen,
  editEventValues,
  secondSelectedRoomId,
}) => {
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const [selectedTime, setSelectedTime] = useState(null);
  const [time, setTime] = useState(timeSlots);
  const [therapyTime, setTherapyTime] = useState(therapyTimeSlots);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [timeChange, setTimeChange] = useState(false);
  const [therapyTimeChange, setTherapyTimeChange] = useState(false);
  const [formValues, setFormValues] = useState({
    eventType: eventOptions.length > 0 ? eventOptions[0].value : "",
    eventId: "",
    supervisionType: "individual",
    clientId: "",
    outsideClient: "",
    name: "",
    date: date,
    timeFrom: "",
    timeTo: "",
    description: "",
    roomId: secondSelectedRoomId
      ? secondSelectedRoomId
      : rooms.length > 0
      ? rooms[0].value
      : "",
  });
  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      timeFrom:
        prevValues.eventType === "therapy"
          ? therapyTime.length > 0
            ? therapyTime[0].value.split(" - ")[0]
            : ""
          : time.length > 0
          ? time[0].value.split(" - ")[0]
          : "",
      timeTo:
        prevValues.eventType === "therapy"
          ? therapyTime.length > 0
            ? therapyTime[0].value.split(" - ")[1]
            : ""
          : time.length > 0
          ? time[0].value.split(" - ")[1]
          : "",
    }));
  }, [therapyTime, time]);
  const filteredUsers = users.filter(
    (user) =>
      user.firstName.toLowerCase().includes(searchValue?.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchValue?.toLowerCase())
  );
  useEffect(() => {
    if (type === "edit") {
      const { timeFrom, timeTo, roomId, clientId } = editEventValues;
      const selectedTimeValue = `${timeFrom} - ${timeTo}`;
      setSelectedTime(selectedTimeValue);
      const selectedClient = users.find((user) => user.id === clientId);
      if (selectedClient) {
        setSearchValue(
          `${selectedClient.firstName} ${selectedClient.lastName}`
        );
      }
      setFormValues({
        eventType: editEventValues.eventType,
        eventId: editEventValues.eventId,
        supervisionType: editEventValues.supervisionType,
        clientId: editEventValues.clientId,
        outsideClient: editEventValues.outsideClient,
        name: editEventValues.name,
        date: editEventValues.date,
        timeFrom: editEventValues.timeFrom,
        timeTo: editEventValues.timeTo,
        description: editEventValues.description,
        roomId: roomId,
      });
    }
  }, [type, editEventValues]);

  useEffect(() => {
    const fetchTime = async () => {
      const response = await getRoomAvalability(
        authToken,
        type === "edit" ? editEventValues.date : date?.split("T")[0],
        formValues.roomId
      );
      setTime(response);
    };
    const fetchTherapyTime = async () => {
      const response = await getTherapyRoomAvailability(
        authToken,
        type === "edit" ? editEventValues.date : date?.split("T")[0],
        formValues.roomId
      );
      setTherapyTime(response);
    };
    if (formValues?.eventType === "therapy") {
      fetchTherapyTime();
    } else {
      fetchTime();
    }
  }, [formValues.roomId, date, editEventValues, type, formValues.eventType]);

  return (
    <div className={styles.wrapper}>
      <img src={Exit} alt="" className={styles.exit} onClick={onClick} />
      <div className={styles.modalContainer}>
        <h1 className={styles.h1}>
          {type === "create"
            ? "Kreiranje događaja"
            : formValues.eventType === "therapy"
            ? (formValues.eventType === "therapy" && "Terapija") +
              `: ${userData.firstName} ${userData.lastName}`
            : ""}
        </h1>
        <div className={styles.formWrapper}>
          <div className={styles.form}>
            <div className={styles.item}>
              <label className={styles.label} htmlFor="vrsta">
                Vrsta događaja
              </label>
              <select
                className={styles.select}
                value={formValues.eventType}
                disabled={type === "edit"}
                name="eventType"
                onChange={(event) =>
                  handleInputChange(
                    "eventType",
                    event.target.value,
                    setFormValues
                  )
                }
              >
                {eventOptions.map((event, index) => {
                  if (role === "Referada") {
                    return (
                      <option key={index} value={event.value}>
                        {event.label}
                      </option>
                    );
                  } else if (role === "Zakupitelj" || role === "Voditelj") {
                    if (index === 0) {
                      return (
                        <option key={index} value={event.value}>
                          {event.label}
                        </option>
                      );
                    } else {
                      return null;
                    }
                  } else if (role === "Učitelj" && index < 3) {
                    return (
                      <option key={index} value={event.value}>
                        {event.label}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
              </select>
            </div>
            {(formValues.eventType === "therapy" ||
              formValues.eventType === "individual_supervision") && (
              <>
                <div className={styles.item}>
                  <label className={styles.label} htmlFor="klijent">
                    Klijent
                  </label>
                  <div className={styles.search}>
                    <div className={styles.searchInput}>
                      <input
                        type="search"
                        className={styles.input}
                        placeholder="Upiši"
                        value={searchValue}
                        onChange={(event) =>
                          handleKlijentChange(
                            event,
                            setSearchValue,
                            setIsSearchVisible
                          )
                        }
                        disabled={formValues.outsideClient !== ""}
                      />
                    </div>
                    <div
                      className={
                        searchValue === "" || !isSearchVisible
                          ? styles.none
                          : styles.searchDropdown
                      }
                    >
                      {filteredUsers?.slice(0, 5).map((user, index) => (
                        <div
                          key={index}
                          className={styles.dropdownItems}
                          onClick={() =>
                            handleKlijentClick(
                              user.id,
                              user.firstName,
                              user.lastName,
                              setSearchValue,
                              setFormValues,
                              setIsSearchVisible
                            )
                          }
                        >
                          {user.firstName} {user.lastName}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.item}>
                  <label className={styles.label} htmlFor="klijentvan">
                    Klijent van IPD-a
                  </label>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Upiši"
                    value={formValues.outsideClient}
                    disabled={searchValue !== ""}
                    name="outsideClient"
                    onChange={(event) =>
                      handleInputChange(
                        "outsideClient",
                        event.target.value,
                        setFormValues
                      )
                    }
                  />
                </div>
              </>
            )}
            {formValues.eventType === "other" && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="Ostalo">
                  Ostalo
                </label>
                <input
                  type="text"
                  className={styles.input}
                  placeholder="Upiši"
                  value={formValues.name}
                  name="name"
                  onChange={(event) =>
                    handleInputChange("name", event.target.value, setFormValues)
                  }
                />
              </div>
            )}
            <div className={styles.item}>
              <label className={styles.label} htmlFor="datum">
                Datum održavanja
              </label>
              <input
                type="date"
                className={styles.input}
                value={formValues.date}
                name="date"
                onChange={(event) =>
                  handleInputChange("date", event.target.value, setFormValues)
                }
              />
            </div>
            <div className={styles.item}>
              <label className={styles.label} htmlFor="mjesto">
                Mjesto održavanja
              </label>
              <select
                className={styles.select}
                name="roomId"
                value={secondSelectedRoomId || formValues.roomId}
                onChange={(event) =>
                  handleInputChange("roomId", event.target.value, setFormValues)
                }
              >
                {rooms.map((room, index) => (
                  <option value={room.value} key={index}>
                    {room.label}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.item}>
              <label className={styles.label} htmlFor="vrijeme">
                Vrijeme održavanja
              </label>
              <div className={styles.timeSelects}>
                <select
                  className={styles.selectSmall}
                  value={selectedTime ? selectedTime : ""}
                  name="timeFrom"
                  onChange={(event) =>
                    handleStartTimeChange(
                      event,
                      setSelectedTime,
                      formValues.eventType === "therapy"
                        ? setTherapyTimeChange
                        : setTimeChange,
                      setFormValues
                    )
                  }
                >
                  {formValues.eventType === "therapy"
                    ? therapyTime &&
                      therapyTime.map((time, index) => (
                        <option
                          disabled={!time.avaliability}
                          key={index}
                          value={time.value}
                        >
                          {time.start}
                        </option>
                      ))
                    : time &&
                      time.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.start}
                        </option>
                      ))}
                </select>
                <span className={styles.divider}>-</span>
                <select
                  className={styles.selectSmall}
                  value={selectedTime ? selectedTime : ""}
                  onChange={() => {}}
                  disabled={true}
                >
                  {formValues.eventType === "therapy"
                    ? therapyTime &&
                      therapyTime.map((time, index) => (
                        <option
                          disabled={!time.avaliability}
                          key={index}
                          value={time.value}
                        >
                          {time.end}
                        </option>
                      ))
                    : time &&
                      time.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.end}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            {(formValues.eventType === "therapy" ||
              formValues.eventType === "individual_supervision") && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="napomena">
                  Napomena
                </label>
                <textarea
                  className={styles.textarea}
                  placeholder="Dodaj napomenu"
                  value={formValues.description}
                  name="description"
                  onChange={(event) =>
                    handleInputChange(
                      "description",
                      event.target.value,
                      setFormValues
                    )
                  }
                />
              </div>
            )}
            {formValues.eventType === "other" && (
              <div className={styles.item}>
                <label className={styles.label} htmlFor="opis">
                  Opis
                </label>
                <textarea
                  className={styles.opis}
                  value={formValues.description}
                  name="description"
                  onChange={(event) =>
                    handleInputChange(
                      "description",
                      event.target.value,
                      setFormValues
                    )
                  }
                  placeholder="Dodaj opis"
                />
              </div>
            )}
          </div>
          <div className={styles.btnsContainer}>
            {type === "edit" && (
              <div
                className={styles.delete}
                onClick={() =>
                  deleteEvent(
                    formValues,
                    authToken,
                    startDate,
                    endDate,
                    selectedRooms,
                    setEvents,
                    setIsCreateOpen,
                    reRender,
                    setReRender
                  )
                }
              >
                <TrashIcon alt="" />
              </div>
            )}
            <div className={styles.createBtns}>
              <button className={styles.btnCancel} onClick={onClick}>
                Odustani
              </button>
              <button
                className={styles.btnCreate}
                onClick={() => {
                  type === "edit"
                    ? updateEvent(
                        formValues.eventType,
                        formValues,
                        authToken,
                        startDate,
                        endDate,
                        selectedRooms,
                        setEvents,
                        setIsCreateOpen,
                        reRender,
                        setReRender
                      )
                    : createEvent(
                        formValues.eventType,
                        formValues,
                        authToken,
                        startDate,
                        endDate,
                        selectedRooms,
                        setEvents,
                        setIsCreateOpen,
                        reRender,
                        setReRender
                      );
                }}
              >
                {type === "edit" ? "Spremi promjene" : "Kreiraj događaj"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
