import { useNavigate } from "react-router-dom";
import ArchiveUser from "../../../../assets/svg/ArchiveUser";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import CrossIcon from "../../../../assets/svg/CrossIcon";
import UserIcon from "../../../../assets/svg/UserIcon";
import styles from "./AttendeesTable.module.css";

const AttendeesTable = ({
  data,
  groupData,
  handleRemoveItem,
  editMode,
  userId,
  isCreateNewGroup,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.tableContainer}>
      <div className={styles.scrollContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeaderRow}>
              <th className={styles.headerCell}>
                <p className={styles.headerCellLabel}>Ime i prezime</p>
              </th>
              <th className={styles.headerCell}>
                <p className={styles.headerCellLabel}>Aktivni Program/Grupa</p>
              </th>
              {(userId === "Referada" ||
                userId === "Voditelj" ||
                userId === "Polaznik VT-a" ||
                userId === "Supervizor") && (
                <>
                  <th className={styles.headerCell}>
                    <p className={styles.headerCellLabel}>Email</p>
                  </th>
                  <th className={styles.headerCell}>
                    <p className={styles.headerCellLabel}>Broj mobitela</p>
                  </th>
                  {editMode && !isCreateNewGroup && (
                    <th className={styles.headerCell}>
                      <p className={styles.headerCellLabel}>Akcije</p>
                    </th>
                  )}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <tr className={styles.tableDataRow} key={index}>
                  <td className={styles.nameCell}>
                    {item?.imageUrl ? (
                      <>
                        {item?.imageUrl ? (
                          <img
                            alt="user"
                            src={item?.imageUrl}
                            className={styles.userPhoto}
                          />
                        ) : (
                          <div className={styles.emptyProfilePhoto}>
                            <UserIcon className={styles.profilePhotoIcon} />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {item?.element?.imageUrl ? (
                          <>
                            <img
                              alt="user"
                              src={item?.element?.imageUrl}
                              className={styles.userPhoto}
                            />
                          </>
                        ) : (
                          <>
                            {item?.user?.imageUrl ? (
                              <img
                                alt="user"
                                src={item?.user?.imageUrl}
                                className={styles.userPhoto}
                              />
                            ) : (
                              <div className={styles.emptyProfilePhoto}>
                                <UserIcon className={styles.profilePhotoIcon} />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {/** -----------  */}
                    <p className={styles.nameText}>
                      {item?.user ? (
                        <>
                          {item?.user?.firstName && item?.user?.lastName
                            ? [item?.user?.firstName, " ", item?.user?.lastName]
                            : "Nema podataka!"}
                        </>
                      ) : (
                        <>
                          {item?.firstName && item?.lastName ? (
                            [item?.firstName, " ", item?.lastName]
                          ) : (
                            <>
                              {item?.element?.firstName &&
                              item?.element?.lastName ? (
                                <>
                                  {item?.element?.firstName}{" "}
                                  {item?.element?.lastName}
                                </>
                              ) : (
                                <>{"Nema podataka!"}</>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </p>
                  </td>
                  <td className={styles.programCell}>
                    <div className={styles.programCellContent}>
                      <p className={styles.programText}>
                        {item?.aktivniProgrami ? (
                          <>
                            {item?.aktivniProgrami.length === 0
                              ? "---"
                              : item?.aktivniProgrami.length === 1
                              ? `${item?.aktivniProgrami?.[0]}`
                              : item?.aktivniProgrami.length > 1
                              ? `${item?.aktivniProgrami?.[0]}, ${item?.aktivniProgrami?.[1]}`
                              : "---"}
                          </>
                        ) : <>
                            {item?.programNames && (
                              <>
                                {item?.programNames.length === 0
                                  ? "---"
                                  : item?.programNames.length === 1
                                  ? `${item?.programNames?.[0]}`
                                  : item?.programNames.length > 1
                                  ? `${item?.programNames?.[0]}, ${item?.programNames?.[1]}`
                                  : "---"}
                              </>
                            )}
                          </> ? (
                          <>
                            {item?.activePrograms && (
                              <>
                                {" "}
                                {item?.activePrograms.length === 0
                                  ? "---"
                                  : item?.activePrograms.length === 1
                                  ? `${item?.activePrograms?.[0]}`
                                  : item?.activePrograms.length > 1
                                  ? `${item?.activePrograms?.[0]}, ${item?.activePrograms?.[1]}`
                                  : "---"}
                              </>
                            )}
                          </>
                        ) : (
                          "Nema podataka"
                        )}
                      </p>
                      <p className={styles.groupText}>
                        {item?.grupe?.groups ? (
                          <>
                            {item?.grupe?.groups && (
                              <>
                                {item?.grupe?.groups.length === 0
                                  ? "---"
                                  : item?.grupe?.groups.length === 1
                                  ? `${item?.grupe?.groups?.[0]?.name}`
                                  : item?.grupe?.groups.length > 1
                                  ? `${item?.grupe?.groups?.[0]?.name}, ${item?.grupe?.groups?.[1]?.name}`
                                  : "---"}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {item?.activeGroups ? (
                              <>
                                {" "}
                                {item?.activeGroups.length === 0
                                  ? "---"
                                  : item?.activeGroups.length === 1
                                  ? `${item?.activeGroups?.[0]}`
                                  : item?.activeGroups.length > 1
                                  ? `${item?.activeGroups?.[0]}, ${item?.activeGroups?.[1]}`
                                  : "---"}
                              </>
                            ) : (
                              "Nema podataka!"
                            )}
                          </>
                        )}
                      </p>
                    </div>
                  </td>
                  {(userId === "Referada" || userId === "Voditelj") && (
                    <>
                      <td className={styles.emailCell}>
                        <a
                          href={
                            item.emailAddress
                              ? `mailto:${item.emailAddress} `
                              : item?.user?.emailAddress
                              ? `mailto:${item?.user?.emailAddress}`
                              : `mailto:${item?.element?.emailAddress}`
                          }
                          className={styles.emailLink}
                        >
                          <p className={styles.emailText}>
                            {item?.user?.emailAddress ? (
                              <>{item?.user?.emailAddress}</>
                            ) : (
                              <>
                                {item.emailAddress ? (
                                  <>{item?.emailAddress}</>
                                ) : (
                                  <>{item?.element?.emailAddress}</>
                                )}
                              </>
                            )}
                          </p>
                        </a>
                      </td>
                      <td className={styles.phoneCell}>
                        <a
                          href={
                            item.phoneNumber
                              ? `tel:${item.phoneNumber}`
                              : item?.user?.phoneNumber
                              ? `tel:${item?.user?.phoneNumber}`
                              : `tel:${item?.element?.phoneNumber}`
                          }
                          className={styles.emailLink}
                        >
                          <p className={styles.phoneText}>
                            {item?.user?.phoneNumber ? (
                              <>{item?.user?.phoneNumber}</>
                            ) : (
                              <>
                                {item.phoneNumber ? (
                                  <>{item?.phoneNumber}</>
                                ) : (
                                  <>
                                    {item?.element?.phoneNumber
                                      ? item?.element?.phoneNumber
                                      : "Nema podatka"}
                                  </>
                                )}
                              </>
                            )}
                          </p>
                        </a>
                      </td>
                      {editMode && !isCreateNewGroup && (
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            marginRight: 30,
                            paddingLeft: 25,
                          }}
                        >
                          <div
                            className={styles.removeItemButton}
                            onClick={() => {
                              handleRemoveItem(item.id);
                            }}
                          >
                            <CloseIcon className={styles.removeItemIcon} />
                          </div>
                          <div
                            className={styles.removeItemButton}
                            onClick={() => {
                              handleRemoveItem(item.id);
                            }}
                          >
                            <ArchiveUser className={styles.archiveItemIcon} />
                          </div>
                        </td>
                      )}
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {editMode && (
        <div className={styles.tableBottomContainer}>
          <div
            onClick={() => {
              navigate("/dodaj-polaznika-u-grupu", {
                state: {
                  groupId: groupData?.id,
                  isCreateNewGroup: isCreateNewGroup,
                  selectedAttendeesData: data,
                },
              });
            }}
            className={styles.addAttendeeButton}
          >
            <p className={styles.addAttendeeButtonText}>Dodaj polaznika</p>
            <CrossIcon className={styles.addAttendeeButtonIcon} />
          </div>
        </div>
      )}
    </div>
  );
};
export default AttendeesTable;
