import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getTherapyRoomAvailability = async (accessToken, date, roomId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/checkTherapyRoomAvailability?date=${date}&room=${roomId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching therapy room availability data", error);
    throw error;
  }
};
