import useWindowDimensions from "../../../hooks/useWindowDimensions";
import styles from "./CustomInput.module.css";

const CustomInput = ({
  labelText,
  placeholder,
  type,
  name,
  onBlur,
  onChange,
  value,
  pattern,
  disabled,
  disabledLabel,
  isBirthDate,
  isRed,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 1250;
  return (
    <label className={styles.inputLabel}>
      {labelText && (
        <p
          className={
            disabledLabel ? styles.disabledLabel : styles.inputLabelText
          }
        >
          {labelText}
        </p>
      )}
      <input
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        className={`${styles.input} ${isRed ? styles.red : ""}`}
        pattern={pattern ? pattern : null}
        disabled={disabled}
        onClick={isBirthDate && !isMobile ? (e) => e.preventDefault() : null}
      />
    </label>
  );
};
export default CustomInput;
