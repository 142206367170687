import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal/Modal";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { IndividualneSupervizijeValidationSchema } from "./Schema";
import styles from "./Styles.module.css";
import {
  brojTerapije,
  handleInputChange,
  handleUnesiSateClick,
  postData,
} from "./utils";

function IndividualneSupervizije({ supervizori, userEvidentionID }) {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const fullName = localStorage.getItem("evidentionFullName");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    date: new Date().toISOString().split("T")[0],
    brojSati: "",
    fullName: "",
    vanjskiSupervizor: "",
  });
  useEffect(() => {
    if (formData.fullName !== "Vanjski supervizor") {
      setFormData({
        ...formData,
        vanjskiSupervizor: "",
      });
    }
  }, [formData.fullName]);
  const otherInfo = JSON.stringify(
    postData("individual_supervision", formData)
  );
  const [errors, setErrors] = useState({});
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        Ručni unos sati za individualne supervizije
      </span>
      <div className={styles.inputWrapper}>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Datum individualne supervizije</span>
          <input
            className={styles.rucniUnosInput}
            type="date"
            name="date"
            value={formData.date}
            onChange={(e) =>
              handleInputChange("date", e.target.value, setFormData, formData)
            }
          />
          {errors.date && <div className={styles.error}>{errors.date}</div>}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Broj sati supervizije</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="brojSati"
            value={formData.brojSati}
            onChange={(e) =>
              handleInputChange(
                "brojSati",
                e.target.value,
                setFormData,
                formData
              )
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {brojTerapije.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.brojSati && (
            <div className={styles.error}>{errors.brojSati}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Supervizor</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={(e) =>
              handleInputChange(
                "fullName",
                e.target.value,
                setFormData,
                formData
              )
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {supervizori.map((item, index) => (
              <option key={index} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.fullName && (
            <div className={styles.error}>{errors.fullName}</div>
          )}
        </div>
        {formData.fullName === "Vanjski supervizor" && (
          <div className={styles.inputColumn}>
            <span className={styles.label}>
              Ime i prezime vanjskog supervizora
            </span>
            <input
              className={styles.rucniUnosInput}
              type="text"
              name="vanjskiSupervizor"
              value={formData.vanjskiSupervizor}
              onChange={(e) =>
                handleInputChange(
                  "vanjskiSupervizor",
                  e.target.value,
                  setFormData,
                  formData
                )
              }
            />
            {errors.vanjskiSupervizor && (
              <div className={styles.error}>{errors.vanjskiSupervizor}</div>
            )}
          </div>
        )}
      </div>
      <div className={styles.btnRow}>
        <button
          onClick={() => {
            localStorage.setItem("evidentionFullName", fullName);
            localStorage.setItem("evidentionUserID", userEvidentionID);
            navigate("/evidencija");
          }}
          className={styles.cancelBtn}
        >
          Odustani
        </button>
        <button
          className={styles.successBtn}
          onClick={() =>
            handleUnesiSateClick(
              setErrors,
              formData,
              IndividualneSupervizijeValidationSchema,
              userEvidentionID,
              "individual_supervision",
              otherInfo,
              authToken,
              setModalContent,
              navigate,
              setShowModal
            )
          }
        >
          Unesi sate
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default IndividualneSupervizije;
