import Question from "../../../assets/img/Help.png";
import Success from "../../../assets/img/Success.png";
import styles from "../PolazniciPregled.module.css";
import { dodajZakupitelja, ukloniZakupitelja } from "../api";
import { fetchData } from "./fetchData";

const monthsCroatian = [
  "siječnja",
  "veljače",
  "ožujka",
  "travnja",
  "svibnja",
  "lipnja",
  "srpnja",
  "kolovoza",
  "rujna",
  "listopada",
  "studenoga",
  "prosinca",
];

export function formatDate(apiDate) {
  const originalDate = new Date(apiDate);
  const day = originalDate.getDate();
  const monthIndex = originalDate.getMonth();
  const year = originalDate.getFullYear();

  const formattedDate = `${day < 10 ? "0" : ""}${day}. ${
    monthsCroatian[monthIndex]
  } ${year}.`;

  return formattedDate;
}

export const handleSuccessAddZakupiteljClick = (
  setIsModalZakupiteljOpen,
  setModalContent,
  setSuccessModal,
  pojediniPolaznikID,
  selectedZakupiteljOption,
  accessToken,
  setUserData,
  setUserInGroups,
  setPolozeniProgram,
  setIsZakupitelj,
  setMjestoUpisaOptions,
  setAllPrograms,
  setZakupiteljType
) => {
  dodajZakupitelja(pojediniPolaznikID, selectedZakupiteljOption, accessToken)
    .then((response) => {
      if (response.status === 200) {
        setIsModalZakupiteljOpen(false);
        setModalContent({
          image: Success,
          description: "Polaznik uspješno dodan",
          buttons: [
            {
              label: "U redu",
              action: () => setSuccessModal(false),
              className: "modal-btn-success",
            },
          ],
        });
        setSuccessModal(true);
        fetchData(
          pojediniPolaznikID,
          accessToken,
          setUserData,
          setUserInGroups,
          setPolozeniProgram,
          setIsZakupitelj,
          setMjestoUpisaOptions,
          setAllPrograms,
          setZakupiteljType
        );
      }
    })
    .catch((error) => {
      console.error("Error while adding zakupitelj:", error);
    });
};

export const handleSuccessRemoveZakupiteljClick = (
  setModalContent,
  setSuccessModal,
  pojediniPolaznikID,
  accessToken,
  setUserData,
  setUserInGroups,
  setPolozeniProgram,
  setIsZakupitelj,
  setMjestoUpisaOptions,
  setAllPrograms,
  setZakupiteljType
) => {
  setModalContent({
    image: Question,
    title: "Jeste li sigurni da želite ukloniti polaznika iz zakupitelja?",
    description:
      "Time će polaznik vidjeti samo svoje događaje i neće više moći dodavati svoje terapije.",
    buttons: [
      {
        label: "Odustani",
        action: () => setSuccessModal(false),
        className: "modal-btn-success",
      },
      {
        label: "Svejedno ukloni",
        action: () => {
          ukloniZakupitelja(pojediniPolaznikID, accessToken).then(
            (response) => {
              if (response.status === 200) {
                setSuccessModal(false);
                fetchData(
                  pojediniPolaznikID,
                  accessToken,
                  setUserData,
                  setUserInGroups,
                  setPolozeniProgram,
                  setIsZakupitelj,
                  setMjestoUpisaOptions,
                  setAllPrograms,
                  setZakupiteljType
                );
              }
            }
          );
        },
        className: styles.deleteBtn,
      },
    ],
  });
  setSuccessModal(true);
};
export const selectStyles = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    alignContent: "center",
    backgroundColor: "#FFFFFF",
    color: "#5F6D7E",
    height: "46px",
    padding: "8px 16px",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "8px",
    border: "1px solid #D1D9E2;",
    boxShadow: "4px 4px 64px 0px rgba(95, 109, 126, 0.10);",
    cursor: "pointer",
    "&:focus": {
      border: "0 !important",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    };
  },
};
export const zakupiteljOptions = [
  {
    label: "Basic",
    value: "BASIC",
  },
  {
    label: "Premium",
    value: "PREMIUM",
  },
];

export const handleGetUserEvidention = async (navigate, userId, fullName) => {
  navigate("/evidencija", {
    state: {
      userEvidentionID: userId,
      fullName: fullName,
    },
  });
};
