import Exit from "../../../assets/img/ExitIcon.png";
import styles from "./InfoModal.module.css";
import { infoLabels } from "./modalUtils";

const InfoModal = ({ onClick, data, rooms }) => {
  const { room, timeFrom, timeTo, eventType, title } = data;
  const roomName = rooms.find((item) => item.value === room);
  const eventData = [roomName?.label, timeFrom, timeTo];

  return (
    <div className={styles.wrapper}>
      <img src={Exit} alt="" className={styles.exit} onClick={onClick} />
      <div className={styles.modalContainer}>
        <h1 className={styles.h1}>
          {eventType === undefined && title}
          {eventType === "other" && "Ostalo"}
          {eventType === "individual_supervision" && "Individualna supervizija"}
          {eventType === "Terapija" && ":User"}
        </h1>
        <div className={styles.content}>
          <div className={styles.labels}>
            {infoLabels?.map((label, index) => (
              <div className={styles.label} key={index}>
                {label}
              </div>
            ))}
          </div>
          <div className={styles.eventInfos}>
            {eventData.map((data, index) => (
              <div className={styles.data} key={index}>
                {data}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
