import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal/Modal";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { RetroaktivniRadKlijentiValidationSchema } from "./Schema";
import styles from "./Styles.module.css";
import {
  handleInputChange,
  handleUnesiSateClick,
  postData,
  previousAcademicYears,
} from "./utils";

function RetroaktivniRadKlijenti({ userEvidentionID }) {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [showModal, setShowModal] = useState(false);
  const fullName = localStorage.getItem("evidentionFullName");
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    fullName: "",
    year: new Date().toISOString().split("T")[0],
    brojSati: "",
  });
  const otherInfo = JSON.stringify(
    postData("manual_work_with_client", formData)
  );
  const [errors, setErrors] = useState({});
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        Ručni unos sati za retroaktivni rad s klijentom
      </span>
      <div className={styles.inputWrapper}>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Ime i prezime klijenta</span>
          <input
            className={styles.rucniUnosInput}
            type="text"
            name="fullName"
            placeholder="Upiši"
            value={formData.fullName}
            onChange={(e) =>
              handleInputChange(
                "fullName",
                e.target.value,
                setFormData,
                formData
              )
            }
          />
          {errors.fullName && (
            <div className={styles.error}>{errors.fullName}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Datum održavanja</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="year"
            value={formData.year}
            onChange={(e) =>
              handleInputChange("year", e.target.value, setFormData, formData)
            }
          >
            {previousAcademicYears.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.year && <div className={styles.error}>{errors.year}</div>}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>
            Dosadašnji broj terapije s klijentom
          </span>
          <input
            className={styles.rucniUnosInput}
            type="number"
            placeholder="Upiši"
            name="brojSati"
            min="1"
            max="200"
            value={formData.brojSati}
            onChange={(e) =>
              handleInputChange(
                "brojSati",
                e.target.value,
                setFormData,
                formData
              )
            }
          />
          {errors.brojSati && (
            <div className={styles.error}>{errors.brojSati}</div>
          )}
        </div>
      </div>
      <div className={styles.btnRow}>
        <button
          onClick={() => {
            localStorage.setItem("evidentionFullName", fullName);
            localStorage.setItem("evidentionUserID", userEvidentionID);
            navigate("/evidencija");
          }}
          className={styles.cancelBtn}
        >
          Odustani
        </button>
        <button
          className={styles.successBtn}
          onClick={() =>
            handleUnesiSateClick(
              setErrors,
              formData,
              RetroaktivniRadKlijentiValidationSchema,
              userEvidentionID,
              "manual_work_with_client",
              otherInfo,
              authToken,
              setModalContent,
              navigate,
              setShowModal
            )
          }
        >
          Unesi sate
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default RetroaktivniRadKlijenti;
