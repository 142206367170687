import React from "react";
import Avatar from "../../../../assets/img/Avatar.png";
import { useUserState } from "../../../../context/UserContext";
import "./IntrospekcijeTable.css";
import { renderIntrospekcija } from "./utils";

function RenderTableData({ data = [], emptyTDNumber }) {
  const { role } = useUserState();
  const userId = role;
  return (
    <>
      {data?.map((item, index) => (
        <>
          {userId !== "Voditelj" && (
            <tr key={index}>
              <td>
                <div className="introspekcije-mentor-row">
                  <img
                    alt=""
                    src={item?.mentorUrl || Avatar}
                    className="introspekcije-mentor-avatar"
                  />
                  {item?.mentorName || "Test Mentor"}
                </div>
              </td>
              {[...Array(emptyTDNumber - 1)].map((_, index) => (
                <td key={index} />
              ))}
            </tr>
          )}
          {item?.polaznici?.map((polaznik, polaznikIndex) => (
            <tr key={polaznikIndex}>
              <td style={{ borderRight: "1px solid #D1D9E2" }}>
                <div className="introspekcije-polaznik-column">
                  <span style={{ textTransform: "capitalize" }}>
                    {polaznik?.polaznikName}
                  </span>
                  <span style={{ color: "#818C99" }}>
                    {polaznik?.attendance}
                  </span>
                </div>
              </td>
              {polaznik?.introspections.map((introspection, introIndex) => (
                <td
                  key={introIndex}
                  style={{ borderRight: "1px solid #D1D9E2" }}
                >
                  {renderIntrospekcija(introspection)}
                </td>
              ))}
            </tr>
          ))}
        </>
      ))}
    </>
  );
}

export default RenderTableData;
