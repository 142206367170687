import React, { useEffect, useState } from "react";
import Exit from "../../../../assets/img/ExitIcon.png";
import Pagination from "../../../../components/Pagination/Pagination";
import { useUserState } from "../../../../context/UserContext";
import { useAuthentication } from "../../../hooks/useAuthentication";
import TableTerapije from "./Table/TableTerapije";
import styles from "./TerapijeModal.module.css";
import { fetchTerapijeModalData } from "./utils";

function TerapijeModal({
  onClose,
  userId,
  modalEventType,
  topic,
  deleteFlag,
  setDeleteFlag,
  evidentionUserID,
}) {
  let columnNames = ["Datum", "Terapeut", "Broj terapija", "Obriši zapis"];
  if (topic.includes("Individualna")) {
    columnNames = ["Datum", "Supervizor", "Broj sati", "Obriši zapis"];
  }
  if (topic.includes("Fakultativne")) {
    columnNames = ["Datum", "Voditelj", "Broj sati", "Obriši zapis"];
  }
  if (topic.includes("Grupna")) {
    columnNames = [
      "Datum",
      "Tip grupne supervizije",
      "Supervizor",
      "Broj sati",
      "Obriši zapis",
    ];
  }
  if (topic.includes("terapija")) {
    columnNames = ["Datum", "Terapeut", "Broj terapija", "Obriši zapis"];
  }
  if (topic.includes("klijent")) {
    columnNames = ["Datum", "Klijent", "Broj sati", "Obriši zapis"];
  }
  if (topic.includes("grupom")) {
    columnNames = ["Datum", "Uloga", "Broj sati", "Obriši zapis"];
  }
  if (topic.includes("Intenzivi")) {
    columnNames = ["Godina", "Intenziv", "Broj sati", "Obriši zapis"];
  }
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [currentPage, setCurrentPage] = useState(1);
  const [modalData, setModalData] = useState([]);
  const [otherInfo, setOtherInfo] = useState([]);
  const [closeModal, setCloseModal] = useState(false);

  useEffect(() => {
    if (authToken)
      fetchTerapijeModalData(
        modalEventType,
        evidentionUserID,
        authToken,
        setModalData
      );
  }, [modalEventType, authToken]);

  useEffect(() => {
    if (modalData.length > 0) {
      const parsedOtherInfoArray = modalData.map((item) => ({
        otherInfo: JSON.parse(item.otherInfo),
        eventId: item.id,
      }));
      setOtherInfo(parsedOtherInfoArray);
    }
  }, [modalData]);

  useEffect(() => {
    if (closeModal) {
      onClose();
      setDeleteFlag(!deleteFlag);
    }
  }, [closeModal]);

  const itemsPerPage = 3;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOffers = otherInfo.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div className={styles.modalOverlay}>
      <img
        alt=""
        src={Exit}
        className={styles.exitBtn}
        onClick={() => onClose()}
      />
      <div className={styles.modalWrapper}>
        <div className={styles.infoModal}>
          <p className={styles.title}>{topic}</p>
          <div className={styles.tableWrapper}>
            <TableTerapije
              columnNames={columnNames}
              data={currentOffers}
              userId={userId}
              eventType={modalEventType}
              setModalData={setModalData}
              setCloseModal={setCloseModal}
              setDeleteFlag={setDeleteFlag}
              deleteFlag={deleteFlag}
              evidentionUserID={evidentionUserID}
              topic={topic}
            />
          </div>
          <Pagination
            itemsToDisplay={otherInfo}
            setCurrent={setCurrentPage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default TerapijeModal;
