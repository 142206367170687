import { getAllGroupsForPolaznik } from "../api/getAllGroupsForPolaznik";
import { getAllGroupsReferada } from "../api/getAllGroupsReferada";
import { getSearchGroupsReferada } from "../api/getSearchGroupsReferada";

export const fetchGroupData = async (
  page,
  cityIds,
  programId,
  accessToken,
  aktivne,
  setAllGroupsReferada,
  setNumberOfGroups
) => {
  try {
    const allGroupsForReferada = await getAllGroupsReferada(
      page,
      10,
      cityIds,
      programId,
      accessToken,
      aktivne
    );
    setAllGroupsReferada(allGroupsForReferada.groups);
    setNumberOfGroups(allGroupsForReferada.count);
  } catch (error) {
    console.error("Error in fetchData:", error);
  }
};

export const fetchGroupSearchData = async (
  page,
  accessToken,
  aktivne,
  setAllGroupsReferada,
  setNumberOfGroups,
  search
) => {
  try {
    const allGroupsForReferada = await getSearchGroupsReferada(
      page,
      10,
      accessToken,
      aktivne,
      search
    );
    setAllGroupsReferada(allGroupsForReferada.groups);
    setNumberOfGroups(allGroupsForReferada.count);
  } catch (error) {
    console.error("Error in fetchData:", error);
  }
};

export const fetchGroupsForPolaznik = async (
  accessToken,
  polaznikID,
  setAllGroupsPolaznik
) => {
  try {
    const allGroupsForPolaznik = await getAllGroupsForPolaznik(
      polaznikID,
      accessToken
    );
    setAllGroupsPolaznik(allGroupsForPolaznik);
  } catch (error) {
    console.error("Error in fetchData:", error);
  }
};

export const handleTabChange = (newValue, setCurrentTabIndex) => {
  setCurrentTabIndex(newValue);
};

export const tabsData = [
  { value: 0, label: "Grupe koje vodim" },
  { value: 1, label: "Moje grupe" },
];
