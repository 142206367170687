import React from "react";
import Trash from "../../../../../assets/img/TrashIcon.png";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import { formatDate } from "../../../../myProfile/utils";
import { handleDeleteEntry } from "../utils";

function RenderTableData({
  data = [],
  userId,
  eventType,
  setModalData,
  setCloseModal,
  setDeleteFlag,
  deleteFlag,
  evidentionUserID,
  topic,
}) {
  const { authToken } = useAuthentication();

  return (
    <>
      {data.map((item, index) => (
        <tr key={index}>
          <td>
            {item?.otherInfo?.dateGrupa
              ? item?.otherInfo?.dateGrupa
              : item?.otherInfo?.date
              ? formatDate(item?.otherInfo?.date)
              : item?.otherInfo?.year ||
                formatDate(item?.otherInfo?.date) === ""
              ? item?.otherInfo?.year
              : "Nema podataka"}
          </td>
          {item?.otherInfo?.tipGrupneSupervizije &&
            !(
              topic.includes("na Trijadi") ||
              topic.includes("Supervizant") ||
              item?.otherInfo?.tipGrupneSupervizije.includes("individual")
            ) && (
              <td>
                {item?.otherInfo?.tipGrupneSupervizije
                  ? item?.otherInfo?.tipGrupneSupervizije === "trijada"
                    ? "Trijada"
                    : item?.otherInfo?.tipGrupneSupervizije === "eper"
                    ? "EPeR"
                    : item?.otherInfo?.tipGrupneSupervizije === "ags"
                    ? "AGS"
                    : item?.otherInfo?.tipGrupneSupervizije ===
                      "ags_supervisant"
                    ? "AGS(supervizant)"
                    : item?.otherInfo?.tipGrupneSupervizije ===
                      "trijada_therapist"
                    ? "Trijada(terapeut)"
                    : "Nema podataka"
                  : null}
              </td>
            )}
          <td>
            {item?.otherInfo?.name?.includes("Supervizor:")
              ? item?.otherInfo?.name.split(";")[0].split(":")[1]
              : item?.otherInfo?.name
              ? item?.otherInfo?.name
              : item?.otherInfo?.fullName === "Vanjski supervizor" &&
                item?.otherInfo?.vanjskiSupervizor
              ? item?.otherInfo?.vanjskiSupervizor
              : item?.otherInfo?.fullName === "Vanjski terapeut" &&
                item?.otherInfo?.vanjskiTerapeut
              ? item?.otherInfo?.vanjskiTerapeut
              : item?.otherInfo?.fullName
              ? item?.otherInfo?.fullName
              : item?.otherInfo?.uloga
              ? item?.otherInfo?.uloga
              : item?.otherInfo?.Supervizor
              ? item?.otherInfo?.Supervizor
              : "Nema podataka"}
          </td>
          <td style={{ paddingLeft: 86 }}>
            {topic?.includes("Grupna") && item?.otherInfo?.satiGrupneSupervizije
              ? item?.otherInfo?.satiGrupneSupervizije
              : (topic?.includes("trijadi") || topic?.includes("AGS")) &&
                item?.otherInfo?.satiIndividualneSupervizije
              ? item?.otherInfo?.satiIndividualneSupervizije
              : item?.otherInfo?.gprSati
              ? item?.otherInfo?.gprSati
              : item?.otherInfo?.brojSati
              ? item?.otherInfo?.brojSati
              : 1}
          </td>
          <td style={{ paddingLeft: 70 }}>
            <button
              className={"table-terapije-btn"}
              onClick={() =>
                handleDeleteEntry(
                  item.eventId,
                  evidentionUserID,
                  authToken,
                  eventType,
                  setModalData,
                  setCloseModal,
                  setDeleteFlag,
                  deleteFlag,
                  item
                )
              }
            >
              <img alt="" src={Trash} className="table-terapije-trash-icon" />
            </button>
          </td>
        </tr>
      ))}
    </>
  );
}

export default RenderTableData;
