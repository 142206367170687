import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal/Modal";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { AktualniRadKlijentiValidationSchema } from "./Schema";
import styles from "./Styles.module.css";
import { handleInputChange, handleUnesiSateClick, postData } from "./utils";

function AktualniRadKlijenti({
  currentAcademicYearStart,
  currentAcademicYearEnd,
  userEvidentionID,
}) {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [showModal, setShowModal] = useState(false);
  const fullName = localStorage.getItem("evidentionFullName");
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    fullName: "",
    date: new Date().toISOString().split("T")[0],
    year: "",
    brojSati: 1,
  });
  const otherInfo = JSON.stringify(
    postData("manual_work_with_client", formData)
  );
  const [errors, setErrors] = useState({});
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        Ručni unos sati za aktualni rad s klijentom
      </span>
      <div className={styles.inputWrapper}>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Ime i prezime klijenta</span>
          <input
            className={styles.rucniUnosInput}
            type="text"
            name="fullName"
            placeholder="Upiši"
            value={formData.fullName}
            onChange={(e) =>
              handleInputChange(
                "fullName",
                e.target.value,
                setFormData,
                formData
              )
            }
          />
          {errors.fullName && (
            <div className={styles.error}>{errors.fullName}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Datum održavanja</span>
          <input
            className={styles.rucniUnosInput}
            type="date"
            name="date"
            min={`${currentAcademicYearStart}-09-01`}
            max={`${currentAcademicYearEnd}-08-31`}
            value={formData.date}
            onChange={(e) =>
              handleInputChange("date", e.target.value, setFormData, formData)
            }
          />
          {errors.date && <div className={styles.error}>{errors.date}</div>}
        </div>
      </div>
      <div className={styles.btnRow}>
        <button
          onClick={() => {
            localStorage.setItem("evidentionFullName", fullName);
            localStorage.setItem("evidentionUserID", userEvidentionID);
            navigate("/evidencija");
          }}
          className={styles.cancelBtn}
        >
          Odustani
        </button>
        <button
          className={styles.successBtn}
          onClick={() =>
            handleUnesiSateClick(
              setErrors,
              formData,
              AktualniRadKlijentiValidationSchema,
              userEvidentionID,
              "manual_work_with_client",
              otherInfo,
              authToken,
              setModalContent,
              navigate,
              setShowModal
            )
          }
        >
          Unesi sate
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default AktualniRadKlijenti;
