import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchIntenziviData = async (id, isWeekend, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/intensive/${id}?isWeekend=${isWeekend}`,
      {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const vrstaIntenziva = [
  { value: "ljetni", label: "Ljetni" },
  { value: "vikend", label: "Vikend" },
];

export const podvrstaLjetni = [
  { value: "procesni", label: "Procesni" },
  { value: "IPD", label: "IPD" },
];

export const podvrstaVikend = [
  { value: "povratakSebi", label: "Povratak sebi" },
  { value: "procesniVikend", label: "Procesni (vikend)" },
  { value: "odnosi", label: "Odnosi" },
];

export const getSubcategoryOptions = (formData) => {
  if (formData.type === "ljetni") {
    return podvrstaLjetni;
  } else return podvrstaVikend;
};
