import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Anonymous from "../../../../assets/img/Anonymous.png";
import Info from "../../../../assets/svg/InfoIcon.svg";
import OcjeneTooltip from "../../../../components/OcjenaTooltip/OcjenaTooltip";
import { useUserState } from "../../../../context/UserContext";
import { getUserById } from "../../../djelatnikProfil/api/getUserById";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { getEvaluations } from "../../api/getEvaluations";
import { getOsvrti } from "../../api/getOsvrti";
import { evaluations } from "../../utils";
import CommentContainer from "./CommentContainer";
import styles from "./OsvrtiOcjeneNaMojRad.module.css";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#4D4D4D",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    fontWeight: 400,
    fontFamily: "Inter",
    padding: 15,
  },
}));

function OsvrtiOcjeneNaMojRad() {
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [osvrti, setOsvrti] = useState([]);
  const [evaluationsDB, setEvaluationsDB] = useState([]);
  const [voditelj, setVoditelj] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const urlIsPojediniVoditelj = searchParams.get("isPojediniVoditelj");
  const paramVoditelj = searchParams.get("voditeljId");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUserById(paramVoditelj, authToken);
        setVoditelj(response);
      } catch (error) {
        console.error("Error in fetchUser:", error);
      }
    };
    if (authToken && paramVoditelj) {
      fetchUser();
    }
  }, [authToken]);

  useEffect(() => {
    const fetchOsvrti = async () => {
      try {
        if (!paramVoditelj) {
          const response = await getOsvrti(userData.id, authToken);
          setOsvrti(response.osvrti);
        } else {
          const response = await getOsvrti(paramVoditelj, authToken);
          setOsvrti(response.osvrti);
        }
      } catch (error) {
        console.error("Error in fetchOsvrti:", error);
      }
    };
    if (authToken) fetchOsvrti();
  }, [authToken]);

  useEffect(() => {
    const fetchEvaluations = async () => {
      try {
        if (!paramVoditelj) {
          const response = await getEvaluations(userData.id, authToken);
          setEvaluationsDB(response.evaluations);
        } else {
          const response = await getEvaluations(paramVoditelj, authToken);
          setEvaluationsDB(response.evaluations);
        }
      } catch (error) {
        console.error("Error in fetchEvaluations:", error);
      }
    };
    if (authToken) fetchEvaluations();
  }, [authToken]);

  return (
    <div className={styles.wrapper}>
      {!urlIsPojediniVoditelj ? (
        <span className={styles.avatarName} style={{ marginBottom: 30 }}>
          Moje ocjene
        </span>
      ) : (
        <div style={{ display: "flex", gap: 20, marginBottom: 30 }}>
          <img
            src={voditelj?.imageUrl}
            alt=""
            style={{ height: 80, width: 80, borderRadius: 80 }}
          />
          <div>
            <p
              style={{
                fontSize: 22,
                color: "#4d4d4d",
                fontWeight: 600,
                fontFamily: "Inter",
                marginBlockEnd: 0,
                marginBlockStart: 15,
              }}
            >
              {voditelj?.firstName + " " + voditelj?.lastName}
            </p>
            <p
              style={{
                fontSize: 15,
                color: "#5F6D7EC7",
                fontWeight: 600,
                fontFamily: "Inter",
                marginBlockStart: 5,
              }}
            >
              Voditelj
            </p>
          </div>
        </div>
      )}

      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          borderRadius: 8,
          borderStyle: "hidden",
          boxShadow: " 0 0 0 1px #D1D9E2",
          marginLeft: "2px",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #D1D9E2  ",
                borderRadius: "8px 0 0 0",
                padding: "10px",
                width: "200px",
                textAlign: "left",
                backgroundColor: "#F8F9FB",
                fontSize: 15,
                color: "#5F6D7EC7",
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              Kriterij
            </th>
            {[2023, 2024, 2025, 2026].map((year) => (
              <th
                key={year}
                style={{
                  border: "1px solid #D1D9E2   ",
                  borderRadius: "0 8px 0 0",
                  textAlign: "center",
                  backgroundColor: "#F8F9FB",
                  fontSize: 15,
                  color: "#5F6D7EC7",
                  fontWeight: 600,
                  fontFamily: "Inter",
                }}
              >
                <div style={{ padding: "15px" }}>
                  {year}/{year + 1}
                </div>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      borderRight: "1px solid #D1D9E2   ",
                      padding: "0 10px",
                      flex: 1,
                      fontSize: 12,
                    }}
                  >
                    Evaluacija 1
                  </p>
                  <p style={{ padding: "0 10px", flex: 1, fontSize: 12 }}>
                    Evaluacija 2
                  </p>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {evaluations.map((evaluation, index) => (
            <tr key={index}>
              <td
                style={{
                  border: "1px solid #D1D9E2  ",
                  padding: "10px",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: 14,
                    color: "#2E3646",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  }}
                >
                  {evaluation.title}
                  <LightTooltip title={evaluation.description} placement="top">
                    <img
                      src={Info}
                      alt=""
                      height={17}
                      width={17}
                      style={{ cursor: "pointer" }}
                    />
                  </LightTooltip>
                </div>
              </td>
              {[0, 1, 2, 3].map((_, i) => (
                <td
                  key={i}
                  style={{
                    border: "1px solid #D1D9E2  ",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  <td
                    style={{
                      padding: "0 52px",
                      fontSize: 15,
                      color: "#5F6D7E",
                      fontWeight: 600,
                      fontFamily: "Inter",
                      borderRight: "1px solid #D1D9E2   ",
                    }}
                    className={styles.evaluationNumber}
                  >
                    <OcjeneTooltip
                      data={{
                        ocjena:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][0] &&
                          evaluationsDB[_][0].average &&
                          evaluationsDB[_][0]?.average[index] !== 0 &&
                          evaluationsDB[_][0]?.average[index] !== null
                            ? evaluationsDB[_][0].average[index].toFixed(1)
                            : "0.0",
                        allCount:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][0] &&
                          evaluationsDB[_][0].count,
                        count5:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][0] &&
                          evaluationsDB[_][0].gradeCount &&
                          evaluationsDB[_][0].gradeCount[index][4],
                        count4:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][0] &&
                          evaluationsDB[_][0].gradeCount &&
                          evaluationsDB[_][0].gradeCount[index][3],
                        count3:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][0] &&
                          evaluationsDB[_][0].gradeCount &&
                          evaluationsDB[_][0].gradeCount[index][2],
                        count2:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][0] &&
                          evaluationsDB[_][0].gradeCount &&
                          evaluationsDB[_][0].gradeCount[index][1],
                        count1:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][0] &&
                          evaluationsDB[_][0].gradeCount &&
                          evaluationsDB[_][0].gradeCount[index][0],
                      }}
                    >
                      {evaluationsDB?.length > 0 &&
                      evaluationsDB[_][0].average &&
                      evaluationsDB[_][0]?.average[index] !== 0 &&
                      evaluationsDB[_][0]?.average[index] !== null
                        ? evaluationsDB[_][0].average[index].toFixed(1)
                        : "0.0"}
                    </OcjeneTooltip>
                  </td>

                  <td
                    style={{
                      padding: "0 52px",
                      fontSize: 15,
                      color: "#5F6D7E",
                      fontWeight: 600,
                      fontFamily: "Inter",
                    }}
                    className={styles.evaluationNumber}
                  >
                    <OcjeneTooltip
                      data={{
                        ocjena:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][1] &&
                          evaluationsDB[_][1].average &&
                          evaluationsDB[_][1]?.average[index] !== 0 &&
                          evaluationsDB[_][1]?.average[index] !== null
                            ? evaluationsDB[_][1].average[index].toFixed(1)
                            : "0.0",
                        allCount:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][1] &&
                          evaluationsDB[_][1].count,
                        count5:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][1] &&
                          evaluationsDB[_][1].gradeCount &&
                          evaluationsDB[_][1].gradeCount[index][4],
                        count4:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][1] &&
                          evaluationsDB[_][1].gradeCount &&
                          evaluationsDB[_][1].gradeCount[index][3],
                        count3:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][1] &&
                          evaluationsDB[_][1].gradeCount &&
                          evaluationsDB[_][1].gradeCount[index][2],
                        count2:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][1] &&
                          evaluationsDB[_][1].gradeCount &&
                          evaluationsDB[_][1].gradeCount[index][1],
                        count1:
                          evaluationsDB?.length > 0 &&
                          evaluationsDB[_][1] &&
                          evaluationsDB[_][1].gradeCount &&
                          evaluationsDB[_][1].gradeCount[index][0],
                      }}
                    >
                      {evaluationsDB?.length > 0 &&
                      evaluationsDB[_][1] &&
                      evaluationsDB[_][1].average &&
                      evaluationsDB[_][1]?.average[index] !== 0 &&
                      evaluationsDB[_][1]?.average[index] !== null
                        ? evaluationsDB[_][1].average[index].toFixed(1)
                        : "0.0"}
                    </OcjeneTooltip>
                  </td>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <span className={styles.avatarName} style={{ marginTop: 72 }}>
        Osvrti polaznika{" "}
        <span style={{ color: "#9FA7B2" }}>{osvrti.length}</span>
      </span>
      {osvrti.length > 0 ? (
        <div className={styles.ratingColumn} style={{ width: "90%" }}>
          {osvrti.map((item, index) => (
            <CommentContainer
              key={index}
              avatar={item.isAnonymous ? Anonymous : item.user.imageUrl}
              name={
                item.isAnonymous
                  ? "Anonimus"
                  : item.user.firstName + " " + item.user.lastName
              }
              date={item.createdAt}
              comment={item.osvrtText}
            />
          ))}
        </div>
      ) : (
        <div className={styles.emptyContainer}>
          <span className={styles.emptySpan}>
            Trenutno nema nijednog osvrta na ovog korisnika.
          </span>
        </div>
      )}
    </div>
  );
}

export default OsvrtiOcjeneNaMojRad;
