import React, { useState } from "react";
import Anonymous from "../../../../../../assets/img/Anonymous.png";
import Plus from "../../../../../../assets/img/WhitePlus.png";
import { useUserState } from "../../../../../../context/UserContext";
import { formatDate } from "../../../../../myProfile/utils";
import KomentarModal from "../KomentarModal/KomentarModal";

function RenderTableData({ data = [], onClick }) {
  const { userData } = useUserState();
  const [showModal, setShowModal] = useState(false);
  const [showFullOpis, setShowFullOpis] = useState([]);

  const toggleOpis = (index) => {
    const newShowFullOpis = [...showFullOpis];
    newShowFullOpis[index] = !newShowFullOpis[index];
    setShowFullOpis(newShowFullOpis);
  };
  return (
    <>
      {data.map((item, index) => (
        <tr key={index}>
          <td>{item?.date ? formatDate(item?.date) : "Nema podataka"}</td>
          <td>{item?.type ? item?.type : "Nema podataka"}</td>
          <td>
            <div className="table-evaluacije-row">
              <img
                alt=""
                className="table-evaluacije-avatar"
                src={item?.imageUrl ? item?.imageUrl : Anonymous}
              />
              {item?.fullName ? item?.fullName : "Nema podataka"}
            </div>
          </td>
          <td>
            {item?.comment ? (
              <>
                <span
                  className="table-nastupna-predavanja-comment-span"
                  style={{
                    WebkitLineClamp: showFullOpis[index] ? "unset" : 2,
                    whiteSpace: showFullOpis[index] ? "normal" : "",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "48ch",
                  }}
                >
                  {item?.comment}
                </span>
                {item?.comment.length > 32 && (
                  <div
                    onClick={() => toggleOpis(index)}
                    className="table-nastupna-predavanja-toggle"
                  >
                    {showFullOpis[index] ? "Prikaži manje" : "Prikaži više"}
                  </div>
                )}
              </>
            ) : (
              <>
                {userData.id === item?.userId ? (
                  <button
                    className="table-nastupna-predavanja-btn"
                    onClick={() => setShowModal(true)}
                  >
                    Dodaj komentar <img alt="" src={Plus} />
                  </button>
                ) : (
                  <span className="table-evaluacije-comment">
                    Komentar trenutno nije dodan.
                  </span>
                )}
              </>
            )}
          </td>
        </tr>
      ))}
      {showModal && <KomentarModal onClose={() => setShowModal(false)} />}
    </>
  );
}

export default RenderTableData;
