import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal/Modal.jsx";
import { useAuthentication } from "../../../hooks/useAuthentication";
import CustomInput from "../../../myProfile/components/customInput/CustomInput";
import SelectDropdown from "../../../singleGroup/components/selectDropdown/SelectDropdown";
import { handleArhivirajDjelatnika, handleEditDjelatnika } from "./API.js";
import styles from "./EditMode.module.css";
import { getAllGroups } from "./getAllGroups";
import { areAllFieldsFilled, handleInputChangeArhiviraj } from "./utils";

function EditMode({
  userData,
  mjestoUpisaOptions,
  allPrograms,
  onClickOdustani,
  isArhiviraj,
  isEdit,
}) {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [allGroups, setAllGroups] = useState([]);
  const [formData, setFormData] = useState({
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    emailAddress: userData?.emailAddress || "",
    phoneNumber: userData?.phoneNumber || "",
    dateOfBirth: userData?.dateOfBirth || "",
    gender: userData?.gender || "",
    city: userData?.city || "",
    programCity: userData?.programCity || "",
    title: userData?.title || "",
    qualification: userData?.qualification || "",
    address: userData?.address || "",
    OIB: userData?.OIB || "",
    country: userData?.country || "",
  });
  const [arhivirajFormData, setArhivirajFormData] = useState({
    isPodmireniTroskovi: false,
    programId: 1,
    groupId: 1,
  });

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    title: "",
    description: "",
    buttons: [],
  });

  const [optionGroups, setOptionGroups] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      const groups = await getAllGroups(authToken);
      setAllGroups(groups);
      // const filteredGroups = groups.filter(
      //   (group) => group.programId === arhivirajFormData.programId
      // );
      // setOptionGroups(
      //   filteredGroups.map((group) => ({ value: group.id, label: group.name }))
      // );
    };
    if (authToken) fetchGroups();
  }, [authToken]);

  useEffect(() => {
    const filteredGroups = allGroups.filter(
      (group) => group.programId == arhivirajFormData.programId
    );
    setOptionGroups(
      filteredGroups.map((group) => ({ value: group.id, label: group.name }))
    );
  }, [arhivirajFormData.programId]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div className={styles.profileForm}>
      <div className={styles.profileFormRow}>
        <div className={styles.profileFormColumn}>
          <CustomInput
            name="firstName"
            value={formData.firstName}
            labelText="Ime"
            onChange={handleInputChange}
          />
          <CustomInput
            name="lastName"
            value={formData.lastName}
            labelText="Prezime"
            onChange={handleInputChange}
          />
          <CustomInput
            name="dateOfBirth"
            value={formData.dateOfBirth}
            labelText="Datum rođenja"
            type="date"
            onChange={handleInputChange}
          />
          <CustomInput
            name="address"
            value={formData.address}
            labelText="Adresa stanovanja"
            onChange={handleInputChange}
          />
          <CustomInput
            name="country"
            value={formData.country}
            labelText="Država stanovanja"
            type="text"
            onChange={handleInputChange}
          />
          <SelectDropdown
            name="qualification"
            title="Stručna sprema"
            value={formData.qualification}
            options={[
              {
                value: "doktorZnanosti",
                label: "Doktor znanosti",
              },
            ]}
            onChange={handleInputChange}
          />
          <CustomInput
            name="OIB"
            value={formData.OIB}
            labelText="Identifikacijski broj (OIB/EMŠO/JMBG i sl.)"
            type="number"
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.profileFormColumn}>
          {isArhiviraj && (
            <>
              <SelectDropdown
                name="programId"
                title="Program koji upisuje polaznik"
                placeholder="Odaberi program"
                options={allPrograms}
                value={arhivirajFormData.programId}
                onChange={(e) =>
                  handleInputChangeArhiviraj(
                    e.target.name,
                    e.target.value,
                    e.target.type,
                    e.target.checked,
                    setArhivirajFormData,
                    arhivirajFormData
                  )
                }
              />
              <SelectDropdown
                name="groupId"
                title="Grupa u koju se upisuje polaznik"
                placeholder="Odaberi grupu"
                options={optionGroups}
                value={arhivirajFormData.groupId}
                onChange={(e) =>
                  handleInputChangeArhiviraj(
                    e.target.name,
                    e.target.value,
                    e.target.type,
                    e.target.checked,
                    setArhivirajFormData,
                    arhivirajFormData
                  )
                }
              />
              <label className={styles.label}>
                <input
                  type="checkbox"
                  className={styles.checkboxInput}
                  name="isPodmireniTroskovi"
                  checked={arhivirajFormData.isPodmireniTroskovi}
                  onChange={(e) =>
                    handleInputChangeArhiviraj(
                      e.target.name,
                      e.target.value,
                      e.target.type,
                      e.target.checked,
                      setArhivirajFormData,
                      arhivirajFormData
                    )
                  }
                />
                <span className={styles.checkboxCustom} />
                Polaznik je podmirio troškove upisa
              </label>
            </>
          )}
          <SelectDropdown
            name="gender"
            title="Spol"
            value={formData.gender}
            options={[
              { value: "Muško", label: "Muško" },
              { value: "Žensko", label: "Žensko" },
              { value: "Ostalo", label: "Ostalo" },
            ]}
            onChange={handleInputChange}
          />
          <CustomInput
            name="city"
            value={formData.city}
            labelText="Mjesto stanovanja"
            type="text"
            onChange={handleInputChange}
          />
          <SelectDropdown
            name="programCity"
            title="Grad u kojem pohađa program"
            value={formData.programCity}
            options={mjestoUpisaOptions}
            onChange={handleInputChange}
          />
          <CustomInput
            name="title"
            value={formData.title}
            labelText="Zvanje - Titula"
            onChange={handleInputChange}
          />
          <CustomInput
            name="phoneNumber"
            value={formData.phoneNumber}
            labelText="Broj mobitela"
            onChange={handleInputChange}
          />
          <CustomInput
            name="emailAddress"
            value={formData.emailAddress}
            labelText="Email adresa"
            type="email"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.profileFormButtons}>
        <button className={styles.closeButton} onClick={onClickOdustani}>
          <p className={styles.closeButtonText}>Odustani</p>
        </button>
        <button
          className={
            !areAllFieldsFilled(formData)
              ? styles.saveButtonDisabled
              : styles.saveButton
          }
          disabled={!areAllFieldsFilled(formData)}
          onClick={() => {
            if (isArhiviraj) {
              handleArhivirajDjelatnika(
                [userData?.id],
                authToken,
                navigate,
                arhivirajFormData.groupId,
                setModalContent,
                setShowModal
              );
            } else {
              handleEditDjelatnika(
                userData?.id,
                authToken,
                navigate,
                formData,
                setModalContent,
                setShowModal
              );
            }
          }}
        >
          <p className={styles.saveButtonText}>Spremi promjene</p>
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.title}
          description={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default EditMode;
