import { formatDate } from "../myProfile/utils";
import { getAllTherapistsSupervisors } from "../terapeuti/api/getAllTherapists";
import { getInteractiveLessons } from "./api/getInteractiveLessons";
import { getPresentations } from "./api/getNastupnoPredavanje";
import {
  getMyEvidentionData,
  getUserEvidentionData,
} from "./api/getSudjelovanje";
import { updateZavrsniRad } from "./api/updateZavrsniRad";

export const getPolaznikLevel = (zavrsniRadovi) => {
  let polaznikLevel = "";
  if (zavrsniRadovi?.caseStudy1) {
    polaznikLevel = "psihoterapeut";
  } else {
    //if (zavrsniRadovi?.zavrsniOr)
    polaznikLevel = "praktičar";
  }
  //  else {
  //   polaznikLevel = "rar";
  // }
  return polaznikLevel;
};

export const fetchUserSudjelovanje = async (
  evidentionID,
  accessToken,
  setEvidentionData
) => {
  const response = await getUserEvidentionData(evidentionID, accessToken);
  setEvidentionData(response);
};
export const fetchMySudjelovanje = async (accessToken, setEvidentionData) => {
  const response = await getMyEvidentionData(accessToken);
  setEvidentionData(response);
};
export const handleCheckboxChange = async (
  name,
  value,
  setFormValues,
  evidentionUserID,
  accessToken,
  formValues
) => {
  try {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    await updateZavrsniRad(
      evidentionUserID,
      name === "zavrsniOR" ? value : formValues.zavrsniOR,
      name === "caseStudy1" ? value : formValues.caseStudy1,
      name === "caseStudy2" ? value : formValues.caseStudy2,
      accessToken
    );
  } catch (error) {
    console.error("Error updating checkbox state", error);
  }
};

export const fetchNastupnaPredavanja = async (
  accessToken,
  setNastupnaPredavanja
) => {
  const response = await getInteractiveLessons(accessToken);
  setNastupnaPredavanja(response);
};

export const fetchPresentations = async (accessToken, setPresentations) => {
  const response = await getPresentations(accessToken);
  setPresentations(response);
};
export const columnNames = [
  "Tema predavanja",
  "Datum predavanja",
  "1.gost",
  "Tema 1.gosta predavača",
  "2.gost",
  "Tema 2.gosta predavača",
];
export const OR1ColumnNames = ["OR1", ...columnNames];
export const OR2ColumnNames = ["OR2", ...columnNames];
export const OR3ColumnNames = ["OR3", ...columnNames];
export const OR4ColumnNames = ["OR4", ...columnNames];

export const combinePresentations = (presentations, array) => {
  return array.map((module) => {
    const matchedPresentations = presentations.filter(
      (presentation) => presentation.theoreticalId === module.id
    );
    if (matchedPresentations.length > 0) {
      const prviGost = matchedPresentations[0].User
        ? `${matchedPresentations[0].User.firstName} ${matchedPresentations[0].User.lastName}`
        : "";
      const prviGostAvatar = matchedPresentations[0].User
        ? matchedPresentations[0].User.imageUrl
        : "";
      const statusPrviGost = matchedPresentations[0].attendance
        ? matchedPresentations[0].attendance
        : "";
      const temaPrvogGosta = matchedPresentations[0].topic;
      const prviGostId = matchedPresentations[0]
        ? matchedPresentations[0].userId
        : "";
      const prviGostDone = matchedPresentations[0]
        ? matchedPresentations[0].done
        : false;
      const prviGostAttendance = matchedPresentations[0]
        ? matchedPresentations[0].attendance
        : "";
      let drugiGostId = "";
      let drugiGost = "";
      let drugiGostDone = false;
      let drugiGostAttendance = "";
      let drugiGostAvatar = "";
      let statusDrugiGost = "";
      let temaDrugogGosta = "";
      if (matchedPresentations.length > 1) {
        drugiGostId = matchedPresentations[1]
          ? matchedPresentations[1].userId
          : "";
        drugiGostDone = matchedPresentations[1]
          ? matchedPresentations[1].done
          : false;
        drugiGostAttendance = matchedPresentations[1]
          ? matchedPresentations[1].attendance
          : "";
        drugiGost = matchedPresentations[1].User
          ? `${matchedPresentations[1].User.firstName} ${matchedPresentations[1].User.lastName}`
          : "";
        drugiGostAvatar = matchedPresentations[1].User
          ? matchedPresentations[1].User.imageUrl
          : "";
        statusDrugiGost = matchedPresentations[1].attendance
          ? matchedPresentations[1].attendance
          : "";
        temaDrugogGosta = matchedPresentations[1].topic;
      }

      return {
        theoreticalId: module.id,
        tema: module.name,
        datum: module.date ? module.date : formatDate("2024-01-01"),
        prviGostId,
        prviGost,
        prviGostAttendance,
        prviGostDone,
        prviGostAvatar,
        statusPrviGost,
        temaPrvogGosta,
        drugiGostAttendance,
        drugiGostId,
        drugiGostDone,
        drugiGost,
        drugiGostAvatar,
        statusDrugiGost,
        temaDrugogGosta,
      };
    } else {
      return {
        theoreticalId: module.id,
        tema: module.name,
        datum: module.date ? module.date : formatDate("2024-01-01"),
        prviGostId: "",
        prviGost: "",
        prviGostDone: false,
        prviGostAttendance: "",
        prviGostAvatar: "",
        statusPrviGost: "",
        temaPrvogGosta: "",
        drugiGostId: "",
        drugiGostDone: false,
        drugiGostAttendance: "",
        drugiGost: "",
        drugiGostAvatar: "",
        statusDrugiGost: "",
        temaDrugogGosta: "",
      };
    }
  });
};
export const fetchTerapeutiAndSupervizori = async (
  accessToken,
  setTerapeuti,
  setSupervizori
) => {
  const response = await getAllTherapistsSupervisors(accessToken);
  setTerapeuti(response.filter((terapeut) => terapeut.therapistRole !== 3));
  setSupervizori(
    response.filter((supervizor) => supervizor.therapistRole === 3)
  );
};
