import CheckIcon from "../../assets/svg/CheckIcon";
import styles from "./Checkbox.module.css";

const Checkbox = ({ name, label, value, onChange, onBlur }) => {
  return (
    <label className={styles.checkboxContainer}>
      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        className={styles.checkbox}
      />
      <p className={styles.checkboxLabelText}>{label}</p>
      {value && (
        <div className={styles.iconContainer}>
          <CheckIcon className={styles.icon} />
        </div>
      )}
    </label>
  );
};
export default Checkbox;
