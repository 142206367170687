import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal/Modal";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { OsobniRadGrupaValidationSchema } from "./Schema";
import styles from "./Styles.module.css";
import {
  handleInputChange,
  handleUnesiSateClick,
  osobniRadGrupeRadionice,
  postData,
  uloge,
} from "./utils";

function OsobniRadGrupa({ userEvidentionID }) {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const fullName = localStorage.getItem("evidentionFullName");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    vrstaRadionicePrograma: "",
    uloga: "",
    date: new Date().toISOString().split("T")[0],
    brojSati: "",
  });
  const otherInfo = JSON.stringify(
    postData("therapist_therapy_manual", formData)
  );
  const [errors, setErrors] = useState({});
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        Ručni unos sati za osobni rad s grupom
      </span>
      <div className={styles.inputWrapper}>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Vrsta radionice</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="vrstaRadionicePrograma"
            value={formData.vrstaRadionicePrograma}
            onChange={(e) =>
              handleInputChange(
                "vrstaRadionicePrograma",
                e.target.value,
                setFormData,
                formData
              )
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {osobniRadGrupeRadionice.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.vrstaRadionicePrograma && (
            <div className={styles.error}>{errors.vrstaRadionicePrograma}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Uloga</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="uloga"
            value={formData.uloga}
            onChange={(e) =>
              handleInputChange("uloga", e.target.value, setFormData, formData)
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {uloge.map((item, index) => (
              <option key={index} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.uloga && <div className={styles.error}>{errors.uloga}</div>}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Datum radionice/programa</span>
          <input
            className={styles.rucniUnosInput}
            type="date"
            name="date"
            value={formData.date}
            onChange={(e) =>
              handleInputChange("date", e.target.value, setFormData, formData)
            }
          />
          {errors.date && <div className={styles.error}>{errors.date}</div>}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>
            Ukupan broj sati radionice/programa
          </span>
          <input
            className={styles.rucniUnosInput}
            type="number"
            min="1"
            max="1000"
            name="brojSati"
            placeholder="Upiši"
            value={formData.brojSati}
            onChange={(e) =>
              handleInputChange(
                "brojSati",
                e.target.value,
                setFormData,
                formData
              )
            }
          />
          {errors.brojSati && (
            <div className={styles.error}>{errors.brojSati}</div>
          )}
        </div>
      </div>
      <div className={styles.btnRow}>
        <button
          onClick={() => {
            localStorage.setItem("evidentionFullName", fullName);
            localStorage.setItem("evidentionUserID", userEvidentionID);
            navigate("/evidencija");
          }}
          className={styles.cancelBtn}
        >
          Odustani
        </button>
        <button
          className={styles.successBtn}
          onClick={() =>
            handleUnesiSateClick(
              setErrors,
              formData,
              OsobniRadGrupaValidationSchema,
              userEvidentionID,
              "therapist_therapy_manual",
              otherInfo,
              authToken,
              setModalContent,
              navigate,
              setShowModal
            )
          }
        >
          Unesi sate
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default OsobniRadGrupa;
