import { useEffect, useState } from "react";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import ReusableTabs from "../../components/TabMenu/Tabs";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { getGroupsILead } from "./api/getGroupsILead";
import { getMentorshipsCount } from "./api/getMentorshipsCount";
import GroupsTable from "./components/groupsTable/GroupsTable";
import GroupCard from "./components/PolaznikGrupe/GroupCard";
import styles from "./Groups.module.css";
import { fetchGroupsForPolaznik, tabsData } from "./utils/utils";
const Groups = () => {
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;
  const polaznikID = userData.id;
  const [allGroupsPolaznik, setAllGroupsPolaznik] = useState();
  const [showErr, setShowErr] = useState(false);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [mentorships, setMentorships] = useState(0);
  const [groupsILead, setGroupsILead] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setShowErr(true);
    }, 2000);
  }, []);
  useEffect(() => {
    if (userId !== "Referada") {
      if (authToken)
        fetchGroupsForPolaznik(authToken, polaznikID, setAllGroupsPolaznik);
    }
  }, [authToken, polaznikID, userId]);

  useEffect(() => {
    const fetchMentorships = async () => {
      const response = await getMentorshipsCount(authToken);
      const groupsResponse = await getGroupsILead(authToken);
      if (authToken) setMentorships(response);
      if (authToken) setGroupsILead(groupsResponse);
    };

    if (authToken) fetchMentorships();
  }, [authToken, userId]);

  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };
  return (
    <div>
      {userId === "Polaznik" && (
        <div className={styles.cardWrapper}>
          {allGroupsPolaznik && allGroupsPolaznik.groups.length > 0 ? (
            allGroupsPolaznik.groups
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((item, index) => (
                <GroupCard
                  title={item.name}
                  key={index}
                  route={`/grupe/${item.id}`}
                />
              ))
          ) : showErr ? (
            <div className={styles.noGroupsWrapper}>
              <img alt="" src={InfoIcon} className={styles.noGroupsIcon} />
              Polaznik trenutno ne pripada nijednoj grupi.
            </div>
          ) : null}
        </div>
      )}
      {userId === "Referada" && <GroupsTable />}
      {userId !== "Referada" && userId !== "Polaznik" && (
        <div>
          <ReusableTabs
            currentTabIndex={currentTabIndex}
            handleTabChange={handleTabChange}
            tabsData={tabsData}
          />
          <div className={styles.cardWrapper}>
            {currentTabIndex === 0 && (
              <>
                {groupsILead && groupsILead.length > 0 ? (
                  groupsILead.map((item, index) => (
                    <GroupCard
                      title={item.name}
                      key={index}
                      route={`/grupe/${item.id}`}
                    />
                  ))
                ) : showErr ? (
                  <div
                    className={styles.noGroupsWrapper}
                    style={{ marginTop: 10, marginBottom: 30 }}
                  >
                    <img
                      alt=""
                      src={InfoIcon}
                      className={styles.noGroupsIcon}
                    />
                    Trenutno ne vodite nijednu grupu.
                  </div>
                ) : null}
              </>
            )}
            {currentTabIndex === 1 && (
              <>
                {(allGroupsPolaznik &&
                  allGroupsPolaznik.groups.length > 0 &&
                  allGroupsPolaznik.groups.map((item, index) => (
                    <GroupCard
                      title={item.name}
                      key={index}
                      route={`/grupe/${item.id}`}
                    />
                  ))) || (
                  <div className={styles.noGroupsWrapper}>
                    <img
                      alt=""
                      src={InfoIcon}
                      className={styles.noGroupsIcon}
                    />
                    Trenutno ne pripadate nijednoj grupi.
                  </div>
                )}
              </>
            )}
          </div>
          {currentTabIndex === 0 && showErr && (
            <p className={styles.numberOfMentorships}>
              Ukupan broj mentorstava: {mentorships}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Groups;
