import "quill/dist/quill.snow.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Edit from "../../../assets/img/Edit.png";
import Success from "../../../assets/img/Success.png";
import LeftArrow from "../../../assets/svg/LeftArrow.svg";
import Avatar from "../../../assets/svg/UserAvatarIcon.svg";
import Modal from "../../../components/Modal/Modal";
import QuillEditor from "../../../components/QuillEditor/QuillEditor";
import { useUserState } from "../../../context/UserContext";
import { useAuthentication } from "../../hooks/useAuthentication.js";
import styles from "../../voditelji/components/MojaMentorstvaVoditelj.module.css";
import { Title } from "../ProgramContainer/ProgramContainer.styles";
import {
  createIntrospection,
  getMyMentor,
  updateIntrospection,
} from "./API.js";

function IntrospekcijeContainer({
  description,
  comments,
  answers,
  handleCommentsUpdate,
  isMentorstva,
  polaznikId,
  groupProgramId,
  introspectionIdMentor,
}) {
  const location = useLocation();
  const { index } = useParams();

  const minCommentSize = 150;

  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(comments || "");
  let isTitle = false;
  let isNavigation = false;
  let title = "";
  let introspectionsData = [];
  let isReal = true;
  const introspectionId = isMentorstva
    ? introspectionIdMentor
    : parseInt(index, 10);

  if (!comments && !answers && !description) {
    introspectionsData = location?.state?.introspectionsData;
    description =
      introspectionsData &&
      introspectionsData[introspectionId - 1]?.introspection.questions;
    comments =
      introspectionsData &&
      introspectionsData[introspectionId - 1]?.mentorComment;
    answers =
      introspectionsData && introspectionsData[introspectionId - 1]?.userAnswer;
    title = location?.state?.title;
    isTitle = true;
    isNavigation = true;
    isReal =
      introspectionsData && introspectionsData[introspectionId - 1]?.isReal;
  }
  let programId = isMentorstva ? groupProgramId : location?.state?.programId;

  useEffect(() => {
    setInputValue(comments);
  }, [comments]);

  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const [showEditComment, setShowEditComment] = useState(false);
  const [littleComment, setLittleComment] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [disabledPrevious, setDisabledPrevious] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userAnswer, setUserAnswer] = useState(answers || "");
  const [commentSize, setCommentSize] = useState(0);
  const [isCommentDone, setIsCommentDone] = useState(false);
  const [mentorData, setMentorData] = useState({});
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const userId = role;
  const items = description?.split(/\d+\.\s+/);
  if (items && items[0] === "") {
    items.shift();
  }

  const handleSaveClick = async () => {
    try {
      let commentLength = inputValue
        ?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?]/g, "")
        .replace(/\s/g, "").length;
      if (commentLength >= minCommentSize) {
        await handleCommentsUpdate(inputValue, isCommentDone);
        setShowEditComment(false);
        setLittleComment(false);
      } else {
        setLittleComment(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const descriptionElements = items?.map((item, introspectionId) => (
    <p key={introspectionId}>{`${introspectionId + 1}. ${item}`}</p>
  ));

  useEffect(() => {
    if (introspectionId === 1) {
      setDisabledPrevious(true);
    } else if (introspectionId === introspectionsData.length) {
      setDisabledNext(true);
    } else {
      setDisabledPrevious(false);
      setDisabledNext(false);
    }
  }, [introspectionId]);

  const handleUpdate = async () => {
    const newIntrospectionsData = {
      introspectionId:
        programId == 1
          ? introspectionId
          : programId == 3
          ? introspectionId + 8
          : programId == 4
          ? introspectionId + 14
          : programId == 5
          ? introspectionId + 20
          : introspectionId + 27,
      mentorComment: inputValue,
      userAnswer,
      isRead: false,
      userId: isMentorstva ? polaznikId : userData.id,
      isDone: isDone,
    };
    if (isReal === false) {
      introspectionsData[introspectionId - 1].isReal = true;
      await createIntrospection(newIntrospectionsData, authToken);
    } else {
      await updateIntrospection(newIntrospectionsData, authToken);
    }
  };

  useEffect(() => {
    let commentLength = inputValue
      ?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?]/g, "")
      .replace(/\s/g, "").length;
    if (commentLength < minCommentSize) {
      setCommentSize(commentLength);
      setIsCommentDone(false);
    } else {
      setCommentSize(minCommentSize);
      setIsCommentDone(true);
    }
  }, [inputValue]);

  useEffect(() => {
    const fetchMentor = async () => {
      const response = await getMyMentor(userData.id, programId, authToken);
      setMentorData(response);
    };
    if (authToken) fetchMentor();
  }, [authToken]);

  return (
    <div className="introspekcije-wrapper">
      {isModalOpen && (
        <Modal
          image={Success}
          description={"Tvoja introspekcija je uspješno spremljena."}
          buttons={[
            {
              label: "U redu",
              action: () => {
                if (!isMentorstva) {
                  navigate(`/pojediniprogram/${programId}/one`);
                } else {
                  setIsModalOpen(false);
                }
              },
              className: "modal-btn-success",
            },
          ]}
        />
      )}
      {isTitle && (
        <div style={{ width: "100%", marginTop: "-50px" }}>
          <Title>{title}</Title>
        </div>
      )}
      {isNavigation && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              cursor: disabledPrevious ? "not-allowed" : "pointer",
              opacity: disabledPrevious ? 0.6 : 1,
              userSelect: "none",
            }}
            onClick={() => {
              if (!disabledPrevious) {
                navigate(`/introspekcije-polaznik/${introspectionId - 1}`, {
                  state: {
                    introspectionsData: introspectionsData,
                    title: title,
                    programId: programId,
                  },
                });
              }
            }}
          >
            <img src={LeftArrow} alt="" />
            <p
              style={{
                color: "#5F6D7E",
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Prethodna
            </p>
          </div>
          <p
            style={{
              color: "#5F6D7E",
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: 20,
              userSelect: "none",
            }}
          >
            {introspectionId}. Introspekcija
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              userSelect: "none",
              cursor: disabledNext ? "not-allowed" : "pointer",
              opacity: disabledNext ? 0.6 : 1,
            }}
            onClick={() => {
              if (!disabledNext) {
                navigate(`/introspekcije-polaznik/${introspectionId + 1}`, {
                  state: {
                    introspectionsData: introspectionsData,
                    title: title,
                    programId: programId,
                  },
                });
              }
            }}
          >
            <p
              style={{
                color: "#5F6D7E",
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Sljedeća
            </p>
            <img
              src={LeftArrow}
              alt=""
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>
      )}
      <QuillEditor
        answer={answers}
        description={descriptionElements}
        setUserAnswer={setUserAnswer}
        userId={userId}
        handleUpdate={handleUpdate}
        setIsDone={setIsDone}
        isMentorstva={isMentorstva}
      />
      {/*userId !== "Voditelj" && !isMentorstva */}
      {/*Ako oće da ostane da se može uređivat introspekcija od strane mentora izbrisat ovo */}
      {true && (
        <div className="introspekcije-save-btn-wrapper">
          <button
            className="introspekcije-save-btn"
            onClick={() => {
              handleUpdate();
              setIsModalOpen(true);
            }}
          >
            Spremi
          </button>
        </div>
      )}
      <span className="introspekcije-label">
        {isMentorstva &&
        (userId === "Voditelj" ||
          userId === "Polaznik VT-a" ||
          userId === "Terapeut" ||
          userId === "Učitelj")
          ? showEditComment
            ? "Uredi komentar"
            : comments
            ? "Moj komentar"
            : ""
          : "Komentar mentora"}
      </span>
      {littleComment && (
        <p style={{ color: "red" }}>Komentar treba imati više od 150 slova!</p>
      )}
      {comments && comments.length > 0 ? (
        <div className="introspekcije-comments-row">
          <img
            alt="avatar"
            src={
              isMentorstva ? userData?.imageUrl : mentorData?.imageUrl || Avatar
            }
            className="instrospekcije-avatar-icon"
          />
          <div
            style={
              littleComment
                ? { justifyContent: "space-between", border: "1px solid red" }
                : { justifyContent: "space-between" }
            }
            className="introspekcije-no-comments-container"
          >
            <textarea
              value={inputValue}
              className="introspekcije-no-comments input-text"
              autoFocus={showEditComment}
              disabled={!showEditComment}
              onChange={handleInputChange}
              style={littleComment ? { border: "1px solid red" } : {}}
            />
            {isMentorstva &&
              (userId === "Voditelj" ||
                userId === "Polaznik VT-a" ||
                userId === "Terapeut" ||
                userId === "Učitelj") &&
              !showEditComment && (
                <button
                  className="introspekcije-edit-btn"
                  onClick={() => setShowEditComment(true)}
                >
                  <img alt="" src={Edit} />
                </button>
              )}
          </div>
        </div>
      ) : comments === "" &&
        !isMentorstva &&
        (userId !== "Voditelj" ||
          userId !== "Polaznik VT-a" ||
          userId !== "Terapeut" ||
          userId !== "Učitelj") ? (
        <div className="introspekcije-no-comments">
          Mentor još nije ostavio komentar
        </div>
      ) : comments === "" &&
        isMentorstva &&
        (userId === "Voditelj" ||
          userId === "Polaznik VT-a" ||
          userId === "Terapeut" ||
          userId === "Učitelj") ? (
        <div className="introspekcije-comments-row">
          <img
            alt="avatar"
            src={userData.imageUrl}
            className="instrospekcije-avatar-icon"
          />
          <div
            className="introspekcije-no-comments-container"
            đ
            style={
              littleComment
                ? { justifyContent: "space-between", border: "1px solid red" }
                : { justifyContent: "space-between" }
            }
          >
            <textarea
              value={inputValue}
              className="introspekcije-no-comments input-text"
              autoFocus={showEditComment}
              disabled={!showEditComment}
              onChange={handleInputChange}
              placeholder="Ostavi komentar"
            />
            {isMentorstva &&
              (userId === "Voditelj" ||
                userId === "Polaznik VT-a" ||
                userId === "Terapeut" ||
                userId === "Učitelj") &&
              !showEditComment && (
                <button
                  className="introspekcije-edit-btn"
                  onClick={() => setShowEditComment(true)}
                >
                  <img alt="" src={Edit} />
                </button>
              )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {showEditComment && (
        <div
          className="introspekcije-description"
          style={{ width: "100%", textAlign: "right", fontSize: 15 }}
        >
          {commentSize}/{minCommentSize}
        </div>
      )}
      {showEditComment && (
        <div className={styles.editModeButtonscontainer}>
          <div
            className={styles.cancelButton}
            onClick={() => {
              setShowEditComment(false);
              setInputValue(comments || "");
              setLittleComment(false);
            }}
          >
            <div className={styles.cancelButtonText}>Odustani </div>
          </div>

          <div className={styles.saveButton}>
            <div className={styles.saveButtonText} onClick={handleSaveClick}>
              Spremi promjene
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default IntrospekcijeContainer;
