import axios from "axios";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import AppleIcon from "../../assets/svg/AppleIcon.svg";
import GoogleIcon from "../../assets/svg/GoogleIcon.svg";
import IPDLogo from "../../assets/svg/IPDLogo.png";
import { useUserState } from "../../context/UserContext";
import { appleProvider, auth } from "../../firebase";
import styles from "./SessionExpired.module.css";

const SessionExpired = ({ setOpen }) => {
  const navigate = useNavigate();
  const { setAccessToken, setUserData, setRole } = useUserState();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    await signInWithPopup(auth, provider)
      .then((result) => {
        const token = result.user.accessToken;
        localStorage.setItem("authToken", token);
        const date = new Date();
        localStorage.setItem(
          "sessionTime",
          `${date.setHours(date.getHours() + 3)}`
        );

        setAccessToken(token);
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/user`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(async (data) => {
            await axios
              .get(
                `${process.env.REACT_APP_API_URL}/api/role/${data.data.roleId}`,
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then((response) => setRole(response.data.name));
            setUserData(data.data);
            if (data.data.firstLogin) {
              navigate("/moj-profil", { state: { isFirstLogin: true } });
            } else {
              setOpen(false);
            }
          })
          .catch(() => alert("Nisi registrirani korisnik"));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, credential);
      });
  };
  const handleAppleSignIn = () => {
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        const user = result.user;
        console.log(user);
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = OAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, credential);
      });
  };
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <img src={IPDLogo} alt="logo" className={styles.logo} />
        <h2 className={styles.heading}>Sesija istekla prijavite se ponovno</h2>
        <div className={styles.buttonContainer}>
          <button className={styles.googleButton} onClick={handleGoogleSignIn}>
            <img
              src={GoogleIcon}
              alt="google icon"
              className={styles.googleIcon}
            />
            <p className={styles.googleButtonText}>
              Prijavi se s Google računom
            </p>
          </button>
          <button className={styles.appleButton} onClick={handleAppleSignIn}>
            <img
              src={AppleIcon}
              alt="google icon"
              className={styles.appleIcon}
            />
            <p className={styles.appleButtonText}>Prijavi se s Apple računom</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;
